import React from 'react'
import { Oval	 } from  'react-loader-spinner'
import '../styles/loader.css';
export default function Loader() {
  return (
    <div className='loader'>
    <div className='loader-spinner'>
    <Oval	
    height={200}
    width={200}
    className="spinner"
    color='pink'
    ariaLabel='loading'
  >ashsjjs</Oval></div>
 
  <div className='loader-logo'> <img src="../../../images/CmFLogo.png" /></div></div>
  
  )
}

