import React from 'react'
import '../styles/overlay.css';
export default function Overlay({overLayColor,z_index}) {
  if(z_index==null)
  z_index=0;
  return (
    <div className='overlayComp' style={{ backgroundColor:overLayColor,zIndex:z_index }}>
    </div>
  )
}
