import React, { useState } from 'react'
import Timeline from '../../components/Timeline'
import Card from '../../components/Card'
import Events from '../../components/homepage/Events';
import '../../styles/new_component.css';
import PhotoSlider from "../../components/PhotoSlider.jsx"
import Pagination from "../../components/Pagination"
import SocialMedia from '../../components/SocialMedia';
export default function NewComponent() {
   const [pic1,setPic1]=useState(false);

  //  document.getElementById('showpic').addEventListener('hover')
  //   docv.addEventListener()
  return (
    <div className='new_component'>
        {/* <Timeline/> */}
        {/* <Card/> */}
        {/* <Events/> */}
        {/* <PhotoSlider/> */}
        {/* <Pagination/> */}
        {/* <SocialMedia/> */}
        {
        !pic1&&<img src='../images/home/coverageMap/map1.png' />
        }
        {
          pic1&&<img src='../images/home/coverageMap/map2.png' />
        }
        
        <div id="showpic" onMouseEnter={()=>setPic1((prev)=>!prev)}  onMouseLeave={()=>setPic1((prev)=>!prev)}>SHOW PIC 1</div>
        </div>
  )
}
