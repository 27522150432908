import React, { useEffect, useState } from "react";
import "../../styles/homePageCoverage.css";

const imgSourceArray = [
  "1-Background.png",
  "2-Sirohi.png",
  "3-Pali.png",
  "4-Karauli.png",
  "5-Tonk.png",
  "6-Alwar.png",
  "7-Dausa.png",
  "8-Udaipur.png",
  "9-State-level.png",
];
const sirohi = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>Sirohi</h4>
    <ul>
      <li>Livelihoods & mF</li>
      <li>Education – ECE, Library & Middle</li>
      <li>Nutrition - MCHN</li>
      <li>WASH & MHM</li>
      <li>Skilling & Entrepreneurship</li>
    </ul>
  </div>
);

const pali = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>Pali</h4>
    <ul>
      <li>Livelihoods & mF</li>
      <li>Education – FLN & Library</li>
      <li>WASH & MHM</li>
    </ul>
  </div>
);

const karauli = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>Karauli</h4>
    <ul>
      <li>Livelihoods & mF</li>
      <li>Education – FLN & Library</li>
    </ul>
  </div>
);

const tonk = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>Tonk</h4>
    <ul>
      <li>Livelihoods & mF</li>
      <li>Education – Library</li>
      <li>Nutrition - PLA</li>
    </ul>
  </div>
);

const alwar = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>Alwar</h4>
    <ul>
      <li>Livelihoods & mF</li>
      <li>Education – Library</li>
    </ul>
  </div>
);

const dausa = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>Dausa</h4>
    <ul>
      <li>Livelihoods & mF</li>
      <li>Education – Library</li>
    </ul>
  </div>
);

const udaipur = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>Udaipur</h4>
    <ul>
      <li>Livelihoods & mF</li>
      <li>Education – Library</li>
    </ul>
  </div>
);

const stateLevel = (
  <div className="HomePageCoverage-content-areaDetail-single">
    <h4>State-level Initiatives</h4>
    <ul>
      <li>Education – Library</li>
      <li>FNHW</li>
    </ul>
  </div>
);

export default function HomePageCoverage() {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [imgsrc, setImgsrc] = useState(imgSourceArray[0]);
  const [hovered, setHovered] = useState(false);
  const [showAreaData, setShowAreaData] = useState("");
  const [loadImage,setLoadImage] = useState(<img></img>)

  const handleHover = (data) => {
    setHovered((prev) => !prev);

    setShowAreaData((prev) => data);
  };
  const handleWidthChange = () => {
    setDeviceWidth(() => window.innerWidth);
    if (deviceWidth < 900) {
      setShowAreaData(() => {
        return [sirohi, pali, karauli, tonk, alwar, dausa, udaipur, stateLevel];
      });
    }
  };

  window.addEventListener("resize", handleWidthChange);

  useEffect(() => {
    setShowAreaData(() => {
      // return ''
      if (window.innerWidth < 900)
        return [sirohi, pali, karauli, tonk, alwar, dausa, udaipur, stateLevel];
      else return;
    });
    for(var i=0;i<9;i++)
    {
      setLoadImage(()=><img src={`../images/home/coverageMap/${imgSourceArray[i]}`} />)
    }

  }, []);
  return (
    <div className="HomePageCoverage">
      <div className="HomePageCoverage-content">
        <div className="HomePageCoverage-content-text">
          <h2>WHERE WE WORK</h2>

          <p>Field-level Interventions</p>
          <ul>
            <li>7 Districts</li>
          </ul>
          <p>Technical Support to State Government</p>
          <ul>
            <li>Education - Library - 33 districts</li>
            <li>Livelihoods & mF – 7 districts</li>
            <li>FNHW - 15 districts</li>
          </ul>
        </div>
        <div
          className="HomePageCoverage-content-map"
          style={deviceWidth < 900 ? { display: "none" } : { display: "" } }

        >
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[1]);
              handleHover(sirohi);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#543256" }}
          >
            Sirohi
          </li>
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[2]);
              handleHover(pali);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#596356" }}
          >
            Pali
          </li>
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[3]);
              handleHover(karauli);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#543093" }}
          >
            Karauli
          </li>
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[4]);
              handleHover(tonk);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#459256" }}
          >
            Tonk
          </li>
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[5]);
              handleHover(alwar);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#546743" }}
          >
            Alwar
          </li>
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[6]);
              handleHover(dausa);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#5409aa" }}
          >
            Dausa
          </li>
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[7]);
              handleHover(udaipur);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#aa32bb" }}
          >
            Udaipur
          </li>
          <li
            onMouseEnter={() => {
              setImgsrc(() => imgSourceArray[8]);
              handleHover(stateLevel);
            }}
            onMouseLeave={() => {
              setImgsrc(() => imgSourceArray[0]);
              handleHover();
            }}
            style={{ color: "#5a355c" }}
          >
            State Level
          </li>

          <img src={`../images/home/coverageMap/${imgsrc}`} />
          {loadImage}
        </div>
        <div className="HomePageCoverage-content-areaDetail">
          {showAreaData}
        </div>
      </div>
    </div>
  );
}
