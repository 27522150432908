// import Img1 from './images/cmf_chairman.jpg'
import React from "react";
function Information() {
  let data = [
    {
      name: "Prof. M.S. Sriram, Chairman",
      description:
        "Professor M.S. Sriram is the Chairman of CmF.He is a distinguished academician. Currently, he is the Chairperson of the Centre for Public Policy, IIM Bangalore. He is a Distinguished Fellow of Institute for Development of Research in Banking Technology – set up by the Reserve Bank of India (RBI). He is also independent director on the board of AU Small Finance Bank. In the past he was the ICICI Bank Lalita Gupte Chair Professor of Microfinance and Finance and Accounting Area at IIM Ahmedabad. He has also served as the faculty of IRMA, and Vice President of Basix. Prof. Sriram is a co-author of three Books - Beyond Micro-credit, published by Sage-Vistaar, and two books on Flow of Credit to Small and Marginal Farmers. Prof. Sriram has served on several committees set up by the Government of India, RBI and NABARD. Besides the CmF, he is currently on the board of Indian NDDB Dairy Services and Centre for Budget and Policy Studies, and a Trustee of Pratham Books and Dastkar Andhra. ",
      image:
        "https://cdn.cnn.com/cnnnext/dam/assets/220504143626-elon-musk-050222-large-169.jpg",
    },
    {
      name: "Mr. Narendra Singh Sisodia",
      description: `Mr. Narendra Singh Sisodia was Director General of IDSA from September 2005 to November 15,
            2011. He retired as Secretary in the Ministry of Finance in January 2005. Prior to this assignment, he
            was Secretary, Defence Production and Supplies, Ministry of Defence. Born in 1945, Mr. Sisodia
            graduated from St. Stephens’ College Delhi and obtained a Master’s Degree from Harvard University,
            USA, where he was a Mason Fellow. Mr. Sisodia joined the Indian Administrative Service in 1968 and
            served as District Magistrate in four districts of Rajasthan. He was later Principal Secretary, Industries
            &amp; Commerce, Chairman and Managing Director of the State Industrial Development &amp; Infrastructure
            Corporation and Chairman, State Electricity Board, Rajasthan. In Government of India, he served as
            Joint Secretary, Ministry of Defence from 1988-94. As Additional Secretary in the National Security
            Council Secretariat, he was closely associated with the work of the Kargil Review Committee.
            Subsequently, he was appointed as a member of the Task Force set up to recommend measures for
            Reforming the Management of Defence. He was also responsible for providing resource support to
            the Group of Ministers on Reforming the National Security System.`,
      image:
        "https://cdn.cnn.com/cnnnext/dam/assets/220504143626-elon-musk-050222-large-169.jpg",
    },
    {
      name: "Mr. Ajay Singh Mehta",
      description: `Mr. Ajay Singh Mehta is the President- Board of Trustees of Vidya Bhawan Society, Udaipur. He has
            served as the Chief Executive of Seva Mandir from 1990 – 1999. Mr. Mehta was the Executive
            Director, National Foundation for India (NFI) from July 2001 – June 2011. He also held office as
            General Secretary, AVARD (Association of Voluntary Agencies for Rural Development) from January
            2000 – July 2001. He is on Board of several other non-profit organizations and was also a member of
            State Planning Board, Government of Rajasthan.`,
      image:
        "https://cdn.cnn.com/cnnnext/dam/assets/220504143626-elon-musk-050222-large-169.jpg",
    },
    {
      name: "Ms. Aparna Sahay",
      description: `Ms. Aparna Sahay is an M.A. in Political Science and an MBA. She had a long career in civil services in
            the Government of Rajasthan (Rajasthan Accounts Services). Her major assignments were Member
            Secretary, Rajasthan State Women&#39;s Commission; Director, Lok Jumbish Project; Consultant, Swedish
            International Development Authority on Reproductive &amp; Child Health; State Coordinator, The Hunger
            Project; ED, RTDC and Financial Advisor, RIICO. She retired in 2015 from the post of Registrar and
            Addl Director, Centre for Management Studies, HCM Rajasthan State Institute of Public
            Administration. After her retirement from the government, she also headed Sandhaan – a resource
            organization working in education for a few years.`,
      image:
        "https://cdn.cnn.com/cnnnext/dam/assets/220504143626-elon-musk-050222-large-169.jpg",
    },
    {
      name: "Ms. Girija Srinivasan",
      description: `Ms. Girija Srinivasan is an expert in development finance and rural livelihoods with more than 30
            years of extensive experience of development banking, consulting, technical assistance and studies.
            After an initial career as a development banker for 14 years, she had taken to consulting working
            with a wide variety of international and national organizations. She has authored books and
            documents on community-based approaches in development finance and livelihoods. She has
            authored the Social Performance Report in Microfinance for four years, from 2011 to 2014. She
            authored the SOIL Report 2015 and 2016 jointly with N. Srinivasan. She serves on the board of
            FWWB and industry forums on themes of livelihoods and development finance.`,
      image:
        "https://cdn.cnn.com/cnnnext/dam/assets/220504143626-elon-musk-050222-large-169.jpg",
    },
    {
      name: "Mr. Arun Pandhi, Director, Implementation, Tata Trusts",
      description: `Mr. Arun Pandhi holds a Postgraduate Degree in Development Studies from the University of Leeds,
            United Kingdom. Mr. Pandhi is a rural development professional with specialization on water and
            agriculture. He worked with in the drinking water and sanitation program of the Aga Khan Rural
            Support Programme (India) during 1996-1999. Thereafter, Trusts in 2001, he was associated with the
            Water and Sanitation Program of the World Bank from 1999 to 2001. He itithjoined the Sir Ratan
            Tata Trust in 2001 and currently serves as the Director, Implementation at the Trusts. At the Trusts,
            he has been instrumental in creating and nurturing a number of initiatives and institutes in the space
            of water, agriculture, dairy, rural development and field hockey.`,
      image:
        "https://cdn.cnn.com/cnnnext/dam/assets/220504143626-elon-musk-050222-large-169.jpg",
    },
    {
      name: "Malika Srivastava, Member Secretary (Ex-officio position)",
      description: `Malika Srivastava is the Executive Director of the Centre for microFinance (CmF). She has been
            working with the CmF since 2007 on behalf of the Trusts in various capacities and specializes in
            Community Based Microfinance &amp; Rural Livelihoods. Malika holds a Post Graduate Diploma in Rural
            Management from the Institute of Rural Management, Anand (IRMA). She holds a B.Tech. in Dairy
            Technology. Before joining the CmF in 2007, she worked for around 2 years in Andhra Pradesh (now
            Telangana) to promote farmers’ cooperatives. She has been the Executive Director of CmF since
            November 2015.`,
      image:
        "https://cdn.cnn.com/cnnnext/dam/assets/220504143626-elon-musk-050222-large-169.jpg",
    },
  ];

  let timelineElements = [
    {
      id: 1,
      title: "Training and Capacity Building",
      location: "bahraich, uttar pradesh",
      description: `Training and Capacity Building
       of Tata Trusts’ Sakh-Se-Vikas partners, other NGOs, banks, government departments on Self-Help Group based model of microfinance, research studies and workshops on sectoral issues.
       `,
      buttonText: "buttonn text",
      date: "Seeded in 2007",
      icon: "icon",
    },
    {
      id: 2,
      title: "Technical support",
      location: "bahraich, uttar pradesh",
      description: `Technical Support 
      to SHG Federations, capacity building and knowledge partnership with state government’s SHG program in West Rajasthan
      `,
      buttonText: "buttonn text",
      date: "2009",
      icon: "icon",
    },
    {
      id: 3,
      title: "Livelihood enhancement",
      location: "bahraich, uttar pradesh",
      description: `Livelihood Enhancement
      for SHG members through the Mahila Kisan Sashaktikaran Pariyojna (MKSP) – consortium project of SSV partners, facilitating partnership of SHG federations with the National Rural Livelihoods Mission (NRLM) program
      `,
      buttonText: "buttonn text",
      date: "2013",
      icon: "icon",
    },
    {
      id: 4,
      title: "Multi-Thematic Program ",
      location: "bahraich, uttar pradesh",
      description: `Transformation Initiative launched in South Rajasthan with focus on layering education, nutrition, water, and sanitation interventions with SHGs and livelihoods work`,
      buttonText: "buttonn text",
      date: "2015",
      icon: "icon",
    },
    {
      id: 5,
      title: "Scaling up of learnings",
      location: "bahraich, uttar pradesh",
      description: `Scaling-up of Learnings 
      of multi-thematic program through state level technical support partnerships in education and livelihoods `,
      buttonText: "buttonn text",
      date: "2020 onwards",
      icon: "icon",
    },
  ];
  return { data, timelineElements };
}
export default Information;

//   <select id="cars" name="cars">
//   <option value="volvo">Volvo</option>
//   <option value="saab">Saab</option>
//   <option value="fiat">Fiat</option>
//   <option value="audi">Audi</option>
// </select>
