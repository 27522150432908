import React, { useEffect, useState } from "react";
import "../../styles/profVyas.css";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { Button, Modal } from "react-bootstrap";
import {UploadFile,DeleteFile} from '../../UploadFile';
import {PostOrationData,GetOrationData,DeleteOrationData,UploadOrationImage} from "../../apis";


export default function ProfVyas() {

    const [addOrationmodalShow,setAddOrationmodalShow] =useState(false);
    const [orationDBData,setOrationDBData]=useState([]);

    const fetchOrationData= async()=>{
       const fetchOrationDataRes= await GetOrationData();
       let temp=fetchOrationDataRes.data;
       temp=temp.sort((item1,item2)=>item1.time-item2.time);
       setOrationDBData(()=>temp);
       console.log(fetchOrationDataRes.data)
    }
    const deleteOrationDatafunc= async(id,fileRef)=>{
      const data={
        id:id,
     
      }
      await DeleteFile(fileRef);
      const deleteOrationDataRes= await DeleteOrationData(data);
       console.log(deleteOrationDataRes.data);
      fetchOrationData();
   }

    const handleAddOrationSubmit= async (e)=>{
      e.preventDefault();
      const heading=e.target[0].value;
      const keyPoint=e.target[1].value;
      const linkTopdf=e.target[2].value;
      const  linkTovideo=e.target[3].value;
  
      setAddOrationmodalShow(()=>false)
      const file = e.target[4].files[0];
   
    const UploadFileRes=await UploadFile(file,'profVyas');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
     const time = new Date().getTime();
      const data={
        heading:heading,
        keyPoint:keyPoint,
        linkTopdf:linkTopdf,
        linkTovideo:linkTovideo,
        imageurl:imageurl,
        fileRef:fileRef,
        time:time
      }
       const orationUploadRes= await PostOrationData(data);
       await fetchOrationData();
    }
useEffect(()=>{
fetchOrationData();
},[])
  const annualMemorialOrationArray = [
    {
      heading: "1st Annual Memorial Oration (16 Feb 2019)",
      keyPoints: [
        "Bleeding Hearts and Thinking Minds – Beyond Loan Waivers",
        "Dr. Y. V. Reddy, Ex-Governor RBI",
        "At HCM-RIPA, OTS, Jawahar Lal Nehru Marg, Jaipur",
      ],
    },
    {
      heading: "2nd Annual Memorial Oration (21 Aug 2020)",
      keyPoints: [
        "Poverty after the Pandemic: Building Back Better",
        "Prof. Anirudh Krishna",
      ],
    },
    {
      heading: "3rd Annual Memorial Oration (21 Aug 2021)",
      keyPoints: ["Why Valorise the Peasantry?", "Prof. Tridip Suhrud"],
    },
  ];

  let profVyasHeader = (
    <div className="profVyasHeader">
      <Navbar position={"static"} />
      <div className="profVyasHeader-heading">
        <h2>Tribute to Our Founding Member </h2>
        <h2> Prof. Vijay Shankar Vyas (1931-2018)</h2>
      </div>
    </div>
  );

  let profVyasTribute = (
    <div className="profVyasTribute">
    <img src="../images/media/profVyas.jpeg" />
      <p>
        Prof. Vijay Shankar Vyas was one of the founding members of CmF, a noted
        economist and Padma Bhushan awardee (2006). Prof. Vyas had served as
        Chairman and member of several Boards, Committees, and Commissions at
        the international and national level. He helped set up a number of
        non-profit organizations in Rajasthan, one of which is the Centre for
        microFinance (CmF). He passed away on 12th September 2018 at the age of
        87.
      </p>
      <p>
        It was in 2005, when discussions regarding the need for setting up a
        Centre for Micro Credit in the state of Rajasthan were taking place. On
        the request of several stakeholders including the Tata Trusts, NABARD,
        government and others, Prof. Vyas readily agreed to do a feasibility
        study for such a Centre. In his study, Prof. Vyas very beautifully
        articulated the vision and the broad roles which the Centre should take
        up. He also helped the founding members and the executive team to
        translate the vision into action by actively guiding the various
        workshops and consultations which CmF began organizing after its
        registration in 2007. Prof. Vyas was instrumental in forging CmF’s
        important collaboration with the Bank of Baroda in Dungarpur.
      </p>
      <p>
        Even after he stepped down from the Governing Board of CmF in 2012, we
        continued to benefit from his advice from time to time. He always
        remained accessible and ready to help despite his various commitments.
      </p>
      <p>
        As a mark of our respect, CmF along with AJIT Foundation (founded by
        Prof. Vyas in memory of his son Late Sh. Ajit Vyas) organized the 1st
        Prof. VS Vyas Memorial Lecture on 16th February 2019 at Jaipur. Dr. YV
        Reddy, Ex-Governor, RBI and a close associate of Prof. Vyas delivered
        the memorial lecture. Through the lecture which was titled{" "}
        <strong>“Bleeding Hearts & Thinking Minds: Beyond Loan Waivers”</strong>
        , Dr. Reddy, beautifully drew the attention of the audience to the
        recommendation made by Prof. Vyas as Chair of the RBI’s Expert Committee
        on Rural Credit (2004) to set up an agri-risk fund for farmers which
        would moderate the risk of lending banks. The risk fund was envisaged to
        be set up with equal contribution from central and state governments and
        participating banks. Dr. Reddy said that Prof. Vyas called for adopting
        a new approach to agriculture for long term solutions to the problems of
        commercial viability and unbearable risks. The occasion also saw the
        release of a book edited by Prof. V.S. Vyas{" "}
        <strong>
          ‘Education, Health & Citizenship: Three Dimensions for Empowering
          Adolescents’.
        </strong>
      </p>
      <p>
        We were most privileged to have him inaugurate the Women’s Conclave -
        ABHIVYAKTI of the Rajasthan Microfinance & Livelihoods Colloquium in
        November 2017. While addressing the 250+ women leaders of Self-Help
        Groups and Federations from across the state, he advised that besides
        learning accounting and financial management, rural women should now
        learn computers to keep pace with changing times. In the photo
        exhibition held alongside the Women’s Conclave, Prof. Vyas was very
        happy to see the photographs of CmF’s digital literacy initiative in the
        photo exhibition and he said that{" "}
        <strong>
          ‘this is the kind of empowerment work which I wanted CmF to do’.
        </strong>{" "}
      </p>
      <p>
        At CmF, we remain committed to fulfil Prof. Vyas’ vision of finding
        innovative solutions for empowering the underprivileged and marginalized
        communities.
      </p>
    </div>
  );

  let profVyasOration = (
    <div className="profVyasOration">
      <div className="profVyasOration-heading">
        <h3>Prof. V. S. Vyas Annual Memorial Oration</h3>
        <h6>(Organized by Centre for microFinance & AJIT Foundation)</h6>
      </div>
      <div className="add-oration">
      {localStorage.getItem("CmFtoken") != null &&
        <button onClick={()=>setAddOrationmodalShow(()=>true)}>ADD ORATION</button>}
      </div>
      <div className="annualMemorialOration">
        {orationDBData.length>0&&orationDBData.map((item, i) => {
          return (
            <div className="annualMemorialOration-single">
              <h4>{item.heading}</h4>
              <div className="annualMemorialOration-single-image">
                <img src={item.imageurl} />
                {console.log(item.imageurl)}
              </div>
           
                {/* {item.keyPoints.map((keyPoint, j) => {
                  return <li>{keyPoint}</li>;
                })} */}
                <p>{item.keyPoint}</p>
                {item.linkTopdf!==""&&<span><a href={item.linkTopdf} target="_blank" >PDF</a></span>}
                {item.linkTovideo!==""&&<span><a href={item.linkTovideo} target="_blank" >VIDEO</a></span>}
                {localStorage.getItem("CmFtoken") != null &&
                <button onClick={()=>deleteOrationDatafunc(item.id,item.fileRef)} >DELETE</button>}
            </div>
          );
        })}
      </div>
    </div>
  );
let addOrationModal=(  <Modal
    className="modal-addOration"
    show={addOrationmodalShow}
    onHide={() => setAddOrationmodalShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter-addmember"
    centered
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
    <Modal.Body className="modal-body-addOration">

    <form onSubmit={(e)=>handleAddOrationSubmit(e)}>
    <input  className="input" type="text" placeholder="Heading" />
    <input  className="input" type="text" placeholder="Key Point" />
    <input  className="input" type="text" placeholder="Link to pdf" />
    <input  className="input" type="text" placeholder="Link to video" />
   
    <input  className="input" type="file" placeholder="IMAGE" />
    <label >Add Image</label>
    <button type="submit" >SUBMIT</button>
    </form>
    </Modal.Body>
    

    </Modal>)
  return (
    <div className="profVyas">
      {profVyasHeader}
      {profVyasTribute}
      {profVyasOration}
       {addOrationModal}
    



      <Footer />
    </div>
  );
}
