import React from 'react';
import '../styles/timeline.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Information from '../info';
export default function Timeline() {
    let iconStyle={background:"rgb(28, 183, 116)"};
    const {timelineElements}=Information();
  return (
    <div className='timeline'>
    {/* <h1>Timeline</h1> */}
    <VerticalTimeline>
   
        {
            
            timelineElements.map(element=>{
                {/* console.log(element.id); */}
               return <VerticalTimelineElement
                key={element.id}
                date={element.date}
                
                dateClassName="date"
                iconStyle={iconStyle}
                className="VerticalTimelineElement"
                >
                <h3 className='vertical-timeline-element-title'>{element.title}</h3>
                {/* <h5 className='vertical-timeline-element-subtitle' >{element.location}</h5> */}
                <p className='vertical-timeline-element-description'>{element.description}</p>
                </VerticalTimelineElement>
            }
            )
        
        }
    </VerticalTimeline>
        
    </div>
  )
}

 // "@emotion/react": "^11.9.3",
    // "@emotion/styled": "^11.9.3",
    // "@fortawesome/fontawesome-free": "^6.1.1",
    // "@fortawesome/fontawesome-svg-core": "^6.1.1",
    // "@fortawesome/free-brands-svg-icons": "^6.1.1",
    // "@fortawesome/free-regular-svg-icons": "^6.1.1",
    // "@fortawesome/free-solid-svg-icons": "^6.1.1",
    // "@fortawesome/react-fontawesome": "^0.1.18",
    // "@mui/material": "^5.8.5",
    // "@testing-library/jest-dom": "^5.16.4",
    // "@testing-library/react": "^13.2.0",
    // "@testing-library/user-event": "^13.5.0",
    // "axios": "^0.27.2",
    // "bootstrap": "^5.1.3",
    // "browser-image-compression": "^2.0.0",
    // "firebase": "^9.9.1",
    // "react": "^18.1.0",
    // "react-awesome-reveal": "^3.8.1",
    // "react-bootstrap": "^2.4.0",
    // "react-dom": "^18.1.0",
    // "react-facebook-w": "^8.0.4",
    // "react-icons": "^4.4.0",
    // "react-loader-spinner": "^5.1.5",
    // "react-reveal": "^1.2.2",
    // "react-router-dom": "^6.3.0",
    // "react-scripts": "5.0.1",
    // "react-slick": "^0.29.0",
    // "react-toastify": "^9.0.6",
    // "react-transition-group": "^4.4.2",
    // "react-twitter-embed": "^4.0.4",
    // "slick-carousel": "^1.8.1",
    // "web-vitals": "^2.1.4"
