import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "rgb(242, 180, 210);",
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#888888',
      },
    },
  });
export default function ImpactStoryPagination({ pageCount, handlePageChange }) {
  return (
    <ThemeProvider theme={theme}>
    <Stack spacing={2}>
      <Pagination
        count={pageCount}
        color="primary"
        onChange={(e, newpagenumber) => {
        //   console.log(newpagenumber);
          handlePageChange(newpagenumber);
        }}
      />
    </Stack>
    </ThemeProvider>
  );
}
