import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import Overlay from "../../components/Overlay";
import "../../styles/readmore.css";
import NavBar from "../../components/NavBar";
export default function ReadMore() {
  const navigate = useNavigate();
  const {state} = useLocation();
  console.log(state);
  let {
    title,
    tagline,
    imageurl,
    date,
    theme,
    description,
    pdflink,
    videoLink,
  } = state;
console.log(videoLink);
if(Array.isArray(imageurl)===false)
{
imageurl=[imageurl];
}

  const temptitle = "THE MORE THE MORE";
  const temptagline = "Save money. Live better.";
  const tempimageurl = "https://picsum.photos/536/354";
  const tempdate = "27 july 2019";
  const temptheme = "EDUCATION";
  const tempdescription =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum";

  const tempPdflink = "www.google.com";
  const tempVideolink = "www.google.com";

  // useEffect(()=>{
  //   if(title==undefined&&description==undefined)
  //   {
  //     navigate('/');
  //   }
  // },[])
  let commonReadmoreHeader = (
    <div className="commonReadmoreHeader" >
     <img src={imageurl} />
      <Overlay overLayColor={"#000000aa"} />
      <div className="commonReadmoreHeader-internalDiv">
        <h2>{title}</h2>
      </div>
    </div>
  );
  let commonReadmoreContent = (
    <div className="commonReadmoreContent">
      <div className="commonReadmoreContent-heading">
        <h2>{title}</h2>
      </div>
      <h4>{tagline}</h4>
      <hr />
      <h6>{theme}</h6>
      <p>{date}</p>
      <p>{description}</p>
      {
        imageurl.map((item,i)=>{
          return <img src={imageurl[i]} />
        })
      }
      <hr />
      {pdflink&&<a  target="_blank" href={pdflink}>MORE</a>}

      <br />
      {videoLink&&<a target="_blank" href={videoLink}>VIDEO</a>}
    </div>
  );
  return (
    <div className="common-readmore">
    <NavBar/>
      {commonReadmoreHeader}
      {commonReadmoreContent}

      <Footer />
    </div>
  );
}
