import React, { useEffect, useState } from "react";
// import IMG from '../images/CmFLogo.png';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faS } from "@fortawesome/free-solid-svg-icons";

import "../styles/navBar.css";
export default function Navbar({ position }) {
  const navigate = useNavigate();
  const [navChange, setNavChange] = useState(false);

  const [drawer, setDrawer] = useState(false);
  const [drawerDrawn, setDrawerDrawn] = useState(false);

  const changeBackground = () => {
    if (window.scrollY > 100) setNavChange(true);
    else setNavChange(false);
  };
  const resizeFunc = () => {
    if (window.innerWidth <= 700) setDrawer(() => true);
    else setDrawer(() => false);
  };
  window.addEventListener("scroll", changeBackground);
  window.addEventListener("resize", resizeFunc);

  const handleLogoClick = () => {
    navigate("/");
    // console.log("clicked0");
    // window.location.reload();
    window.scroll(0, 0);
  };
  useEffect(() => {
    // document.getElementById('ourworkid').addEventListener('mouseover', (event) => {
    //   setOurWorkHover(()=>true);
    // })
    resizeFunc();
  }, []);

  return (
    <>
      {!drawer && (
        <div
          className={navChange === true ? "navBar changeNav" : "navBar"}
          style={{ position: position }}
        >
          <div className="nav-logo">
            <img
              src="../../../images/CmFLogo.png"
              onClick={() => navigate("/")}
              className={
                navChange === true && drawer == false ? "changeImgMargin" : ""
              }
              alt="CmFlogo"
            />
          </div>
          <div className="navBar-titles">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>

              <li className="navAbout">
                <Link to="/aboutUs">ABOUT</Link>
                <div className="navAbout-extension extension">
                  <ul>
                    <li>
                      <Link to="/aboutUs">ABOUT US</Link>
                    </li>

                    <li>
                      <Link to="/partners">OUR PARTNERS</Link>
                    </li>
                    <li>
                      <Link to="/reports">KEY INITIATIVES & REPORTS</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="ourwork">
                <Link to="/programs">WHAT WE DO</Link>
                <div className="ourWork-extension extension">
                  <ul>
                    <li>
                      <Link to="/programs/microfinance">Livelihoods & mF</Link>
                    </li>
                    <li>
                      <Link to="/programs/education">Education</Link>
                    </li>
                    <li>
                      <Link to="/programs/nutrition">Nutrition</Link>
                    </li>
                    <li>
                      <Link to="/programs/WASH">WASH</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <Link to="/impactStories">IMPACT STORIES</Link>
              </li>

              <li className="navInsight">
                <Link to="/reflections">INSIGHTS</Link>
                <div className="navInsight-extension extension">
                  <ul>
                    {" "}
                    <li>
                      <Link to="/reflections">REFLECTIONS</Link>
                    </li>
                    <li>
                      <Link to="/research">Research & Publications</Link>
                    </li>
                    <li>
                      <Link to="/awardNrecognition">Awards & Recognition</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="navMedia">
                <Link to="/newsNevents">MEDIA</Link>
                <div className="navMedia-extension extension">
                  <ul>
                    {" "}
                    <li>
                      <div className="navBar-events">
                        <p>Events</p>
                        <ul>
                          <li>
                            <Link to="/newsNevents">News and events</Link>
                          </li>
                          <li>
                            <Link to="/Prof-Vyas">
                              Prof. Vyas V. S. Memorial
                            </Link>
                          </li>
                          <li>
                            <Link to="/colloquium">Colloquium</Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to="/coverage">Media Coverage</Link>
                    </li>
                    <li>
                      <Link to="/videos">Videos</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <Link to="/contactUs">CONTACT US</Link>
              </li>
            </ul>
          </div>

          {/* <FontAwesomeIcon icon={faBars}  className="crossIcon"   onClick={()=>setDrawer(()=>false)} style={ drawer==true?{display:"inline"}:{display:"none"} } /> */}

          {/* <FontAwesomeIcon icon={faBars} className="toggleIcon"  onClick={()=>setDrawer(()=>true)} style={ drawer==true?{display:"none"}:{display:"block"} }  />  */}
        </div>
      )}
      {drawer && (
        <>
          <div className="draw-Navbar">
            <div className="draw-Navbar-logo" onClick={() => handleLogoClick()}>
              <img src="../../../images/CmFLogo.png" />
            </div>
            <FontAwesomeIcon
              icon={faBars}
              className="crossIcon"
              onClick={() => setDrawerDrawn((prev) => !prev)}
            />
          </div>
          <div
            className="draw-Navbar-titles"
            style={drawerDrawn == true ? { display: "" } : { display: "none" }}
          >
            <nav>
              <ul>
                <h6>
                  <li>
                    <Link to="/">HOME</Link>
                  </li>
                </h6>
                <h6> ABOUT US</h6>
                <li>
                  <Link to="/aboutUs">ABOUT US</Link>
                </li>

                <li>
                  <Link to="/partners">OUR PARTNERS</Link>
                </li>
                <li>
                  <Link to="/reports">KEY INITIATIVES & REPORTS</Link>
                </li>
                <h6>WHAT WE DO</h6>

                <li>
                  <Link to="/programs/microfinance">Livelihoods & mF</Link>
                </li>
                <li>
                  <Link to="/programs/education">Education</Link>
                </li>
                <li>
                  <Link to="/programs/nutrition">Nutrition</Link>
                </li>
                <li>
                  <Link to="/programs/WASH">WASH</Link>
                </li>
                {/*--------------MEDIA-----------*/}
                <li>
                  <Link to="/impactStories">
                  <h6>IMPACT STORIES</h6></Link>
                </li>
                <h6>MEDIA</h6>
                {/* <p>EVENTS</p> */}
                <li>
                  <Link to="/newsNevents">News and events</Link>
                </li>
                <li>
                  <Link to="/Prof-Vyas">Prof. Vyas V. S. Memorial</Link>
                </li>
                <li>
                  <Link to="/colloquium">Colloquium</Link>
                </li>

                <li>
                  <Link to="/coverage">Media Coverage</Link>
                </li>
                <li>
                  <Link to="/videos">Videos</Link>
                </li>
                {/*--------------INSIGHTS-----------*/}
                <h6>INSIGHTS</h6>
                <li>
                  <Link to="/reflections">REFLECTIONS</Link>
                </li>
                <li>
                  <Link to="/research">Research & Publications</Link>
                </li>

                <li>
                  <Link to="/awardNrecognition">Awards & Recognition</Link>
                </li>

                {/* --------------CONTACT US-----------*/}
                <h6>
                  {" "}
                  <li>
                    <Link to="/contactUs">CONTACT US</Link>
                  </li>
                </h6>
              </ul>
            </nav>
          </div>
        </>
      )}
    </>
  );
}
