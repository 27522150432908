import React from "react";
import Navbar from "../../../components/NavBar";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Footer from "../../../components/Footer";
import PhotoSlider from "../../../components/PhotoSlider";
import Dots from "../../../components/Dots";
import "../../../styles/skilling.css";
import Overlay from "../../../components/Overlay";
export default function Skilling() {
  let skillHeader = (
    <div className="skillContainer">
      <Navbar />
      {/* <div className="skillContainer-overlay"></div> */}
      <Overlay overLayColor={"#00000099"} z_index={"1"} />
      <PhotoSlider
        imgBase={"programs/skill/S"}
        number={4}
        sizeStyle={{
          width: "100%",
          height: "100%",
        }}
        className="photoSlider"
      />

      <div className="skill-heading-container">
        <Fade left>
          <h1>SKILLING & ENTREPRENEURSHIP</h1>
        </Fade>
        <Fade left delay={1000}>
          <span>bridging the information and mentoring gap</span>
        </Fade>
      </div>
    </div>
  );

  let skillHeading = (
    <div className="skillHeading">
      <h2>SKILLING & ENTREPRENEURSHIP</h2>
      <span>
        Making Skills Development Inclusive for Rural Youths and Informal Sector
        Workers (low literacy groups) to Improve the Quality of Life and Reduce
        the Vulnerability
      </span>
    </div>
  );

  let skillAbout = (
    <div className="skill-about">
      <div className="skill-about-side-img">
        <img src="../images/programs/Education/Pic-2.jpg" />
      </div>
      <div className="skill-about-background-image">
        <Overlay overLayColor={"#444444bb"} z_index={"1"} />
        <p>
          The traditional rural livelihoods of agriculture and livestock based
          livelihoods cannot sustain large families over generations as land and
          water resources remain limited. A large number of youths in South
          Rajasthan work as unskilled wage labour in construction, mining,
          hospitality and other businesses. Through the Skilling Program in
          South Rajasthan, CmF is linking rural youth with a host of skill
          development courses offered by training institutes and supporting
          apprenticeship with local business enterprises.
        </p>
      </div>
    </div>
  );

  let skillStrategy = (
    <>
      {" "}
      <div className="skillstrategy">
        <Fade left>
          <div className="skillstrategy1">
            <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} />
            <span>One-to-One Counselling</span>
          </div>
        </Fade>

        <Fade right>
          <div className="skillstrategy2">
            <Overlay overLayColor={"rgb(175, 79, 114,0.7)"} />
            <span>Awareness and Moblization</span>
          </div>
        </Fade>

        <Fade left>
          <div className="skillstrategy3">
            <Overlay overLayColor={"rgb(209, 194, 63,0.7)"} />
            <span>Continuous Mentorship</span>
          </div>
        </Fade>

        <Fade right>
          <div className="skillstrategy4">
            <Overlay overLayColor={"rgba(138, 70, 155,0.7)"} />
            <span>
              Skill Training through Training Institutes and On-Job-Training
              with local businesses
            </span>
          </div>
        </Fade>

        <div className="skillstrategy0">STRATEGY</div>
      </div>
      <div className="strategy-extrapoints">
        <p>
          <Zoom>
            <ul>
              <li>
                Building awareness and providing high quality one to one
                counselling and advising using technology{" "}
              </li>
              <li>
                Developing a robust mobilization process with tracking of a
                candidates even after two years post training{" "}
              </li>
              <li>
                Providing increased basket of choices for rural youth to pursue
                skill training which are easily accessible, acceptable and
                affordable{" "}
              </li>
              <li>
                Developing systems offering short, modularized and tailor made
                training opportunities by giving due recognizing to prior
                skills.{" "}
              </li>
            </ul>
          </Zoom>{" "}
        </p>
      </div>
    </>
  );
  let skillIntervention = (
    <>
      {" "}
      <div className="skill-key-interventions">
        <div className="skill-key-interventions-heading">
          <h3>KEY INTERVENTIONS</h3>
        </div>
        <div className="skill-key-single-intervention-one">
          <div className="skill-key-single-intervention-one-content">
            <h4>1.Skilling and Entrepreneurship in Sirohi and Udaipur</h4>
            <ul>
              <li>Fostering entrepreneurship in the farm and non-farm space</li>
              <li>
                Economic empowerment encouraging self-investment towards long
                term sustainability and growth
              </li>
              <li>
                Digital Literacy for Banking and Business transactions to bring
                about a paradigm shift in operation of enterprises
              </li>
            </ul>
            <span>Coverage: Sirohi and Udaipur district</span>
            <img src="../../images/programs/skill/key_intervention-one.jpg" />
          </div>

          <div className="key-intervention-one-blockpoints">
            <div className="key-intervention-one-blockpoints-block">
              <p>
                Creation of curated list of business opportunities for
                entrepreneurs to choose
              </p>
            </div>
            <div className="key-intervention-one-blockpoints-block">
              <p>Selection of potential entrepreneurs for training</p>
            </div>
            <div className="key-intervention-one-blockpoints-block">
              <p>
                Training for basic business skills, various market
                opportunities, tasks,finances and networking{" "}
              </p>
            </div>
            <div className="key-intervention-one-blockpoints-block">
              <p>
                Sharpening entrepreneurial acumen through digital tools &
                technology for enterprise management
              </p>
            </div>
            <div className="key-intervention-one-blockpoints-block">
              <p>
                Provision of 'Seed Capital' to trained entrepreneurs to set up
                their enterprises
              </p>
            </div>
            <div className="key-intervention-one-blockpoints-block">
              <p>Handholding support by respective Community Institutions </p>
            </div>
            <div className="key-intervention-one-blockpoints-block">
              <p>Monitoring and Evaluation of entrepreneurs and enterprises</p>
            </div>
          </div>
        </div>
        <div className="skill-key-single-intervention-two">
          <div className="skill-key-single-intervention-two-content">
            <h4>2.Skills Initiative for Informal Sector Workers </h4>
            <p>
              Supporting skill development through awareness creation among
              rural youths, one-to-one counselling sessions with youths and
              parents, partnership with skilling institutes and local
              enterprises (Udyog Mitras), life skills training and post
              placement mentoring. Demonstrating the success of skill model and
              approaches to the Central and State Governments, while also
              evolving sustainable and scalable solutions.
            </p>
            <span>
              {" "}
              Coverage: Abu Road and Pindwara block (Sirohi district)
            </span>
            <img src="../../images/programs/skill/key_intervention-two-pic1.jpg" />
          </div>
          <div className="skill-key-single-intervention-two-otherimg">
            <img src="../../images/programs/skill/key_intervention-two-pic2.jpg" />
            <img src="../../images/programs/skill/key_intervention-two-pic3.jpg" />
          </div>
        </div>
      </div>
      <div className="partners-resources">
        <div className="education_partners">
          <h3>Partnerships & Collaborations</h3>

          <div>
            <p>
              Partnership with 31 private and government run training institutes
              as a mobilization partner for- 1) to address the issue of
              mobilizing students and youths for various courses of interests,
              2) awareness and counselling in rural and tribal regions regarding
              skilling opportunities.
            </p>
          </div>
          <div>
            <p>
              Partnership with Nehru Yuva Kendra (NYK) and Step Academy, Udaipur
              for youth awareness drives in 12 Panchayats for providing the
              youth with the opportunities of training courses in trades such as
              Mason, Plumbing, Hand Pump repairing, two wheeler mechanic and
              Centring
            </p>
          </div>
          <div>
            <p>
              Udyog Mitras: A unique feature of CmF’s skill program is the
              ‘Udyog Mitra’ model wherein local enterprises are on-boarded for
              on-the-job training and apprenticeship. 500+ Udyog Mitras have
              partnered with CmF and are providing critical mentorship to the
              youth willing to learn new trades. Some of the Udyog Mitras also
              participate in the youth mobilization drives organized by CmF and
              help in sensitizing youth on running microenterprises.
            </p>
          </div>
          <div>
            <p>
              CmF has collaborated with Tata STRIVE (an initiative of Tata
              Community Initiatives Trust) for expert support through in-house
              robust technology platform to support mobilization, counselling
              and tracking youth. Tata STRIVE is skilling youth from
              underprivileged backgrounds through innovations in technology,
              pedagogy, and methodology.
            </p>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="skilling">
      {skillHeader}
      <Dots extraStyle={{ marginTop: "2vh" }} />
      {skillHeading}
      {skillAbout}
      <hr />
      {skillStrategy}
      {skillIntervention}

      <Footer />
    </div>
  );
}
