import React, { useEffect, useState } from "react";
import "../../styles/videos.css";
import { Modal } from "react-bootstrap";
import {UploadFile,DeleteFile} from '../../UploadFile';
import {
  PostVideoData,
  GetVideoData,
  DeleteVideoData,
  UploadVideoImage,
} from "../../apis";
import PhotoSlider from "../../components/PhotoSlider";
import { ImTerminal } from "react-icons/im";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import Overlay from "../../components/Overlay";
import Loader from "../../components/Loader";

export default function Video() {
  const [isLoading, setIsLoading] = useState(false);
  const [videoDBdata, setVideoDBdata] = useState([]);
  const [addVideomodalShow, setAddVideomodalShow] = useState(false);

  const fetchVideoData = async () => {
    setIsLoading(() => true);
    const GetVideoDataRes = await GetVideoData();
    let temp=GetVideoDataRes.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setVideoDBdata(() => temp);
    console.log(GetVideoDataRes.data);
    setIsLoading(() => false);
  };
  const deleteVideoDatafunc = async (id) => {
    setIsLoading(() => true);
    const data = {
      id: id,
    };
    const deleteVideoDataRes = await DeleteVideoData(data);
    fetchVideoData();
    setIsLoading(() => false);
  };
  const handleAddVideoSubmit = async (e) => {
    e.preventDefault();
    const title = e.target[0].value;

    const theme = e.target[1].value;
    const linkToVideo = e.target[2].value;

    setAddVideomodalShow(() => false);
    setIsLoading(() => true);
    const time = new Date().getTime();
    const data = {
      title: title,
      theme: theme,
      time: time,
      linkToVideo: linkToVideo,
    
    };
    const uploadVideoDataRes = await PostVideoData(data);
    fetchVideoData();
    setIsLoading(() => false);
  };
  useEffect(() => {
    fetchVideoData();
  }, []);
  let addVideoModal = (
    <Modal
      className="modal-addVideo"
      show={addVideomodalShow}
      onHide={() => setAddVideomodalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addVideo"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addVideo">
        <form onSubmit={(e) => handleAddVideoSubmit(e)}>
          <input className="input" type="text" placeholder="Title" />
          <select name="VideoTheme">
            <option value="Career">Video Theme</option>

            <option value="Community-level Livelihoods & mF">
              {" "}
              Community-level Livelihoods & mF
            </option>
            <option value="Skilling & Entrepreneurship">
              Skilling & Entrepreneurship
            </option>
            <option value="Education">Education</option>
            <option value="WASH">WASH</option>
            <option value="MHM">MHM</option>
            <option value="Nutrition">Nutrition</option>
          </select>
          <input className="input" type="text" placeholder="LINK" />

          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let videoContent = (
    <div className="videoContent">
   
      {videoDBdata.length > 0 &&
        videoDBdata.map((item, i) => {
          return (
            <div className="videoContent-eventContainer-singleEvent">
              <h4>{item.title}</h4>
              <h6>{item.theme}</h6>
           
           
              <iframe
                src={item.linkToVideo}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                title="video"
                className="videoContent-eventContainer-singleEvent-iframe"
              />
              {localStorage.getItem("CmFtoken") != null &&
              <button onClick={()=>deleteVideoDatafunc(item.id)} >DELETE</button>}
            </div>
          );
        })}
    </div>
  );

  let videoHeader = (
    <div className="videoHeader">
    <Navbar />
    <Overlay overLayColor={"#00000055"} z_index={"10"} />
    <div className="Cover-VideoPage">
   
      <PhotoSlider imgBase={"../images/media/Pic-"} number={7} />
    </div>
    <div className="videoHeader-content">
        <h1>VIDEOS</h1>
    </div>
  </div>
  );

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="video">
          {videoHeader}
          <div className="addVideoButton">
          {localStorage.getItem("CmFtoken") != null &&
            <button onClick={() => setAddVideomodalShow(() => true)}>
              ADD Events
            </button>}
          </div>
          {addVideoModal}
          {videoContent}
     
          <Footer />
        </div>
      )}
   
    </>
  );
}
