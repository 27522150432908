import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Dots from "../../components/Dots";
import Header from "../../components/Header";
import Navbar from "../../components/NavBar";
import "../../styles/homePage.css";
import { Button, Modal } from "react-bootstrap";
import HomePrograms from "../../components/homepage/HomePrograms";
import Events from "../../components/homepage/Events";
import SocialMedia from "../../components/SocialMedia";
import {UploadFile,DeleteFile} from '../../UploadFile';
import PhotoSlider from "../../components/PhotoSlider";
import HomePageCoverage from "../../components/homepage/HomePageCoverage";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  PostHeadlineData,
  GetHeadlineData,
  DeleteHeadlineData,
  GetHomeEventData,
  GetHomeStoryData,
} from "../../apis";
import Timeline from "../../components/Timeline";
import Overlay from "../../components/Overlay";
import HomeStory from "../../components/homepage/HomeStory";
import { ToastContainer, toast } from "react-toastify";

export default function HomePage() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [user, setUser] = useState("none");
  const [timeofHeadlineSlide, setTimeofHeadlineSlide] = useState(10000000);
  const [headlineDBdata, setHeadlineDBdata] = useState([]);
  const [showAddHeadlineModal, setShowAddHeadlineModal] = useState(false);
  let lengthofHeadline = 0;
  const SDGLogos = [];
  for (var i = 0; i < 9; i++) SDGLogos.push(i + 1);

  // useEffect(() => {
  //   setTimeofHeadlineSlide(() => lengthofHeadline / 12);
  // }, [lengthofHeadline]);

  const handleAddHeadlineSubmit = (e) => {
    e.preventDefault();
    const title = e.target[0].value;
    const link = e.target[1].value;
    const data = {
      title: title,
      link: link,
    };
    setShowAddHeadlineModal(() => false);
    const uploadRes = PostHeadlineData(data);
    fetchHeadlineData();
  };
  const deleteHeadlineDatafunc = async (id) => {
    const data = {
      id: id,
    };
    const fetchHeadlineDataRes = await DeleteHeadlineData(data);
    fetchHeadlineData();
  };
  const fetchHeadlineData = async () => {
    const fetchHeadlineDataRes = await GetHeadlineData();
    
    setHeadlineDBdata(() => fetchHeadlineDataRes.data);
  };

  const handleLogout = () => {
    console.log("logging out");
    localStorage.removeItem("CmFtoken");
    toast.success("LOGGED OUT");
    // navigate('/');
    window.location.reload();
    setUser(() => "none");
  };

  // const fetchHomeStoryData = async () => {
  //   const fetchHomeStoryDataRes = await GetHomeStoryData();
  //   setHomeStoryDBdata(() => fetchHomeStoryDataRes.data);
  //   console.log(fetchHomeStoryDataRes.data);
  // };

  let token;

  useEffect(() => {
    if (localStorage.getItem("CmFtoken") != undefined) {
      token = localStorage.getItem("CmFtoken");
      setUser(() => "admin");
    } else token = null;
    toast.success("WELCOME");
  }, []);
  let homePageHeadline = (
    <div className="homePageHeadline">
      <div
        className="homePageHeadline-internaldiv"
        style={{ animationDuration: `${timeofHeadlineSlide}s` }}
      >
        <p id="headlinePtag">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
      </div>
      <button onClick={() => setShowAddHeadlineModal(() => true)}>ADD</button>
    </div>
  );
  // lengthofHeadline=document.getElementById('headlinePtag').innerHTML.length;
  lengthofHeadline =
    homePageHeadline.props.children[0].props.children.props.children.length;
  console.log(
    homePageHeadline.props.children[0].props.children.props.children.length
  );

  let homePageHeadlineModal = (
    <Modal
      className="modal-addHeadline"
      show={showAddHeadlineModal}
      onHide={() => setShowAddHeadlineModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addmember"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addHeadline">
        <form onSubmit={(e) => handleAddHeadlineSubmit(e)}>
          <textarea
            name="headline"
            type="text"
            placeholder="Write Something..."
          />
          {/* rows="4" cols="50" */}
          <button type="submit">Update</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let homepageImpactStory = (
    <div className="homepageImpactStory">
      <div className="homepageImpactStory-leftside">
        <h4>IMPACT STORY </h4>
        <p>
          Cmf has influenced a lot of life. lorem ipsom dot dot dot.lorem ipsom
          dot dot dotlorem ipsom dot dot dot
        </p>
      </div>
      <div className="homepageImpactStory-rightside">
        <img src="../images/programs/nutrition/nutrition1.jpg" />
        <p>
          {" "}
          lorem ipsom dot dot dot.lorem ipsom dot dot dotlorem ipsom dot dot
          dotlorem ipsom dot dot dot.lorem ipsom dot dot dotlorem ipsom dot dot
          dot
        </p>
        <Link to="/impactStories">
          <button
          >Read More</button>
        </Link>
      </div>
    </div>
  );

  let homePageHeader = (
    <div className="homePageHeader">
   {user == "admin" && (
          <button className="logoutButton" onClick={() => handleLogout()}>
            LOGOUT
          </button>
        )}
     
      <Navbar position={"fixed"} />
      <Overlay overLayColor={"#6cb68f6e"} z_index={"10"} />
      <div className="Cover-MainPage">
        <PhotoSlider imgBase={"../images/home/PIC-"} number={10} />
      </div>
      <div className="homePageHeader-content">
       
        <h1>Centre for <br style={
            window.innerWidth <= 400 ? { display: "" } : { display: "none" }
          } />  microFinance</h1>
        <h4>Enabling Community, Transforming Lives</h4>
        <p
          style={
            window.innerWidth > 550 ? { display: "" } : { display: "none" }
          }
        >
          A state-level <b>Resource Organization</b> to catalyse the growth of
          community-level institutions, endeavouring to adapt and expand its
          roles and functions in keeping with the evolving needs and aspirations
          of the communities it works with and the macro-environment comprising
          government policies and programs.
        </p>
      </div>


    </div>
  );
  let extratextHomePageHeader = (
    <div
      className=" extratextHomePageHeader"
      style={window.innerWidth <= 550 ? { display: "" } : { display: "none" }}
    >
      <p>
        A state-level <b>Resource Organization</b> to catalyse the growth of
        community-level institutions, endeavouring to adapt and expand its roles
        and functions in keeping with the evolving needs and aspirations of the
        communities it works with and the macro-environment comprising
        government policies and programs.
      </p>
    </div>
  );
  let homePageApproach = (
    <div className="homePageApproach">
      <h3>OUR APPROCH</h3>
      <p>
        Ensuring <b>SUSTAINABILITY</b> of impact, knowledge and community led processes
        remains the core of all programmes of CmF. We endeavour to build and
        share <b>KNOWLEDGE</b> and promote <b>INNOVATIONS</b> in all themes we work in.
        Building <b>PARTNERSHIPS</b> with stakeholders- community institutions,
        non-profits, banks, government, and others to mainstream the learnings
        and best practices of our work in larger programmes for desired impact
        is our key approach.
      </p>
    </div>
  );
  let homePageSustainableGoals = (
    <div className="homePageSustainableGoals">
      <div className="homePageSustainableGoals-heading">
        <img src="../images/home/SDGLogo.jpg" />
        <h4>
          {" "}
          CmF is striving to contribute 9 out of 17 SDGs set  by the United
          Nations{" "}
        </h4>
      </div>

      <div className="homePageSustainableGoals-content">
        {SDGLogos.map((item, i) => {
          return (
            <div className="SDGLogo-single">
              <img src={`../images/home/E_PRINT_0${item}.jpg`} />
            </div>
          );
        })}
      </div>
    </div>
  );

  let homePageTimeline = (
    <div className="homePageTimeline">
      <Timeline />
    </div>
  );
  return (
    <div className="homePage">
      {homePageHeader}
      {extratextHomePageHeader}
      <div className="Content-HomePage">
        {/* {homePageHeadlineModal} */}
        {homePageApproach}
        {homePageTimeline}
        <HomePageCoverage />
        <HomePrograms />
        <Events />
        <HomeStory />
        <SocialMedia />
        {homePageSustainableGoals}
      </div>
      <ToastContainer />
      {/* <button onClick={()=>navigate('/adminLogin')}>ADMIN LOGIN</button> */}
      <Footer />
    </div>
  );
}
