import React, { useEffect, Suspense, lazy, useState } from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import "../../styles/contactus.css";
import Fade from "react-reveal/Fade";
import { SiGooglemessages } from "react-icons/si";
import { TbBrandGmail } from "react-icons/tb";
import { AiOutlinePhone } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { HiOfficeBuilding } from "react-icons/hi";
import {UploadFile,DeleteFile} from '../../UploadFile';
import { Modal } from "react-bootstrap";

import {
  SendContactMail, UploadContactResume,UploadVacancyFile,UploadVacancyData,GetVacancyData,DeleteVacancyData
} from '../../apis';
// import Loading from "../components/common/Loading";
// import Navbar from "../../components/NavBar";
// const ContactCover = lazy(() => import('../components/contact/cover'));
// const MainContent = lazy(() => import('../components/contact/content'));
// const Footer = lazy(() => import('../components/common/footer'));

const Contact = () => {
  
 const [vacancyDBdata,setVacancyDBdata] = useState([]); 
const [isLoading,setIsLoading]=useState(false);
 const [queryType,setQueryType] =useState("none");
 const [contactResume,setContactResume] =useState();
 const [addVacancymodalShow,setAddVacancymodalShow] = useState(false);
 

 const handleResumeChange=(e)=>{
console.log(e.target.files[0])
 }

 const handleContactFormSubmit=async(e)=>{

  e.preventDefault();
  console.log(queryType);

  const name=e.target[0].value;
  const phone=e.target[1].value;
  const email=e.target[2].value;
  const query=e.target[3].value;
  const message=e.target[4].value;
  let data={
    name:name,
    phone:phone,
    email:email,
    query:query,
    message:message,
  }
  
  if(queryType==='Career')
  {
    console.log("contact from")
    const myFile=new FormData();
    myFile.append("file", e.target[5].files[0]);
    const fileurlRes= await UploadContactResume(myFile);
    const fileurl=fileurlRes.data.data;
    console.log(fileurlRes);
    data={...data,fileurl:fileurl};
    const mailRes= await SendContactMail(data);
  
  }
  else{
    // console.log("contact from 2")
   
    const mailRes= await SendContactMail(data);
  // const mailRes= await SendContactMail(data);
  }
  e.target[0].value="";
  e.target[1].value="";
  e.target[2].value="";
  e.target[3].value="";
 e.target[4].value="";
 }


 const fetchVacancyData = async () => {
  setIsLoading(() => true);
  const GetVacancyDataRes = await GetVacancyData();
  let temp=GetVacancyDataRes.data;
  temp=temp.sort((item1,item2)=>item1.time-item2.time);
  setVacancyDBdata(() => temp);
  console.log(GetVacancyDataRes.data);
  setIsLoading(() => false);
 
};
const deleteVacancyDatafunc = async (id) => {
  setIsLoading(() => true);
  const data = {
    id: id,
  };
  const deleteVacancyDataRes = await DeleteVacancyData(data);
  fetchVacancyData();
  setIsLoading(() => false);
};


 const handleAddVacancySubmit = async(e)=>{
   e.preventDefault();
   const jobTitle=e.target[0].value;
   const jobDescription=e.target[1].value;
   const googleFormLink=e.target[2].value;

   const file = e.target[3].files[0];
   setAddVacancymodalShow(false)
   const UploadFileRes=await UploadFile(file,'contact');
   const fileurl= UploadFileRes.fileUrl;
    const fileRef=UploadFileRes.fileRef;
  const time =new Date().getTime();
   const data={
    jobTitle:jobTitle,
    jobDescription:jobDescription,
    fileurl:fileurl,
    googleFormLink:googleFormLink,
    time:time,
    fileRef:fileRef
   }
   
   const vacancyDataUploadRes= await UploadVacancyData(data);
   setVacancyDBdata(()=>vacancyDataUploadRes.data);
   fetchVacancyData();
 }


  useEffect(() => {
    const func = () => {
      document.title = "Contact |   CmF";
    };
    func();
    fetchVacancyData();
  }, []);
  let contactUsHeader = (
    <div className="contactUsHeader">
      <Navbar />
      <div className="contactUsHeader-img">
        <img src={"../images/contactUs/contactUsCover.jpg"} />
      </div>
      <div className="contactUsHeader-heading">
        <h1>CONTACT US</h1>
      </div>
    </div>
  );

  let iconStyle = {
    paddingRight: "10px",
    color: "#678905",
    fontSize: "30px",
    fontWeight: 900,
  };
  let contactUsContent = (
    <div className="contactUsContent">
      <div className="contactUsContent-one">
        <div className="contactUsContent-one-left">
          <p>
            {" "}
            You can get in touch at the addresses and telephone numbers
            mentioned or fill in the form to contact us.
          </p>
        </div>
        <div className="contactUsContent-one-right">
          <div className="contactUsContent-one-right-internalDiv">
            <span>
              <TbBrandGmail style={iconStyle} />
         
            </span>

            <span> CMF@CMFRAJ.ORG </span>
            <br />

            <span>
              <AiOutlinePhone style={iconStyle} />
     
            </span>
            <span> +91-141-4025909 </span>
            <br />
            {/* <span>
              <HiOfficeBuilding style={iconStyle} />
              HEADQUARTERS :
            </span>
            <span>Jaipur, Rajasthan</span>
            <br /> */}
            <span>
              <ImLocation2 style={iconStyle} />
        
            </span>
            <span>
           N-102 (South), Adinath Nagar, J.L.N. Marg, Jaipur, 302018, Rajasthan
            </span>
          </div>
        </div>
      </div>
      <div className="contactUsContent-two">
        <h5>Write to us</h5>
        <form onSubmit={(e)=>handleContactFormSubmit(e)} >
          <input type="text" name="name" placeholder="Name" />
          <input type="number" name="name" placeholder="Phone No." />
          <input type="email" name="email" placeholder="Email" />
          <select name="QueryType" onChange={(e)=>setQueryType(()=>e.target.value)} >
            <option value="">Query Related to *</option>
            <option value="Career">Careers</option>
            <option value="Volunteering">Volunteering</option>
            <option value="Donation">Donation</option>
            <option value="Others">Others</option>
          </select>

          <textarea
            id="w3review"
            name="w3review"
            placeholder="Type your message here...."
            rows="4"
          ></textarea>
        {queryType=="Career"&&<>
        <label>Resume</label>
        <input type="file" name="CoverLetter" placeholder="Resume" onChange={(e)=>handleResumeChange(e)} />
        {/* <label>Resume</label>
          <input type="file" name="Resume" placeholder="Resume" /> */}
         </>
        }
          <button type="submit" >Send</button>
        </form>
      </div>

    </div>
  );

  let postVacancyModal=(<Modal
    className="modal-addVanacy"
    show={addVacancymodalShow}
    onHide={() => setAddVacancymodalShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter-addVanacy"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-body-addVanacy">
      <form onSubmit={(e) => handleAddVacancySubmit(e)}>

        <input className="input" type="text" placeholder="Job Title" />
        <textarea className="input" type="text" placeholder="Job description (timeline / deadline included)" />
        <input className="input" type="text" placeholder="Google Form Link" />
        <input className="input" type="file" placeholder="ATTACHMENT PDF" />
        <label>JOB DESCRIPTION</label>
        <button type="submit">SUBMIT</button>
      </form>
    </Modal.Body>
  </Modal>)
  

  let vacancyContent =(<div className="vanancyContent" >
   {localStorage.getItem("CmFtoken") != null &&
   <button className="post-vacancy-button" onClick={()=>setAddVacancymodalShow(()=>true)} >POST VACANCY</button>}
   <h3>VACANCY</h3>
   <div className="vanancyContent-vacancies">
    {vacancyDBdata.length==0&&
     <p  className="vanancyContent-vacancies-novacancy" >CURRENTLY THERE IS NO VACANCY</p>
    }
    {
      vacancyDBdata.length>0&& vacancyDBdata.map((item,i)=>{
return <div  className="vanancyContent-vacancies-single" >
  <h4> <b>POSITION :</b> {item.jobTitle}</h4>
  <p><b>JOB DESCRIPTION : </b>{item.jobDescription}</p>
<div className="vanancyContent-vacancies-single-buttons" >
<button > <a target="_blank" href={item.fileurl} >KNOW MORE</a></button>
  <button><a target="_blank" href={item.googleFormLink} >APPLY HERE</a></button>
  {localStorage.getItem("CmFtoken") != null &&<button onClick={()=>deleteVacancyDatafunc(item.id)} >DELETE</button>}
</div>
 
</div>
      })  
    }
   </div>
  </div>)
  return (
    <div className="contactUs">
      {contactUsHeader}

      {contactUsContent}
      {vacancyContent}
      {postVacancyModal}
      
      <Footer />
    </div>
  );
};

export default Contact;
