import React from "react";
import "../styles/card.css";
// import cardimg from "../images/1626677755681.jpg";
export default function Card({ name, img, desc, extraStyle }) {
  // let extraStyle = { width: "40vw" };
  return (
    <div className="card text-center singleCard" style={extraStyle}>
      <div className="singleCard-img-container">
        <img src={img} alt="card pic" className="singleCard-img" />
      </div>
      <div className="card-body">
        <h4 className="card-title">{name}</h4>
        <p className="card-text ">{desc}</p>
      </div>
    </div>
    //
  );
}
// style={extraStyle }
