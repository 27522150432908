import React, { useEffect, useState } from 'react'
import '../../styles/adminLogin.css';
import 'animate.css';
import Overlay from '../../components/Overlay';
import {AutheticateAdmin} from '../../apis';
import { useNavigate } from "react-router-dom";

 

export default function AdminLogin() {

  const [isLoading,setIsLoading]=useState(false);
 
  let navigate = useNavigate();

  const [showPass,setShowPass] = useState(false);

  


 const handleLoginSubmit = async (e)=>{
  
  e.preventDefault();
  const email=e.target[0].value;
  const password=e.target[1].value;
  const data={
    email:email,
    password:password
  }
   const loginRes= await AutheticateAdmin(data);
  console.log(loginRes.data);

  if(loginRes.data.status==200)
  {
    localStorage.setItem("CmFtoken", loginRes.data.accessToken);
    navigate('/');
  }
  else
  {
    
  }
  }

  useEffect(()=>{
    console.log(localStorage.getItem('CmFtoken'));
    if(localStorage.getItem('CmFtoken')!=null)
  {
    navigate('/');
  }
},[])

  return (
    <div className='adminLogin'>
    <Overlay overLayColor={"#740542e1"} z_index={"10"}/>
    <img src="https://img.freepik.com/free-photo/gray-abstract-wireframe-technology-background_53876-101941.jpg?t=st=1653614271~exp=1653614871~hmac=0926a938b1fc605694b8f49eb6e75473e5b9f3e096951de554738b9f6aa0e530&w=900"/>
    
    <div className="adminLogin-form ">
    <div className="title">Welcome</div>
    <div className="subtitle">Enter your login credentials!</div>
    <form  onSubmit={(e)=>handleLoginSubmit(e)} >

     <div className="input-container ic1">
      <input id="email" className="input"  type="text" placeholder="Email" />
      {/* <div class="cut"></div> */}
      {/* <label for="Email" class="placeholder">Email</label> */}
    </div>

    <div className="input-container ic2">
      <input id="password" className="input" type={showPass?"text":"password"} placeholder="Password" />

      {/* <div class="cut"></div> */}
      {/* <label for="lastname" class="placeholder">******</label> */}
    </div>
  
    <button type="submit" className='adminLogin-form-SubmitButton' >submit</button>
    <span className='adminLogin-form-showPass'  onClick={()=>setShowPass((prev)=>!prev)} >show password</span>
    </form>
  </div>
 
  </div>
  )
}
