import React, { useEffect, useState } from "react";
import Navbar from "../../../components/NavBar";
import { Button, Modal } from "react-bootstrap";
import "../../../styles/ourTeam.css";
import Card from "../../../components/Card";
// import Img from '../../../images/cmf_chairman.jpg';
import Footer from "../../../components/Footer";
import Dots from "../../../components/Dots";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import {UploadFile,DeleteFile} from '../../../UploadFile';
import { ToastContainer, toast } from "react-toastify";
import { namespace } from "react-reveal/globals";
import Loader from "../../../components/Loader";

import {
  PostTeamMemberData,
  GetTeamMemberData,
  DeleteTeamMemberData,
  UploadTeamMemberImage,
} from "../../../apis";

export default function OurTeam() {
  const [isLoading, setIsLoading] = useState(false);
  const [teamMemberDBdata, setTeamMemberDBdata] = useState([]);
  const [teamMemberDBGMdata, setTeamMemberDBGMdata] = useState([]);
  const [teamMemberDBPOdata, setTeamMemberDBPOdata] = useState([]);
  const [teamMemberDBGENdata, setTeamMemberDBGENdata] = useState([]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalTeamMemberdata, setModalTeamMemberdata] = useState({});
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const fetchTeamMemberData = async () => {
    // setIsLoading(() => true);
    const GetTeamMemberDataRes = await GetTeamMemberData();
    let temp=GetTeamMemberDataRes.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);

    const tempTeamData = GetTeamMemberDataRes.data;
    setTeamMemberDBdata(() => temp);
    console.log(GetTeamMemberDataRes.data);

    const tempGMdata = tempTeamData.filter((item) => item.category == "GM");
    const tempPOdata = tempTeamData.filter((item) => item.category == "PO");
    const tempGENdata = tempTeamData.filter(
      (item) => item.category == "General"
    );
    console.log(tempGENdata);
    tempGENdata.sort((a, b) => b.atCMF > a.atCMF);
    console.log(tempGENdata);
    setTeamMemberDBGMdata(() => tempGMdata);
    setTeamMemberDBPOdata(() => tempPOdata);
    setTeamMemberDBGENdata(() => tempGENdata);

    // setIsLoading(() => false);
  };
  const deleteTeamMemberDatafunc = async (id) => {
   
    setIsLoading(() => true);
    const data = {
      id: id,
    };
    const deleteTeamMemberDataRes = await DeleteTeamMemberData(data);
    fetchTeamMemberData();
    setIsLoading(() => false);
    toast.success("DELETED");
  };

  const handleAddMember = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const designation = e.target.designation.value;
    const experience = e.target.experience.value;
    const atCMF = e.target.atCMF.value;
    const qualification = e.target.qualification.value;
    const expertise = e.target.expertise.value;
    const courses = e.target.courses.value;
    const hoobies = e.target.hoobies.value;
    const description = e.target.description.value;
    const category = e.target.category.value;
    console.log(category);
    console.log(hoobies);

 
    setShowAddMember(() => false);
    setIsLoading(() => true);
    const file =e.target[10].files[0];
   
    const UploadFileRes=await UploadFile(file,'team');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
    const time = new Date().getTime();

    const data = {
      name: name,
      designation: designation,
      experience: experience,
      atCMF: atCMF,
      qualification: qualification,
      expertise: expertise,
      courses: courses,
      hoobies: hoobies,
      description: description,
      imageurl: imageurl,
      category: category,
      time: time,
      fileRef:fileRef,
    };
    console.log(data);
    const uploadTeamMemberDataRes = await PostTeamMemberData(data);
    fetchTeamMemberData();
    setIsLoading(() => false);
    toast.success("UPLOADED");
    // console.log(memberData);

    // const res = await Ping();
    // console.log(res);
    // post
  };

  const handleShowAddMember = () => {
    console.log(showAddMember);
    setShowAddMember((prev) => !prev);
  };

  useEffect(() => {
    fetchTeamMemberData();
  }, []);

  function CategoryMember(categoryData) {
    return (
      <div className="teamContent-blocks">
        {categoryData.length > 0 &&
          categoryData.map((item, i) => {
            return (
              <Fade bottom delay={500 + (i % 4) * 100} duration={2000}>
                <div className="teamContent-singleBlock">
                  <div className="teamContent-singleBlock-imgContainer">
                    <img src={item.imageurl} />
                  </div>
                  <span>{item.name}</span>
                  <span>{item.designation}</span>
                  <hr />
                  <button
                    // variant="primary"

                    onClick={() => {
                      setModalTeamMemberdata(() => item);
                      setModalShow(() => true);
                    }}
                  >
                    KNOW MORE
                  </button>
                  {localStorage.getItem("CmFtoken") != null &&<button onClick={() => deleteTeamMemberDatafunc(item.id)}>
                    DELETE
                  </button>}
                </div>
              </Fade>
            );
          })}{" "}
      </div>
    );
  }

  return (
    <div className="ourTeam">
      {/* <div className="ourTeam-cover">
        <Navbar />
       
        <div className="ourTeam-cover-overlay"></div>
        <Fade left duration={2000}>
          <div className="ourTeam-cover-heading">MEET OUR TEAM</div>
        </Fade>
      </div> */}
      {/* <Dots extraStyle={{ marginTop: "2vh" }} /> */}
      <div className="teamContent">
        <div className="teamContent-heading">
          <Fade top>
            {" "}
            <h3>OUR TEAM</h3>
          </Fade>
        </div>
        <div className="add-member">
        {localStorage.getItem("CmFtoken") != null &&
          <button onClick={() => handleShowAddMember()}>ADD MEMBER</button>}
        </div>

        {CategoryMember(teamMemberDBGMdata)}
        <hr />
        {CategoryMember(teamMemberDBPOdata)}
        <hr />
        {CategoryMember(teamMemberDBGENdata)}
      </div>

      <Modal
        className="modal-addmember"
        show={showAddMember}
        onHide={() => setShowAddMember(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter-addmember"
        centered
      >
        <Modal.Body className="modal-body-addmember">
          <form onSubmit={(e) => handleAddMember(e)}>
            <label id="icon" for="name">
              NAME
            </label>
            <input type="text" name="name" id="name" placeholder="" />
            <label id="icon" for="designation">
              DESIGNATION and THEME
            </label>
            <input
              type="text"
              name="designation"
              id="designation"
              placeholder=""
            />
            <label id="icon" for="experience">
              PROFESSIONAL EXPERIENCE IN YEARS
            </label>
            <input
              type="text"
              name="experience"
              id="experience"
              placeholder=""
            />
            <label id="icon" for="atCMF">
              AT CMF SINCE (MONTH & YEARS)
            </label>
            <input type="date" name="atCMF" id="atCMF" placeholder="" />
            <label id="icon" for="qualification">
              QUALIFICATION
            </label>
            <input
              type="text"
              name="qualification"
              id="qualification"
              placeholder=""
            />
            <label id="icon" for="expertise">
              EXPERTISE/SPECIALIZATION
            </label>
            <input type="text" name="expertise" id="expertise" placeholder="" />
            <label id="icon" for="name">
              COURSES/CERTIFICATES
            </label>
            <input type="text" name="courses" id="courses" placeholder="" />
            <label id="icon" for="hoobies">
              HOBBIES
            </label>
            <input type="text" name="hoobies" id="hoobies" placeholder="" />
            <label id="icon" for="description">
              DESCRIPTION
            </label>
            <textarea
              type="text"
              name="description"
              id="description"
              placeholder=""
            />

            <label id="icon" for="category">
              CATEGORY
            </label>
            <select type="text" name="category" id="" placeholder="">
              <option value="General"> General</option>
              <option value="PO">PO</option>
              <option value="GM">GM</option>
            </select>

            <label id="icon" for="image">
              PHOTOGRAPH
            </label>
            <input type="file" name="image" id="image" placeholder="IMAGE" />
            <button type="submit">SUBMIT</button>
          </form>
        </Modal.Body>
      </Modal>

      <MemberDetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalTeamMemberdata}
      />
 <ToastContainer autoClose={1000} />
      {isLoading && <Loader />}
      {/* <Footer /> */}
    </div>
  );
}

function MemberDetailModal(props) {
  console.log(props);
  const data = props.data;
  return (
    <Modal
      className="modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{data.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-body">
        <div className="teammember-popup">
          <div className="teammember-popup-photo-name">
            <img src={data.imageurl} />
            <span>{data.name}</span>
            <span>{data.designation}</span>
          </div>

          <div className="teammember-popup-about">
            <span>
              <b>EXPERIENCE </b>
              {data.experience}
            </span>
            <span>
              <b>AT CMF SINCE </b>
              {data.atCMF}
            </span>
            <span>
              <b>QUALIFICATION </b>
              {data.qualification}
            </span>
            <span>
              <b>EXPERTISE </b>
              {data.expertise}
            </span>
            <span>
              <b>COURSES </b>
              {data.courses}
            </span>
            <span>
              <b>HOBBIES </b>
              {data.hoobies}
            </span>
          </div>
          <div className="teammember-popup-brief">
            <p>{data.description}</p>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

// function NewMemberFormModal(props){
// return (

// )

// }

const teamData = [
  {
    divisionName: "SubDivision1",
    list: [
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Sanal",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Deep",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Shivam",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
    ],
  },
  {
    divisionName: "SubDivision2",
    list: [
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Sanal",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Deep",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Shivam",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
    ],
  },
  {
    divisionName: "SubDivision3",
    list: [
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Vinod",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Sanal",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Deep",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
      {
        name: "Shivam",
        img: "./images/cmf_chairman.jpg",
        desc: "He is the worker at xyz level",
      },
    ],
  },
];
