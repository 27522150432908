import React, { useState } from "react";
import Navbar from "../../../components/NavBar";
import Overlay from "../../../components/Overlay";
import "../../../styles/programs.css";
import HomePrograms from "../../../components/homepage/HomePrograms";
import Footer from "../../../components/Footer";
import PhotoSlider from "../../../components/PhotoSlider";
import { Modal } from "react-bootstrap";
import {UploadFile,DeleteFile} from '../../../UploadFile';
import { HiArrowCircleRight,HiArrowCircleDown } from "react-icons/hi";

import {PostProgramImageData,UploadProgramImageImage} from '../../../apis';

export default function Programs() {
  const [isLoading,setIsLoading] = useState(false);
  const [addProgramImagemodalShow,setAddProgramImagemodalShow] = useState(false);

   const handleAddProgramImageSubmit = async (e) => {
   
        e.preventDefault();
    const programType = e.target[0].value;
  
    setAddProgramImagemodalShow(() => false);
    const file = e.target[1].files[0];
   
    const UploadFileRes=await UploadFile(file,'programs');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
 
   
    const time = new Date().getTime();

    const data = {
      time: time,
      imageurl:imageurl,
      programType:programType,
      fileRef:fileRef,
    };
    const uploadProgramImageDataRes = await PostProgramImageData(data);
  };



  let programsHeader = (
    <div className="programsHeader">

<div className="programsContainer">
        <Navbar />
        <div className="programsContainer-overlay"></div>

        <PhotoSlider
          imgBase={"programs/Cover/Pic-"}
          number={10}
          sizeStyle={{
            width: "100%",
            height: "100%",
          }}
          className="photoSlider"
        />
        <div className="programs-heading-container">
       
            <h1>PROGRAMS</h1>
        </div>

      </div>

    </div>
  );

  let programsTypes = (
    <div className="programs-HomePrograms">
      <HomePrograms />
    </div>
  );
  let programsContent = (
    <div className="programsContent">
      <h5>
        A three-point approach that we endeavour to incorporate in all our
        current and future engagements to sustainably change the quality of
        lives
      </h5>
      <div className="programsContent-internal-div">
        <div>
          <h6>1.Small Scale Pilot for Proof of Concept</h6>
          <ul>
            <li>Demonstration sites</li>
            <li>In-house training programmes</li>
            <li>Resource development</li>
            <li>Monitoring, Evaluation and Learning Process</li>
          </ul>
        </div>
        <HiArrowCircleRight className="HiArrowCircleRight" />
        <HiArrowCircleDown className="HiArrowCircleDown" />
        
        <div>
          {" "}
          <h6>2.Scale-up through Govt. partnerships</h6>
          <ul>
            <li>Strengthen govt. training programmes</li>
            <li>Activate community-led institutions</li>
            <li>Advocacy with block & district-level govt. system</li>
          </ul>
        </div>
        <HiArrowCircleRight className="HiArrowCircleRight" />
        <HiArrowCircleDown className="HiArrowCircleDown" />
        <div>
          {" "}
          <h6>3.Technical Support at districts and state-level</h6>
          <ul>
            <li>Technical partner at districts and state.</li>
            <li>
              Capacity building and Knowledge partnership with state govt.
            </li>
          </ul>{" "}
        </div>
      </div>
    </div>
  );

  let programsAddImageModal=( <Modal
    className="modal-addProgramImage"
    show={addProgramImagemodalShow}
    onHide={() => setAddProgramImagemodalShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter-addProgramImage"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">ADD INITIATIVE</Modal.Title>
    </Modal.Header>
    <Modal.Body className="modal-body-addProgramImage">
      <form onSubmit={(e) => handleAddProgramImageSubmit(e)}>
      <select name="programCategory"  >
            <option value="education">EDUCATION</option>
            <option value="nutrition">NUTRITION</option>
            <option value="livelihood">LIVELIHOOD etc</option>
            <option value="wash">WASH</option>
          </select>
      <input className="input" type="file" placeholder="IMAGE" />
      <label>Add IMAGE</label>
 
      <button type="submit">SUBMIT</button>
      </form>
    </Modal.Body>
  </Modal>);
  return (
    <div className="programs">
      {programsHeader}
      {programsContent}
      {programsTypes}
      {localStorage.getItem("CmFtoken") != null &&
      <button onClick={()=>setAddProgramImagemodalShow(()=>true)}  className="add-program-image" >ADD IMAGE</button>}
      {programsAddImageModal}
      <Footer />
    </div>
  );
}
