import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/homeStory.css";

import Loader from "../Loader";
import { GetHomeStoryData } from "../../apis";
import { useNavigate } from "react-router-dom";
export default function HomeStory() {
  const [isLoading, setIsLoading] = useState(false);
  const [homeStoryDBdata, setHomeStoryDBdata] = useState([]);
const navigate=useNavigate();
  var settings = {
    // accessibility:true,
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    // centerMode:true,
    focusOnSelect: true,
  };
  const sampleStoryCard = [];
  for (var i = 0; i <= 3; i++) {
    sampleStoryCard.push(<div className="slidesampleStoryCard">{i}</div>);
  }
  const fetchHomeStoryData = async () => {
    const fetchHomeStoryDataRes = await GetHomeStoryData();
    setHomeStoryDBdata(() => fetchHomeStoryDataRes.data);
    console.log(fetchHomeStoryDataRes.data);
  };
  useEffect(() => {
    fetchHomeStoryData();
  }, []);
  return (
    <div className="HomeStory">
      <div className="HomeStory-left">
        <h4>IMPACT STORIES</h4>
        <p>
          Through concerted efforts of CmF and its partners, glimpses of
          transformation are now visible in many pockets, impacting the lives of
          a large number of underprivileged families in Rajasthan and beyond.
          With the scaling-up of well-tested pilot and proof of concepts with
          robust monitoring processes, shared understanding with partners and
          government with the aim to embed our measures into government
          monitoring framework have helped to increase our visibility,
          sustainability, and government ownership of key initiatives. The
          Frequent research studies and impact assessments validate and deepen
          our learning.
        </p>
      </div>

      <Slider {...settings} className="HomeStory-slideShow">
     
        {homeStoryDBdata.length > 0 &&
          homeStoryDBdata.map((item, i) => {
            return (
              <div className="HomeStorysliderSingle">
                <div className="HomeStorysliderSingle-img">
                  <img src={item.imageurl} />
                </div>

                <div className="HomeStorysliderSingle-content">
                  <h4>{item.heading}</h4>
                  <h6>{item.tagline}</h6>
                  <p>{item.description}</p>
                </div>
            

                <button
                  className="HomeStorysliderSingle-readmore"
                  onClick={() => {
                    navigate("/more", {
                      state: {
                        title:item.heading,
                        tagline:item.tagline,
                        imageurl:item.imageurl,
                        date:"",
                        theme:"",
                        description:item.description,
                        pdflink:"",
                        videoLink:"",
                      },
                    });
                  }}
                >
                  READ MORE
                </button>
              </div>
            );
          })}

  
      </Slider>
    </div>
  );
}
