import React, { useEffect, useState } from "react";
import Dots from "../../../components/Dots";
import Navbar from "../../../components/NavBar";
import "../../../styles/education.css";
import Footer from "../../../components/Footer";
import PhotoSlider from "../../../components/PhotoSlider";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// import { Fade } from "react-awesome-reveal";
import Reveal from "react-reveal/Reveal";
// import TransitionGroup from "react-transition-group/TransitionGroup";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { GetProgramImageData, DeleteProgramImageData } from "../../../apis";
import Overlay from "../../../components/Overlay";
import { DeleteFile } from "../../../UploadFile";
function Education() {
  const [educationImage, setEducationImage] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(0);

  var settings = {
    // accessibility:true,
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    speed: 1900,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    // centerMode:true,
    focusOnSelect: true,
  };

  const deleteEducationImage = async (id) => {
    await DeleteProgramImageData;
  };
  const tempEducationImage = [
    { imageurl: "https://picsum.photos/536/354" },
    {
      imageurl:
        "https://images.unsplash.com/photo-1566275529824-cca6d008f3da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGhvdG98ZW58MHx8MHx8&w=1000&q=80",
    },
    {
      imageurl:
        "https://cdn.mos.cms.futurecdn.net/CAZ6JXi6huSuN4QGE627NR-1200-80.jpg.webp",
    },
    {
      imageurl:
        "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
    },
  ];
  const deletePic=async (id,fileRef)=>{
    const data={
      id:id
    }
    await DeleteProgramImageData(data);
    await DeleteFile(fileRef);
    // fetchEducationImage();
    window.location.reload(false);
  }
  const fetchEducationImage = async () => {
    const fetchEducationImageRes = await GetProgramImageData();
    console.log(fetchEducationImageRes.data);
    let data = fetchEducationImageRes.data.filter(
      (item) => item.programType == "education"
    );
    let temp = data;
    temp = temp.sort((item1, item2) => item1.time - item2.time);
    setEducationImage(() => temp);
    console.log(data);
  };

  useEffect(() => {
    fetchEducationImage();
    let tempSlideToShow = 3;
    if (window.innerWidth < 1000) tempSlideToShow = 2;
    else if (window.innerWidth < 600) tempSlideToShow = 1;
    setSlidesToShow(() => tempSlideToShow);
  }, []);

  return (
    <div className="education">
      <div className="educationContainer">
        <Navbar />
        <div className="educationContainer-overlay"></div>

        <PhotoSlider
          imgBase={"programs/Education/Pic-"}
          number={10}
          sizeStyle={{
            width: "100%",
            height: "100%",
          }}
          className="photoSlider"
        />

        <div className="education-heading-container">
          <Fade left>
            <h1>EDUCATION</h1>
          </Fade>
          <Fade left delay={1000}>
            <span
              style={
                window.innerWidth < 400 ? { display: "none" } : { display: "" }
              }
            >
              Strengthening learning environment for accessible,
              <br /> enjoyable and meaningful education
            </span>
          </Fade>
        </div>

        {/* <div className='color-overlay'></div> */}
      </div>

      <div className="educationHeading">
        {/* <h2>Education</h2> */}
        <span
          style={
            window.innerWidth > 400 ? { display: "none" } : { display: "" }
          }
        >
          Strengthening learning environment for accessible, enjoyable and
          meaningful education
        </span>
      </div>

      <div className="educationAbout">
        {/* <Overlay overLayColor={"#333333dd"} /> */}
        <div className="educationAbout-content">
          <p>
            Poor learning levels of students in rural and government schools
            continue to remain a big dilemma of policy makers and development
            practitioners in the country. The problem gets magnified especially
            in tribal areas where early school drop outs are the norm as
            children need to help their parents in earning their daily bread.
            Teachers’ capacities are constrained due to lack of of qualitative
            in-service capacity building and support process which also affect
            their motivation. CmF is making concerted efforts to create enabling
            learner friendly physical environment and contextualized pedagogical
            processes in government schools and Anganwadis so that children
            enjoy the process of learning and develop strong foundational
            skills.
          </p>
        </div>
      </div>

      <div className="educationstrategy-new">
        <h4>Strategy & Intervention Areas</h4>
        <div className="educationstrategy-content">
          <Fade left>
            <div className="educationstrategy1-new">
              <h6>Create demonstration sites - model schools and Anganwadis</h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>Making physical spaces vibrant and enjoyable </li>
                <li>
                  {" "}
                  Augmenting resources for children and teachers – teaching and
                  learning aids
                </li>
                <li>
                  Involving children in management and active usage of resources{" "}
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="educationstrategy2-new">
              <h6>Capacity building of teachers and Anganwadi cadre </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>
                  Focus on <b>Early Childhood Education, Libraries,</b> and{" "}
                  <b>Foundational Literacy and Numeracy</b>{" "}
                </li>
                <li>
                  Training programs and short-term courses for teachers and
                  resource persons
                </li>
                <li>On-site support for improved pedagogical processes</li>
              </ul>
            </div>
          </Fade>

          <Fade left>
            <div className="educationstrategy3-new">
              <h6>Scaling up best practices through partnerships</h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>
                  Technical support to government at state and district level{" "}
                </li>
                <li>Cross-learning workshops and events </li>
                <li>Policy level deliberations and technical inputs</li>
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="educationstrategy4-new">
              <h6>Community engagement for improving quality of education </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>
                  Orientation of School Management Committees (SMCs) and
                  regularizing meetings{" "}
                </li>
                <li>
                  Enabling meaningful parent teacher interaction through school
                  level events
                </li>
                <li>
                  Increasing parental engagement in education of children at
                  home
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
      <div className="education-journeySoFar">
        <p>
          <b>Journey so far:</b> &bull; 1434 Anganwadis & 3805 schools reached
          through trainings and resource support. &bull; 7,000+ AW Workers,
          teachers, and PEEOs trained on ECE, FLN, Remediation and Library
          &bull; 100 model school libraries, 34 district-level model libraries,
          and 350 model Anganwadis developed &bull; 0.45M children & students
          benefitted through improved classroom practices{" "}
        </p>
      </div>

      <div className="photoGallery">
        {educationImage.length > 0 && (
          <Slider {...settings} className="  educationSlider">
            {educationImage.map((item, i) => {
              return (
               
                  <div className="educationSlider-image-container">
                    <div className="educationSlider-image-container-internalDiv">
                      <img src={item.imageurl} />
                      {localStorage.getItem("CmFtoken") != null &&
                      <button onClick={()=>deletePic(item.id,item.fileRef)} >DELETE</button>}
                    </div>
                  
                  </div>
             
              );
            })}
          </Slider>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Education;
