import React from 'react';
import { SiGooglemessages } from "react-icons/si";
import { TbBrandGmail } from "react-icons/tb";
import { AiOutlinePhone } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { HiOfficeBuilding } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import "../styles/footer.css";


let iconStyle = {
  paddingRight: "10px",
  color: "#ffffff",
  fontSize: "30px",
  fontWeight: 900,
};

const Footer = ({ extraStyle }) => {

  const year=new Date().getFullYear()


  const data = [
    {
      icon: faFacebook,
      name: 'facebook',
      link: 'https://www.facebook.com/cmfraj'
    },
    {
      icon: faLinkedinIn,
      name: 'linkedin',
      link: 'https://www.linkedin.com/company/cmfraj/'
    },
    {
      icon: faInstagram,
      name: 'instagram',
      link: 'https://www.instagram.com/_cmfraj/'
    },
    // {
    //   icon: faYoutube,
    //   name: 'youtube',
    //   link: 'https://www.youtube.com/channel/UC4Uw9mJgYrssRq6vC7fO3fA'
    // },
    {
      icon: faTwitter,
      name: 'twitter',
      link: 'https://twitter.com/cmfraj'
    }
  ]

  let footerConnect=(<div className='footerConnect'>
<h4>CONNECT WITH US</h4>
    <div className='socialHandles'>
            {data.map((item, ind) => {
              const { icon, link, name } = item
              return (
                <div className='socialHandles-single-icon'>
                <span key={item + ind} className="mr-3 ">
                  <a
                   target="_blank"
                    href={link}
                   
                    rel="noopener noreferrer"
                    style={{ paddingLeft: '0' }} className="icon">
                    <FontAwesomeIcon icon={icon} className={name} />
                  </a>
                </span>
                </div>
              )
            })}

     </div>
  </div>)

//   let footerDisclaimer=(<div className='footerDisclaimer'>
// <h5>Disclaimer</h5>
// <p>No third party agency is working on behalf of CmF for any recruitment, trainings, camps or other activities related to CmF. Please check/consult with CmF directly to get correct information or assistance. Please beware of any unwarranted claims made by anyone on our behalf. Kindly bring any such claims to our notice for immediate attention and action.</p>
//   <p>CmF doesn't charge any money from anyone for these activities. So if you come across any individual/ organization making a monetary demand in our name please bring it to our notice immediately on mail@CmF.org</p>
//   </div>)
let footerAddress=(<div className='footerAddress'>
 
 
            <span>
              <TbBrandGmail style={iconStyle} />
            
            </span>

            <span> CMF@CMFRAJ.ORG </span>
            <br />

            <span>
              <AiOutlinePhone style={iconStyle} />
              
            </span>
            <span> +91-141-4025909 </span>
            <br />
            {/* <span>
              <HiOfficeBuilding style={iconStyle} />
              HEADQUARTERS :
            </span>
            <span>Jaipur, Rajasthan</span>
            <br /> */}
            <span>
              <ImLocation2 style={iconStyle} /> 
        
            </span>
            <span>
           
            N-102 (South), Adinath Nagar, J.L.N. Marg, Jaipur, 302018, Rajasthan

            </span>
         
</div>)

  let footerNavigate=(<div className='footerNavigate'> 
   <h6>Navigate </h6> |
  <Link to='/' ><span>home</span> |</Link>
  <Link to='/aboutUs' >  <span>about us</span>|</Link>
  <Link to='/contactUs' > <span>contact us</span>|</Link>
  <Link to='/adminLogin' ><span>admin login</span>|</Link>
  <Link to='/programs' ><span>programs</span>|</Link>
  <Link to='/impactStories' >  <span>impact stories</span>| </Link>
  <Link to='/reports' ><span>report</span>|</Link>
  <Link to='/Prof-Vyas' ><span>Prof-Vyas</span> |</Link>
  <Link to='/awardNrecognition' ><span>Award n Recognition</span> |</Link>
  <Link to='/reflections' ><span>reflections</span> |</Link>
  <Link to='/research' ><span>research</span> |</Link>
  <Link to='/videos' ><span>videos</span> |</Link>
  <Link to='/coverage' ><span>coverage</span> |</Link>
  <Link to='/newsNevents' ><span>events</span> |</Link>
  <Link to='/colloquium' ><span>colloquium</span> |</Link>
  </div>)

  let footerCopyRight=(<div className='footerCopyRight'>
   <p>Copyright 	&copy; {year} CmF. All Rights Reserved</p>
  </div>)
  return (
    <div className="footer" >
    {/* {footerDisclaimer} */}
   
    <div className='footer-addressNconnect'>
    {footerAddress}
    {footerConnect}
    </div>
    {footerNavigate}
    {footerCopyRight}
    </div>
  )
}

export default Footer
