import React from "react";
import "../styles/photoSlider.css";
import Carousel from 'react-bootstrap/Carousel';

export default function PhotoSlider({ imgBase, number, sizeStyle, imgStyle }) {
  let arr = [];
  for (var i = 0; i < number; i++) {
    arr[i] = i + 1;
  }
  return (
    <Carousel  className="corousel-made" indicators={false} controls={false}>


{arr.map((item, i) => {
          return (
            <Carousel.Item interval={2300} className="carousel-item-made" >
            <img
                src={`../../images/${imgBase}${item}.jpg`}
                className="d-block w-100 "
                alt="..."
              />
      </Carousel.Item>
          );
        })}
    </Carousel>
  );
}