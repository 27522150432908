// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getStorage} from 'firebase/storage';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqcbmH6R7xyFTrpQO3p1bxc8SJEmNSBeE",
  authDomain: "cmfwebsite-42d13.firebaseapp.com",
  projectId: "cmfwebsite-42d13",
  storageBucket: "cmfwebsite-42d13.appspot.com",
  messagingSenderId: "291349019593",
  appId: "1:291349019593:web:6c8c53739577750dc0c618"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const storage= getStorage(app);