import React, { useEffect, useState } from "react";
import "../../styles/award.css";
import { Modal } from "react-bootstrap";
import {
  PostAwardData,
  GetAwardData,
  DeleteAwardData,
  UploadAwardImage,
} from "../../apis";
import PhotoSlider from "../../components/PhotoSlider";
import {UploadFile,DeleteFile} from '../../UploadFile';
import { ImTerminal } from "react-icons/im";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import Overlay from "../../components/Overlay";
import { useNavigate } from "react-router-dom";

export default function Award() {
  const navigate=useNavigate();
  const [isLoading,setIsLoading]=useState(false);
  const [awardDBdata, setAwardDBdata] = useState([]);
  const [addAwardmodalShow, setAddAwardmodalShow] = useState(false);

  const fetchAwardData = async () => {
    const GetAwardDataRes = await GetAwardData();

    let temp=GetAwardDataRes.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    

    setAwardDBdata(() => temp);
    console.log(GetAwardDataRes.data);
  };
  const deleteAwardDatafunc = async (id) => {
    const data = {
      id: id,
    };
    const deleteAwardDataRes = await DeleteAwardData(data);
    fetchAwardData();
  };
  const handleAddAwardSubmit = async (e) => {
 

    e.preventDefault();
    const title = e.target[0].value;
    const tagline = e.target[1].value;
    const detail= e.target[2].value;

    const linkToVideo = e.target[3].value;
    const date = e.target[4].value;

 
    setAddAwardmodalShow(() => false);
    const file = e.target[5].files[0];
   
    const UploadFileRes=await UploadFile(file,'award');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;

    const time =new Date().getTime();
    const data = {
      title: title,
      tagline: tagline,
      detail: detail,
      linkToVideo: linkToVideo,
      date: date,
      imageurl:imageurl,
      time:time,
      fileRef:fileRef
    };
    const uploadAwardDataRes = await PostAwardData(data);
    fetchAwardData();
  };
  useEffect(() => {
    fetchAwardData();
  }, []);
  let addAwardModal = (
    <Modal
      className="modal-addAward"
      show={addAwardmodalShow}
      onHide={() => setAddAwardmodalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addAward"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addAward">
        <form onSubmit={(e) => handleAddAwardSubmit(e)}>
          <input className="input" type="text" placeholder="Title" />
          <input className="input" type="text" placeholder="Tagline" /> 
          <textarea className="input" type="text" placeholder="Detail" />

          <input className="input" type="text" placeholder="LINK To Video" />
          <input className="input" type="text" placeholder="DATE" />
          <input className="input" type="file" placeholder="IMAGE" />
          <label>Add Image</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let awardContent = (
    <div className="awardContent">
      {/* {
            awardDBdata.length>0&&awardDBdata.map((item,i)=>{
                return <button onClick={()=>deleteAwardDatafunc(item.id)} >delete</button>
            }
               
            )
           } */}

      {awardDBdata.length > 0 &&
        awardDBdata.map((item, i) => {
          return (
            <div className="awardContent-eventContainer-singleEvent">
              <img src={item.imageurl} />
              <h4>{item.title}</h4>
              <p>{item.keyPoint}</p>
              {item.date && <p>{item.date}</p>}
              <div className="awardContent-eventContainer-singleEvent-footer">
                {item.linkToVideo!=="" &&<a target="_blank" href={item.linkToVideo}>Link</a>}
                {localStorage.getItem("CmFtoken") != null &&
                <button onClick={() => deleteAwardDatafunc(item.id)}>
                  DELETE
                </button>}

                <button
                  onClick={() => {
                    navigate("/more", {
                      state: {
                        title:item.title,
                        tagline:"",
                        imageurl:item.imageurl,
                        date:"",
                        theme:"",
                        description:item.keyPoint,
                        pdflink:item.link,
                        videoLink:"",
                      },
                    });
                  }}
                
                >READ MORE</button>
              </div>
            </div>
          );
        })}
    </div>
  );

  let awardHeader = (
    <div className="awardHeader">
    <Navbar />
    <Overlay overLayColor={"#00000055"} z_index={"10"} />
    <div className="Cover-AwardPage">
   
      <PhotoSlider imgBase={"../images/insights/Pic-"} number={10} />
    </div>
    <div className="awardHeader-content">
        <h1>AWARDS AND RECOGNITIONS</h1>
    </div>
  </div>
  );
 
  return (
    <div className="award">
      {awardHeader}
      <div className="addAwardButton">
      {localStorage.getItem("CmFtoken") != null &&
        <button onClick={() => setAddAwardmodalShow(() => true)}>
          ADD AWARD
        </button>
      }
      </div>
      {addAwardModal}
      {awardContent}
      {/* <iframe src="https://award.google.com/pubs/archive/44678.pdf"
   width="800" height="600"/> */}
      <Footer />
    </div>
  );
}
