import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import '../../styles/homePageEvents.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../Card';
import {GetHomeEventData} from "../../apis";
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';

export default function Events() {
  const navigate=useNavigate();
   const [isLoading,setIsLoading]=useState(false);
  const [homeEventDBdata, setHomeEventDBdata] = useState([]);
   const [slidesToShow,setSlidesToShow]=useState(1);
  const  [width,setWidth]=useState(window.innerWidth);
  const  [cardWidth,setCradWidth]=useState(400);



  const fetchHomeEventData = async () => {
    const fetchHomeEventDataRes = await GetHomeEventData();
    let temp=fetchHomeEventDataRes.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setHomeEventDBdata(() => temp);
    console.log("fetchHomeEventDataRes.data",fetchHomeEventDataRes.data);
  };

   useEffect(()=>{
    fetchHomeEventData();
   if(window.innerWidth<=800)
   {
    setSlidesToShow(()=>1);
    let temp_cardWidth=Math.ceil(0.8*(window.innerWidth));
    setCradWidth(()=>temp_cardWidth)
   }
   
   else if(window.innerWidth<=1100)
   {
    setSlidesToShow(()=>2);
    let temp_cardWidth=Math.ceil(0.4*(window.innerWidth));
    setCradWidth(()=>temp_cardWidth)
   }
   else
   {
    setSlidesToShow(()=>3);
    let temp_cardWidth=Math.ceil(0.3*(window.innerWidth));
    setCradWidth(()=>temp_cardWidth)
   }
   


  //  console.log("slidesToShow = ",slidesToShow);
   },[]);
     let x=0;

    //  const sampleEventCard=[];
    //  for(var i=0;i<=15;i++)
    //  {
    //   sampleEventCard.push()
    //  }
     
//      (<div className='slideCard'>
// <Card name={"Independence Day"} desc={"Independence Day is celebrated annually on 15 August as a national holiday in India"} img={"img"} extraStyle={{width:"400px"}}/>
// </div>)

    var settings = {
        // accessibility:true,
        adaptiveHeight:true,
        dots: false,
        infinite: true,
        speed: 1900,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay:true,
        arrows:false,
        autoplaySpeed:5000,
        // centerMode:true,
        focusOnSelect:true
      };
      const reportWindowSize=()=>{
        // const { innerWidth: width, innerHeight: height } = window;
        // console.log(width);
        const curr_width=window.innerWidth;
        setWidth(()=>curr_width)
        // x=x+1;
      }
      // window.addEventListener('resize', reportWindowSize);
  return (
    <div className='Events-HomePage'>
    {/* <h1>Events</h1> */}
    <div className='Events-HomePage-heading'>
      <h3>EVENTS AND STORIES</h3>
    </div>
   {
    homeEventDBdata.length>0&& <Slider {...settings} className='slider'>
     {
     homeEventDBdata.map((item,i)=>{
    

      return (<div className='slideCard'>
<Card name={item.title} desc={item.keyPoint} img={item.imageurl} extraStyle={ {width:cardWidth}}/>
</div>)
      })
     }
     
    </Slider>
   }
   
    <button onClick={()=>navigate('/newsNevents')}>SEE More-&gt;</button>
    </div>
  )
}

