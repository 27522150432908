import React, { useEffect, useState } from 'react'
import '../../styles/opinion.css';
import {Modal} from 'react-bootstrap';
import {PostOpinionData,GetOpinionData,DeleteOpinionData,UploadOpinionImage} from "../../apis";
import { ImTerminal } from 'react-icons/im';
import Footer from '../../components/Footer';
import PhotoSlider from '../../components/PhotoSlider';
import Navbar from '../../components/NavBar';
import Overlay from '../../components/Overlay';
import {UploadFile,DeleteFile} from '../../UploadFile';
import { useNavigate } from 'react-router-dom';
export default function Opinion() {
  const navigate=useNavigate();
  const [isLoading,setIsLoading]=useState(false);
    const [opinionDBdata,setOpinionDBdata]=useState([]);
    const [addOpinionmodalShow,setAddOpinionmodalShow] = useState(false);

    const fetchOpinionData= async()=>{
         const GetOpinionDataRes=  await GetOpinionData();
         let temp= GetOpinionDataRes.data;
         temp=temp.sort((item1,item2)=>item1.time-item2.time);
         setOpinionDBdata(()=>temp);
         console.log(GetOpinionDataRes.data);
    }
    const deleteOpinionDatafunc=async(id,fileRef)=>{
        const data={
            id:id
        }
        const deleteOpinionDataRes=  await DeleteOpinionData(data);
        fetchOpinionData();
    }
   const handleAddOpinionSubmit=async(e)=>{
    e.preventDefault();
    const title=e.target[0].value;
    const details=e.target[1].value;
    const link=e.target[2].value;
    const date=e.target[3].value;
    const tagline=e.target[4].value;

    setAddOpinionmodalShow(()=>false);
    const file = e.target[5].files[0];
   
    const UploadFileRes=await UploadFile(file,'opinion');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;

     const time = new Date().getTime();

     const data={
        title:title,
        time:time,
       details:details,
       link:link,
        imageurl:imageurl,
        tagline:tagline,
        fileRef:fileRef
     }
     const  uploadOpinionDataRes= await PostOpinionData(data);
    fetchOpinionData();
   }
   useEffect(()=>{
    fetchOpinionData();
   },[])
    let addOpinionModal=( <Modal
        className="modal-addOpinion"
        show={addOpinionmodalShow}
        onHide={() => setAddOpinionmodalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter-addOpinion"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
          </Modal.Header>
        <Modal.Body className="modal-body-addOpinion">
    
        <form onSubmit={(e)=>handleAddOpinionSubmit(e)}>
        <input  className="input" type="text" placeholder="Title" />
        <input  className="input" type="text" placeholder="Detail" />
        <input  className="input" type="text" placeholder="LINK(if any)" />
        <input  className="input" type="text" placeholder="DATE" />
        <input  className="input" type="text" placeholder="Tagline" />
        <input  className="input" type="file" placeholder="IMAGE" />
        <label >Add Image</label>
        <button type="submit" >SUBMIT</button>
        </form>
        </Modal.Body>
        
    
        </Modal>)

        let opinionContent=(<div className='opinionContent'>
           {/* {
            opinionDBdata.length>0&&opinionDBdata.map((item,i)=>{
                return <button onClick={()=>deleteOpinionDatafunc(item.id)} >delete</button>
            }
               
            )
           } */}
        
              {opinionDBdata.length>0&&opinionDBdata.map((item,i)=>{
                return <div className='opinionContent-eventContainer-singleEvent'>
               
               <img src={item.imageurl} />
               <h4>{item.title}</h4>
               <h6>{item.tagline}</h6>
               <p>{item.details}</p>
                 { item.date&& <p>{item.date}</p>}  
              <div className='opinionContent-eventContainer-singleEvent-footer' >
              { item.link!==""&&<a target="_blank" href={item.link}>Link</a>}
              {localStorage.getItem("CmFtoken") != null &&<button onClick={()=>deleteOpinionDatafunc(item.id,item.fileRef)} >DELETE</button>}
               <button
                onClick={() => {
                    navigate("/more", {
                      state: {
                        title:item.title,
                        tagline:item.tagline,
                        imageurl:item.imageurl,
                        date:item.date,
                        theme:"",
                        description:item.details,
                        pdflink:item.link,
                        videoLink:"",
                      },
                    });
                  }}
               
               >READ MORE</button>
               </div>
                </div>

              })
              }
         
        </div>)

        let opinionHeader=(  <div className="opinionHeader">
        <Navbar />
        <Overlay overLayColor={"#00000055"} z_index={"10"} />
        <div className="Cover-OpinionPage">
       
          <PhotoSlider imgBase={"../images/insights/Pic-"} number={10} />
        </div>
        <div className="opinionHeader-content">
            <h1>REFLECTIONS</h1>
        </div>
      </div>)
  return (
    <div className='opinion' >
    {opinionHeader}
    <div className='addOpinionButton'>
    {localStorage.getItem("CmFtoken") != null &&
     <button onClick={()=>setAddOpinionmodalShow(()=>true)}  >ADD REFLECTION</button>}
     </div>
     {addOpinionModal}
     {opinionContent}
<Footer/>
    </div>
  )
}
