import React, { useState } from 'react'
import Navbar from '../../components/NavBar';
import Overlay from '../../components/Overlay';
import "../../styles/career.css";
import {UploadFile,DeleteFile} from '../../UploadFile';

export default function Career() {
  const [openApplicationmodalshow,setOpenApplicationmodalshow]=useState(false);

    let careerHeader =(<div className='careerHeader'>
    <Navbar/>

<img src='./images/Career/Career_Cover.jpg' className='careerHeader-img' />
<Overlay overLayColor={"#333333aa"} z_index={"0"} />
<div className='careerHeader-heading'>
<h2>CAREER AT CMF</h2>
</div>
    </div>)

    let  openApplication=(<div className='openApplication'>
    <h3>OPEN APPLICATION</h3>
    <button onClick={()=>setOpenApplicationmodalshow((prev)=>!prev)} >APPLY</button>
    </div>)
 let openApplicationModal=(<div className='openApplicationModal'>
   <form>
   <button  onClick={()=>setOpenApplicationmodalshow((prev)=>!prev)}>Close</button>
   <h4>Open Application</h4>
   <input id="name"  type="text" placeholder="Name" />
   <input id="contact"  type="text" placeholder="Contact" />
   <input id="email"  type="text" placeholder="Email" />
   <input id="dob"  type="text" placeholder="Date of Birth" />
   <input id="qualification"  type="text" placeholder="Qualification" />
   <input id="employment"  type="text" placeholder="Curent/Last Employment(Position and Organization" />
   <input id="totalEXP"  type="text" placeholder="Total Experience" />
   <input id="cover letter"  type="file" placeholder="Cover letter" />
   <input id="resume"  type="file" placeholder="Resume/CV" />
<button>Submit</button>
   </form>
 </div>)

    let  vacancy=(<div className='vacancy'>
<h3>VANACIES</h3>
{}
    </div>)
  return (
    <div className='career'>
    {careerHeader}
    {
      openApplication
    }
    {openApplicationmodalshow&&openApplicationModal}
    {
      vacancy
    }
    </div>
  )
}