import React, { useEffect, useState } from "react";
import "../styles/socialMedia.css";
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import { FacebookProvider, Page } from 'react-facebook-w';
export default function SocialMedia() {
const [width,setWidth] =useState(400);
const [src,setSrc]=useState("https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcmfraj&tabs=timeline&width=250&height=550&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId")
  
useEffect(()=>{
  setWidth(()=>window.innerWidth)
if(window.innerWidth>=500)
setSrc(()=>"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcmfraj&tabs=timeline&width=450&height=550&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId")
else
{
  let new_width=Math.ceil(width*0.778);
  setSrc(()=>`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcmfraj&tabs=timeline&width=${new_width}&height=550&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId`)
}

},[])

let facebook = (
    <div className="socialMedia-facebook">
    <div className="socialMedia-facebook-heading" >
      <h3>FACEBOOK</h3>
    </div>
      <div className="socialMedia-facebook-container">
      <iframe
        src={src}
        // width="100%"
        height="600"
        style={{ border:"none",overflow:"hidden",width:"100%" }}
        // scrolling="no"
        frameBorder="0"
        allowFullScreen="true"
        // allow={autoplay, clipboard-write, encrypted-media, pictureInpicture, web-share}
      ></iframe>
      {/* <FacebookProvider appId="123456789">
        <Page href="https://www.facebook.com" tabs="timeline" />
      </FacebookProvider>  */}
      {/* <div className="fb-page"
    data-href="https://www.facebook.com/facebook/"
    data-tabs="timeline"
    data-width="500"
    data-height="400"
    data-small-header="true"
    data-adapt-container-width="true"
    data-hide-cover="false"
    data-show-facepile="false">
   
</div> */}
</div>
    </div>
  );

  let twitter=(<div className="socialMedia-twitter">
  <div className="socialMedia-twitter-heading" >
      <h3>TWITTER</h3>
    </div>
  <div className="socialMedia-twitter-container">

  
{/* <TwitterTimelineEmbed
  sourceType="profile"
  screenName="cmfraj"
  options={{height: 550}}

  noFooter
  noHeader 

 className="TwitterTimelineEmbed"
 /> */}


<TwitterTimelineEmbed
sourceType="profile"
screenName="cmfraj"
options={{
  height: 550,
  theme: 'dark', // 'light' or 'dark' theme
  borderColor: '#AAB8C2', // Set the border color
  // tweetLimit: 5, // Number of tweets to display
}}
noFooter
noHeader 
/>


</div>
  </div>)

  return <><div className="socialMedia">
  {/* <div className="socialMedia-checkout">
   <h4>CHECK OUT OUR SOCIAL MEDIA !!!</h4>
  </div> */}
  <div className="socialMedia-content" >
  {facebook}
  {twitter}
  </div>
  
  </div>
 </>
  ;
}
