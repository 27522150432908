import React, { useEffect, useState, useLayoutEffect } from "react";
import Information from "../../../info";
import Overlay from "../../../components/Overlay";
import { Modal } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import "../../../styles/aboutUs.css";
import Dots from "../../../components/Dots";
// import Img from "../../../images/1626677755681.jpg";
// import chairMan from './images/cmf_chairman.jpg';
import Navbar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
// import IMG from "./images/CmFLogo.png";
import Timeline from "../../../components/Timeline";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/Loader";
import OurTeam from '../../common/about/OurTeam';
import {UploadFile,DeleteFile} from '../../../UploadFile';
import PhotoSlider from "../../../components/PhotoSlider";
import {
  PostBoardMemberData,
  GetBoardMemberData,
  DeleteBoardMemberData,
  PostChairmanData,
  GetChairmanData,
  DeleteChairmanData,
  UploadChairmanImage,
} from "../../../apis";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function AboutUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [chairman, setChairman] = useState({});
  const [boardMember, setBoardMember] = useState([]);
  const [showAddChairmanModal, setShowAddChairmanModal] = useState(false);
  const [showAddBoardMemberModal, setShowAddBoardMemberModal] = useState(false);
  const [width, height] = useWindowSize();
  let h = 0;
  const { data } = Information();
  const getContentHeight = () => {
    // h = document.getElementsByClassName("contentAboutUs").length;
    // console.log(h);
    // return h;
  };
  document.addEventListener("scroll", getContentHeight);

  const handleAddChairmanSubmit = async (e) => {
    e.preventDefault();
    const name = e.target[0].value;
    const detail = e.target[1].value;
    setShowAddChairmanModal(() => false);
    setIsLoading(() => true);
  
    const file =e.target[2].files[0];
   
    const UploadFileRes=await UploadFile(file,'about');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
    const chairmandata = {
      name: name,
      detail: detail,
      imageurl: imageurl,
      fileRef:fileRef,
   
    };
    const chairmanresponse = await PostChairmanData(chairmandata);
    setIsLoading(() => false);

    fetchChairmanData();
  };
  const handleAddBoardMemberModalSubmit = async (e) => {
    e.preventDefault();
    const position = e.target[0].value;
    const name = e.target[1].value;
    const detail = e.target[2].value;

    setShowAddBoardMemberModal(() => false);
    setIsLoading(() => true);
    const file = e.target[3].files[0]
   
    const UploadFileRes=await UploadFile(file,'about');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
 
    const time = new Date().getTime();
    const BoardMemberdata = {
      name: name,
      detail: detail,
      position: position,
      imageurl: imageurl,
      time: time,
    };
    const BoardMemberresponse = await PostBoardMemberData(BoardMemberdata);
    setIsLoading(() => false);
    toast.success("UPLOADED");
    fetchChairmanData();
  };
  const fetchChairmanData = async () => {
    const chairmanresponse = await GetChairmanData();
    let temp=chairmanresponse.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setChairman(() => temp);
    console.log(chairmanresponse.data);
  };
  const fetchBoardMemberData = async () => {
    const boardMemberresponse = await GetBoardMemberData();
    let temp=boardMemberresponse.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setBoardMember(() => temp);
    console.log(boardMemberresponse.data);
  };
  const handledeleteChairmanData = async (id) => {
    setIsLoading(() => true);
    const data = {
      id: id,
    };
    await DeleteChairmanData(data);
    fetchChairmanData();
    setIsLoading(() => false);
    toast.success("DELETED");
  };
  const handledeleteBoardMemberData = async (id) => {
    setIsLoading(() => true);

    const data = {
      id: id,
    };
    console.log("id", id);
    await DeleteBoardMemberData(data);
    fetchBoardMemberData();
    setIsLoading(() => false);
    toast.success("DELETED");
  };

  useEffect(() => {
    fetchChairmanData();
    fetchBoardMemberData();
  }, []);
  useEffect(() => {}, [width]);

  let aboutUsHeader = (
    <div className="aboutHeader">
    <Navbar />
    <Overlay overLayColor={"#00000055"} z_index={"10"} />
    <div className="Cover-AboutPage">
   
      <PhotoSlider imgBase={"../images/AboutUs/Pic-"} number={10} />
    </div>
    <div className="aboutHeader-content">
        <h1>ABOUT US</h1>
    </div>
  </div>
  );

  let aboutUsHeading = (
    <div className="aboutUsHeading">
      <img src="./images/CmFLogo.png" className="logoMain" />
      <h3>CENTRE FOR MICROFINANCE (CmF)</h3>
      <p>
        The Centre for microFinance (CmF), seeded in 2007, is a{" "}
        <b>state level resource organization</b> to catalyse the growth of the
        community led microfinance (women’s Self-Help Group model) sector in
        Rajasthan. Over the last 15 years, it has been CmF’s constant endeavour
        to adapt and expand its role and functions in keeping with the evolving
        needs and aspirations of the communities it works with and the macro-
        environment comprising government policies and programs.
      </p>
    </div>
  );

  let aboutUsApproach = (
    <div className="aboutUsApproach">
      <h3>OUR APPROACH</h3>
      <p>
        Ensuring sustainability of impact, knowledge and community led processes
        remains the core of all programmes of CmF. We endeavour to build and
        share knowledge and promote innovations in all themes we work in.
        Building partnerships with stakeholders- community institutions,
        non-profits, banks, government and others to mainstream the learnings
        and best practices of our work in larger programmes for desired impact
        is a cross-cutting role of CmF across all the themes.
      </p>
    </div>
  );

  let aboutUsBoardMembers = (
    <div className="aboutUsBoardMembers">
      <div className="aboutUsBoardMembers-heading">
        <h3>OUR GOVERNING BOARD</h3>
      </div>
      <div className="aboutUsBoardMembers-registeredUnder">
        <p>
          CmF is registered as a not-for-profit society under the Rajasthan
          Societies Act 1958 No. 766 dated 13.02.2007. Its governing board is
          drawn from experts in different spheres – academics, development
          practice, government, and philanthropy.
        </p>
      </div>

      <div className="aboutUsBoardMembers-content">
      {localStorage.getItem("CmFtoken") != null &&
        <button
          className="addChairmanbutton"
          onClick={() => setShowAddChairmanModal(() => true)}
        >
          ADD CHAIRMAN
        </button>}
        {chairman.length > 0 &&
          chairman.map((item, i) => {
            return (
              <div className="aboutUsBoardMembers-content-chairman">
                <div className="aboutUsBoardMembers-content-chairman-img">
                  <img src={item.imageurl} alt="picture" />
                </div>
                <div className="aboutUsBoardMembers-content-chairman-content">
                  <h2>CHAIRMAN</h2>
                  <h3>{item.name}</h3>
                  <p>{item.detail}</p>
                  {localStorage.getItem("CmFtoken") != null &&<button onClick={() => handledeleteChairmanData(item.id)}>
                    Delete
                  </button>}
                </div>
              </div>
            );
          })}
          {localStorage.getItem("CmFtoken") != null &&
        <button onClick={() => setShowAddBoardMemberModal(() => true)}>
          ADD MEMBER
        </button>}
        <div className="aboutUsBoardMembers-content-others">
          {boardMember.length > 0 &&
            boardMember.map((item, i) => {
              return (
                <div className="aboutUsBoardMembers-content-others-single">
                  <h3>{item.position}</h3>
                  <h4>{item.name}</h4>
                  <div className="aboutUsBoardMembers-content-others-single-img-container">
                    <img src={item.imageurl} />
                  </div>
                  <p>{item.detail}</p>
                  {localStorage.getItem("CmFtoken") != null &&<button onClick={() => handledeleteBoardMemberData(item.id)}>
                    Delete
                  </button>}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
  let addChairManModal = (
    <Modal
      className="modal-addChairman"
      show={showAddChairmanModal}
      onHide={() => setShowAddChairmanModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addChairman"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addChairman">
        <form onSubmit={(e) => handleAddChairmanSubmit(e)}>
          <input className="input" type="text" placeholder="Name" />

          <textarea
            name="headline"
            type="text"
            placeholder="Write Something..."
          />

          <input className="input" type="file" placeholder="Add Image" />
          <label>Image</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let addBoardMemberModal = (
    <Modal
      className="modal-addBoardMemberModal"
      show={showAddBoardMemberModal}
      onHide={() => setShowAddBoardMemberModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addBoardMemberModal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addBoardMemberModal">
        <form onSubmit={(e) => handleAddBoardMemberModalSubmit(e)}>
          <input className="input" type="text" placeholder="Position" />
          <input className="input" type="text" placeholder="Name" />

          <textarea
            name="headline"
            type="text"
            placeholder="Write Something..."
          />
          <input className="input" type="file" placeholder="Add Image" />
          <label>Image</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  return (
    <div className="aboutUs">
      {aboutUsHeader}
      {aboutUsHeading}
      {/* {aboutUsApproach} */}
      {aboutUsBoardMembers}
      {addChairManModal}
      {addBoardMemberModal}
      <OurTeam/>
      <ToastContainer autoClose={1000} />
      {isLoading && <Loader />}
      <Footer />
      {/* <Navbar />
      <h1>ABOUT US</h1>
      <img src='./images/1626677755681.jpg' className="aboutUsPic" />

      <div className="contentAboutUs">
        <img src='./images/CmFLogo.png' className="logoMain" />
        <div className="cmf">
          <h3>CENTRE FOR MICROFINANCE (CmF)</h3>
          <span className="cmfText">
            The Centre for microFinance (CmF) was seeded in 2007 as a state
            level resource organization to catalyse the growth of the community
            led microfinance (women’s Self-Help Group model) sector in
            Rajasthan. Over the last 14 years, it has been CmF’s constant
            endeavour to adapt and expand its role and functions in keeping with
            the evolving needs and aspirations of the communities it works with
            and the macro- environment comprising government policies and
            programs.
          </span>
        </div>
        <Dots />
        <div className="ourApproach">
          <h3>OUR APPROACH</h3>
          <span>
            Ensuring sustainability of impact, knowledge and community led
            processes remains the core of all programmes of CmF. We endeavour to
            build and share knowledge and promote innovations in all themes we
            work in. Building partnerships with stakeholders- community
            institutions, non-profits, banks, government and others to
            mainstream the learnings and best practices of our work in larger
            programmes for desired impact is a cross-cutting role of CmF across
            all the themes.
          </span>
        </div>
        <Dots />
        <h2 style={{ display:"block",textAlign: 'center',marginTop:30,fontSize:40 }}>OUR JOURNEY</h2>
        <Timeline/>
        <Dots />
        <div className="boardMembers">
          <h3>GOVERNING BOARD</h3>
          <span>
            CmF is registered as a not-for-profit society under the Rajasthan
            Societies Act 1958. It was registered in 2007. Its governing board
            is drawn from experts in different spheres – academics, development
            practice, government and philanthropy.
          </span>
        </div>

        {data.map((item, i) => {
          if (i % 2 == 0 && width > 950) {
            return (
              <>
                {i !== 0 && <Dots />}
                <div className="each-member">
                  {console.log(i)}

                  <span className="descrip">{item.description}</span>
                  <div className="boardMemPic">
                    <img src='./images/cmf_chairman.jpg' alt="picture" />
                    <span>{item.name}</span>
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                <Dots />
                <div className="each-member">
                  <div className="boardMemPic">
                    <img src='./images/cmf_chairman.jpg' alt="picture" />
                    <span>{item.name}</span>
                  </div>
                  <span className="descrip">{item.description}</span>
                </div>
              </>
            );
          }
        })}
      </div>

      <Footer /> */}
    </div>
  );
}
