import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../styles/initiative.css";
import Navbar from "../../components/NavBar";
import Overlay from "../../components/Overlay";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import { UploadFile, DeleteFile } from "../../UploadFile";

import {
  PostInitiativeData,
  GetInitiativeData,
  DeleteInitiativeData,
  UploadInitiativeFile,
  PostReportData,
  GetReportData,
  DeleteReportData,
  UploadReportFile,
} from "../../apis";

export default function Initiative() {
  const [isLoading, setIsLoading] = useState(false);

  const [initiativeDBdata, setInitiativeDBdata] = useState([]);
  const [addInitiativemodalShow, setAddInitiativemodalShow] = useState(false);

  const [reportDBdata, setReportDBdata] = useState([]);
  const [addReportmodalShow, setAddReportmodalShow] = useState(false);
  const initiativeData = [];
  for (var i = 0; i < 12; i++) {
    initiativeData.push({
      heading: "Initiativeor Report With Balance Sheet 2020-21  ",
      link: "www.google.com",
    });
  }
  const reportData = [];
  for (var i = 0; i < 12; i++) {
    reportData.push({
      heading: "Initiativeor Report With Balance Sheet 2020-21  ",
      link: "www.google.com",
    });
  }

  const fetchReportData = async () => {
    const GetReportDataRes = await GetReportData();
    let temp = GetReportDataRes.data;
    temp = temp.sort((item1, item2) => item1.time - item2.time);
    setReportDBdata(() => temp);
    console.log(GetReportDataRes.data);
  };
  const deleteReportDatafunc = async (id, fileRef) => {
    const data = {
      id: id,
    };
    await DeleteFile(fileRef);

    const deleteReportDataRes = await DeleteReportData(data);
    fetchReportData();
  };
  const handleAddReportSubmit = async (e) => {
    e.preventDefault();
    const heading = e.target[0].value;

    setAddReportmodalShow(() => false);
    setIsLoading(() => true);
    const file = e.target[1].files[0];

    const UploadFileRes = await UploadFile(file, "audit");
    const fileurl = UploadFileRes.fileUrl;
    const fileRef = UploadFileRes.fileRef;

    const data = {
      heading: heading,
      fileurl: fileurl,
      fileRef,
    };
    const uploadReportDataRes = await PostReportData(data);
    setIsLoading(() => false);
    fetchReportData();
  };

  const fetchInitiativeData = async () => {
    const GetInitiativeDataRes = await GetInitiativeData();

    let temp = GetInitiativeDataRes.data;
    temp = temp.sort((item1, item2) => item1.time - item2.time);

    setInitiativeDBdata(() => temp);
    console.log(GetInitiativeDataRes.data);
  };
  const deleteInitiativeDatafunc = async (id, fileRef, imageRef) => {
    await DeleteFile(fileRef);
    await DeleteFile(imageRef);
    const data = {
      id: id,
    };

    const deleteInitiativeDataRes = await DeleteInitiativeData(data);
    fetchInitiativeData();
  };
  const handleAddInitiativeSubmit = async (e) => {
    e.preventDefault();
    const heading = e.target[0].value;

    setAddInitiativemodalShow(() => false);
    setIsLoading(() => true);

    const file0 = e.target[1].files[0];

    const UploadImageRes = await UploadFile(file0, "audit");
    const imageurl = UploadImageRes.fileUrl;
    const imageRef = UploadImageRes.fileRef;

    const file = e.target[2].files[0];

    const UploadFileRes = await UploadFile(file, "audit");
    const fileurl = UploadFileRes.fileUrl;
    const fileRef = UploadFileRes.fileRef;

    const data = {
      heading: heading,
      fileurl: fileurl,
      imageurl: imageurl,
      fileRef: fileRef,
      imageRef: imageRef,
    };
    const uploadInitiativeDataRes = await PostInitiativeData(data);
    setIsLoading(() => false);
    fetchInitiativeData();
  };
  useEffect(() => {
    fetchInitiativeData();
    fetchReportData();
  }, []);

  /*---------HTML------------ */
  let initiativeHeader = (
    <div className="initiativeHeader">
      <Navbar />
      {/* <img
        src="../images/Annual_Audit/audit-Cover.jpg"
        alt="initiative-Cover"
        className="initiativeHeader-img"
      /> */}
      {/* <Overlay overLayColor={"#333333aa"} z_index={"0"} /> */}
      <div className="initiativeHeader-heading">
        <h2>REPORTS</h2>
      </div>
    </div>
  );

  let addInitiativeModal = (
    <Modal
      className="modal-addInitiative"
      show={addInitiativemodalShow}
      onHide={() => setAddInitiativemodalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addInitiative"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          ADD INITIATIVE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addInitiative">
        <form onSubmit={(e) => handleAddInitiativeSubmit(e)}>
          <input className="input" type="text" placeholder="HEADING" />

          <input className="input" type="file" placeholder="IMAGE" />
          <label>Add IMAGE</label>
          <input className="input" type="file" placeholder="FILE" />

          <label>Add FILE</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let addReportModal = (
    <Modal
      className="modal-addReport"
      show={addReportmodalShow}
      onHide={() => setAddReportmodalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addReport"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addReport">
        <form onSubmit={(e) => handleAddReportSubmit(e)}>
          <input className="input" type="text" placeholder="HEADING" />
          <input className="input" type="file" placeholder="FILE" />
          <label>Add FILE</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let initiativeContent = (
    <div className="initiativeContent">
      <h4>KEY INITIATIVES</h4>
      <div className="initiativeContent-links">
        <ul>
          {initiativeDBdata.map((singleItem, i) => {
            return (
              <div className="initiativeContent-links-single">
                <a target="_blank" href={singleItem.fileurl}>
                  <h5>{singleItem.heading}</h5>
                </a>
                {console.log(singleItem.imageurl)}
                <a target="_blank" href={singleItem.fileurl}>
                  <img src={singleItem.imageurl} />
                </a>
                {localStorage.getItem("CmFtoken") != null && (
                  <button
                    onClick={() =>
                      deleteInitiativeDatafunc(
                        singleItem.id,
                        singleItem.fileRef,
                        singleItem.imageurl
                      )
                    }
                  >
                    DELETE
                  </button>
                )}
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );

  let reportContent = (
    <div className="reportContent">
      <h4>REPORTS</h4>
      <div className="reportContent-links">
        <ul>
          {reportDBdata.map((singleItem, i) => {
            return (
              <li>
                <div className="reportContent-links-single">
                  <p>
                    <a href={singleItem.fileurl} target="_blank">
                      {singleItem.heading}
                    </a>
                  </p>
                  {localStorage.getItem("CmFtoken") != null && (
                    <button
                      onClick={() =>
                        deleteReportDatafunc(singleItem.id, singleItem.fileurl)
                      }
                    >
                      DELETE
                    </button>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  let disasterRelief = (
    <div className="disasterRelief">
      <div className="disasterRelief-covid">
        <h3>COVID RELIEF</h3>
        <img src="https://firebasestorage.googleapis.com/v0/b/cmfwebsite-42d13.appspot.com/o/disaster_Relief%2FCAVACH%20Photo-1.JPG?alt=media&token=1b60d91f-1ead-468f-8f48-4a31a0e5ee2a" />
        <a href="https://firebasestorage.googleapis.com/v0/b/cmfwebsite-42d13.appspot.com/o/disaster_Relief%2FCovid-19%20Relief.docx?alt=media&token=6edefb3f-d668-4eb1-9b34-33557cc5c310"> LINK</a>
      </div>
      <div className="disasterRelief-flood">
        <h3>FLOOD RELIEF</h3>
        <img src="https://firebasestorage.googleapis.com/v0/b/cmfwebsite-42d13.appspot.com/o/disaster_Relief%2FFlood%20Page%20photo%201.jpg?alt=media&token=5433ef5a-5b95-4ddf-baa0-392f40d44fc2" />
        <a href="https://firebasestorage.googleapis.com/v0/b/cmfwebsite-42d13.appspot.com/o/disaster_Relief%2FFLOOD%20RELIEF.docx?alt=media&token=c3880828-6e2e-47ca-9d67-f990225a1c62">LINK</a>
      </div>
    </div>
  );

  return (
    <div className="initiative">
      {initiativeHeader}
      <div className="initiative-AddButton">
      {localStorage.getItem("CmFtoken") != null &&
        <button onClick={() => setAddInitiativemodalShow(() => true)}>
          ADD KEY INITIATIVE
        </button>}
        {localStorage.getItem("CmFtoken") != null &&
        <button onClick={() => setAddReportmodalShow(() => true)}>
          ADD REPORT
        </button>}
      </div>

      {addInitiativeModal}
      {addReportModal}
      {initiativeDBdata.length > 0 && initiativeContent}
      {reportDBdata.length > 0 && reportContent}
      {/* {disasterRelief} */}
      {isLoading && <Loader />}
      <Footer />
    </div>
  );
}
