import React, { useEffect, useState } from "react";
import Navbar from "../../../components/NavBar";
import Overlay from "../../../components/Overlay";
import "../../../styles/impactStories.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ImpactStoryPagination from "../../../components/Pagination";
import Footer from "../../../components/Footer";
import { Modal } from "react-bootstrap";
import PhotoSlider from "../../../components/PhotoSlider";
import {UploadFile,DeleteFile} from '../../../UploadFile';

import {
  PostStoryData,
  GetStoryData,
  DeleteStoryData,
  UploadStoryImage,
} from "../../../apis";
import Loader from "../../../components/Loader";
// import { Timestamp } from "@google-cloud/firestore";
let impactAllStoriesArray = [];
const lorem = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`;
for (var i = 1; i <= 20; i++) {
  impactAllStoriesArray.push({
    heading: "The Girl that got cured",
    tagline: "Curing is Caring",
    description: lorem,
    imageurl: "../../images/programs/Education/Pic-4.jpg",
    id: i,
  });
}

export default function ImpactStories() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showStoryModal, setShowStoryModal] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [impactStoriesArray, setImpactStoriesArray] = useState([]);
  const [impactStoriesArrayDBdata, setImpactStoriesArrayDBdata] = useState([]);
  const [impactStoriesArrayDB, setImpactStoriesArrayDB] = useState([]);
  //   const [impactAllStoriesArray,setImpactAllStoriesArray] =useState()
  const handlePageChange = (pagenumber) => {
    setPage(() => pagenumber);
  };

  let rgbs = ["#8DF5A988", "#FFFFCC88", "#99CCFF88", "#FFB26688"];

  /*------------------------USEEFFECTs------------------------*/

  const multipleUpload=(e)=>{
console.log(e.target.value);
  }

  const handleStorySubmit = async (e) => {
    e.preventDefault();
    setIsLoading(() => true);
    const heading = e.target[0].value;
    const tagline = e.target[1].value;
    const description = e.target[2].value;
    
    const num_of_images=e.target[3].files.length;
   
    // const url=await uploadStoryImage();
    const fileData = new FormData();
    fileData.append("file", e.target[3].files[0]);
    console.log(fileData);
    setShowStoryModal(() => false);
    let imageurl=[];
    let fileRef=[];
   for(var i=0;i<num_of_images;i++)
   {
    let file = e.target[3].files[i];
    let UploadFileRes=await UploadFile(file,'story');
    let single_imageurl= UploadFileRes.fileUrl;
    let single_fileRef=UploadFileRes.fileRef;
    imageurl.push(single_imageurl);
    fileRef.push(single_fileRef);
   }
   let file = e.target[4].files[0];
   let UploadFileRes=await UploadFile(file,'story');
   let pdfurl= UploadFileRes.fileUrl;
    let pdfRef=UploadFileRes.fileRef;



     console.log(imageurl);
    setIsLoading(() => false);

    const time = new Date().getTime();
  
    const data = {
      heading: heading,
      tagline: tagline,
      description: description,
      imageurl: imageurl,
      time: time,
      fileRef:fileRef,
      pdfRef:pdfRef,
      pdfurl:pdfurl
    };
    console.log(data);
    const res = await PostStoryData(data);
    fetchData();
    console.log(res);
    setIsLoading(() => false);
    toast.success("UPLOADED");
  };
  const fetchData = async () => {
    // setIsLoading(() => true);
    setShowStoryModal(() => false);
    const ResGetData = await GetStoryData();
    let temp=ResGetData.data;
    temp=temp.sort((item1,item2)=>item2.time-item1.time);
    setImpactStoriesArrayDBdata(() => temp);
    // impactAllStoriesArray = ResGetData.data;
    // console.log(temp);
    // setIsLoading(() => false);
  };
  const handleStoryDelete = async (id,fileRef,pdfRef) => {
    setIsLoading(() => true);
    const deleteData = { id: id };
    let num_of_files=fileRef.length;
    for(var i=0;i<num_of_files;i++)
    {
      await DeleteFile(fileRef[i]);
    }
    if(pdfRef!=null)
    await DeleteFile(pdfRef);
    // console.log(deleteData);
    // await DeleteFile(fileRef);
    await DeleteStoryData(deleteData);
    fetchData();
    setIsLoading(() => false);
    toast.success("DELETED");
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    let len = impactStoriesArrayDBdata.length;

    console.log("length and Math.ceil=", len, "  and ", Math.ceil(len / 6));
    setPageCount(() => Math.ceil(len / 6));
    let firstParam = 0;
    let secondParam = Math.min(len, 6);
    let temp = impactStoriesArrayDBdata.slice(firstParam, secondParam);
    // console.log(page, " ", len, " ", firstParam, " ", secondParam, " ", temp);
    setImpactStoriesArrayDB(() => temp);
    console.log(temp);
  }, [impactStoriesArrayDBdata]);

  useEffect(() => {
    let len = impactStoriesArrayDBdata.length;
    let firstParam = (page - 1) * 6;
    let secondParam = Math.min(len, page * 6);
    let temp = impactStoriesArrayDBdata.slice(firstParam, secondParam);
    setImpactStoriesArrayDB(() => temp);
    console.log(temp);
    console.log(page, " ", len, " ", firstParam, " ", secondParam, " ", temp);
  }, [page]);

  /*--------------------HTML PART------------------*/

  let impactStoriesHeader = (
    <div className="impactStoriesHeader">
      <Navbar />
      <Overlay overLayColor={"#00000055"} z_index={"10"} />
      <div className="Cover-StoryPage">
     
        <PhotoSlider imgBase={"../images/impactStory/Pic-"} number={10} />
      </div>
      <div className="impactStoriesHeader-content">
          <h1>IMPACT STORIES</h1>
      </div>
    </div>
  );

  let addStoryModal = (
    <Modal
      className="modal-addStory"
      show={showStoryModal}
      onHide={() => setShowStoryModal(() => false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addmember"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addStory">
        <form onSubmit={(e) => handleStorySubmit(e)}>
          <input className="input" type="text" placeholder="Heading.." />
          <input className="input " type="text" placeholder="Tagline.." />
          <textarea
            className="input "
            type="text"
            placeholder="Description..."
          />
          <label>IMAGES</label>
          <input className="input"  type="file" multiple placeholder="add image" onChange={(e)=>multipleUpload(e)} />
          <label>PDF</label>
          <input className="input"  type="file"  placeholder=""  />
          <button type="submit">Submit</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let impactStoriesBlock = (
    <div className="impactStoriesBlock">
      <div className="impactStoriesBlock-page-number">
        <div className="impactStoriesBlock-page">
          <span>PAGE </span>
        </div>
        <div className="impactStoriesBlock-number">
          <span>{page}</span>
        </div>
      </div>

      {impactStoriesArrayDB.length>0&&impactStoriesArrayDB.map((story, i) => {
        let x = i % 10;
        if (x == 0) x++;
        return (
          <div className="story" key={i}>
            {/* <div className="story-border"></div>
            <div className="story-border"></div> */}
            <div className="story-img">
              {/* <Overlay overLayColor={"#22222255"} z_index={"10"} /> */}
              <img src={story.imageurl[0]} />
            </div>
            <div className="story-content">
              <h3>{story.heading}</h3>
              <h5>
                <i>{story.tagline}</i>
              </h5>
              <p>
                {story.description}
              </p>
              <hr />
              <button
                onClick={() => {
                    navigate("/more", {
                      state: {
                        title:story.heading,
                        tagline:story.tagline,
                        imageurl:story.imageurl,
                        date:"",
                        theme:"",
                        description:story.description,
                        pdflink:story.pdfurl,
                        videoLink:"",
                      },
                    });
                  }}
              >
                READ MORE...
              </button>
              {localStorage.getItem("CmFtoken") != null && (
                <button
                  style={{ backgroundColor: "red" }}
                  onClick={() => {
                    handleStoryDelete(story.id,story.fileRef,story.pdfRef);
                  }}
                >
                  {" "}
                  DELETE
                </button>
              )}
            </div>
          </div>
        );
      })}
      <div className="sticky-pagination">
        <ImpactStoryPagination
          pageCount={pageCount}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );

  // let impactStoryPagination = (
  //   <div className="ImpactStoryPagination-container">
  //     <ImpactStoryPagination
  //       pageCount={pageCount}
  //       handlePageChange={handlePageChange}
  //     />
  //   </div>
  // );

  return (
    <div className="impactStories">
      {impactStoriesHeader}
      {localStorage.getItem("CmFtoken") != null &&
      <button
        className="impactStories-addStory"
        onClick={() => setShowStoryModal(() => true)}
      >
        Add Story
      </button>}
      {addStoryModal}
      {impactStoriesBlock}
      {/* {impactStoryPagination} */}
      {/* {console.log(impactAllStoriesArray)} */}
      <Footer />
      <ToastContainer autoClose={1000} />
      {isLoading && <Loader />}
    </div>
  );
}
