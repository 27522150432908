import React, { useEffect, useState } from "react";
import Dots from "../../../components/Dots";
import Navbar from "../../../components/NavBar";
import "../../../styles/WASH.css";
import Footer from "../../../components/Footer";
import PhotoSlider from "../../../components/PhotoSlider";
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import { Fade } from "react-awesome-reveal";
import Reveal from "react-reveal/Reveal";
// import TransitionGroup from "react-transition-group/TransitionGroup";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { DeleteFile } from "../../../UploadFile";
import Overlay from "../../../components/Overlay";
import { GetProgramImageData, DeleteProgramImageData } from "../../../apis";
function Wash() {
  const [washImage, setWashImage] = useState([]);
  const [slidesToShow,setSlidesToShow]=useState(0);

  var settings = {
    // accessibility:true,
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    speed: 1900,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    // centerMode:true,
    focusOnSelect: true,
  };

  const deletePic=async (id,fileRef)=>{
    const data={
      id:id
    }
    await DeleteProgramImageData(data);
    await DeleteFile(fileRef);
    // fetchNutritionImage();
    window.location.reload(false);
  }
  const tempWashImage = [
    { imageurl: "https://picsum.photos/536/354" },
    { imageurl:"https://images.unsplash.com/photo-1566275529824-cca6d008f3da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGhvdG98ZW58MHx8MHx8&w=1000&q=80" },
    { imageurl: "https://cdn.mos.cms.futurecdn.net/CAZ6JXi6huSuN4QGE627NR-1200-80.jpg.webp" },
    { imageurl: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg" },
  ];

  const fetchWashImage = async () => {
    const fetchWashImageRes = await GetProgramImageData();
    console.log( fetchWashImageRes.data);
    let data = fetchWashImageRes.data.filter((item) => item.programType == "wash");;
    let temp=data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setWashImage(() => temp);
    console.log( data);
  };

  useEffect(() => {
    fetchWashImage();
    let tempSlideToShow=3;
    if(window.innerWidth<1000)
    tempSlideToShow=2;
    else if(window.innerWidth<600)
    tempSlideToShow=1;
    setSlidesToShow(()=>tempSlideToShow)
  }, []);
  return (
    <div className="wash">
      <div className="washContainer">
        <Navbar />
        <div className="washContainer-overlay"></div>

        <PhotoSlider
          imgBase={"programs/wash/Pic-"}
          number={10}
          sizeStyle={{
            width: "100%",
            height: "100%",
          }}
          className="photoSlider"
        />

        <div className="wash-heading-container">
          <Fade left>
            <h1>WASH – Water Sanitation and Hygiene</h1>
          </Fade>
          <Fade left delay={1000}>
            <span
              style={
                window.innerWidth < 400 ? { display: "none" } : { display: "" }
              }
            >
             Enabling equitable and sustainable access <br/> to safe drinking water and sanitation
            </span>
          </Fade>
        </div>

        {/* <div className='color-overlay'></div> */}
      </div>

      <div className="washHeading">
        {/* <h2>Wash</h2> */}
        <span
          style={
            window.innerWidth > 400 ? { display: "none" } : { display: "" }
          }
        >
         Enabling equitable and sustainable access to safe drinking water and sanitation
        </span>
      </div>

      <div className="washAbout">
        {/* <Overlay overLayColor={"#333333dd"} /> */}
        <div className="washAbout-content">
          <p>
            A vast majority of rural women in Rajasthan spend more than an hour
            fetching drinking water for their families. Toilet usage too remain
            low as it is largely dependent on water availability for upkeep.
            Water borne diseases emerging from poor hygienic practices are
            closely linked to malnutrition among children. CmF is focussing on
            behaviour change through local art forms and demand generation for
            safe water and sanitation among rural communities. This is augmented
            with facilitating the right technology for infrastructure
            development, creating linkages with government, and facilitating
            collectivization of community for self-management and governance of
            water resources.
          </p>
        </div>
      </div>

      <div className="washstrategy-new">
        <h4>Strategy & Intervention Areas</h4>
        <div className="washstrategy-content">
          <Fade left>
            <div className="washstrategy1-new">
              <h6>Community managed WASH infrastructure </h6>
              <ul>
                <li>
                  Set up habitation level safe drinking water supply systems{" "}
                </li>
                <li>Integrate technologies for smart water management</li>
                <li>
                  Building capacities of water user groups for regular operation
                  & maintenance
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="washstrategy2-new">
              <h6>BEHAVIOUR CHANGE THROUGH SOCIAL ART </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>Use of toilets</li>
                <li>Handwashing and safe handling of drinking water</li>
                <li>Fluoride mitigation </li>
              </ul>
            </div>
          </Fade>

          <Fade left>
            <div className="washstrategy3-new">
              <h6>CAPACITATING PEOPLE’S REPRESENTATIVES AND GOVERNMENT </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>
                  Increasing involvement of community in planning processes of
                  Panchayati Raj Institutions (PRIs)
                </li>
                <li>
                  Training on management and maintenance of water supply systems{" "}
                </li>
                <li>
                  Technical support for designing and monitoring of construction
                  of water resource structures{" "}
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="washstrategy4-new">
              <h6>Menstrual Hygiene Management </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>Training of women and adolescent girls</li>
                <li>
                  Develop supply chain of environment friendly sanitary products
                </li>
                <li>
                  Link with public health systems for related health issues
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
      <div className="wash-journeySoFar">
        <p>
          <b>Journey so far: </b> &bull; 242,000 individuals reached through
          behavior change campaigns &bull; 120,000 individuals gained access to
          safe drinking water and sanitation &bull; 120 schools and Anganwadis
          equipped with improved WASH facilities &bull; 50,000 adolescent girls
          and women trained on MHM
        </p>
      </div>
      <div className="photoGallery">
        {washImage.length > 0 && (
          <Slider {...settings} className="  washSlider">
            {washImage.map((item, i) => {
              return (
                <div className="washSlider-image-container" >
                <div className="washSlider-image-container-internalDiv">
                   <img src={item.imageurl}/>
                   {localStorage.getItem("CmFtoken") != null &&
                   <button onClick={()=>deletePic(item.id,item.fileRef)} >DELETE</button>}
                   </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Wash;
