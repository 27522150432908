import React, { useEffect, useState } from "react";
import Dots from "../../../components/Dots";
import Navbar from "../../../components/NavBar";
import "../../../styles/microfinance.css";
import Footer from "../../../components/Footer";
import PhotoSlider from "../../../components/PhotoSlider";
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import { Fade } from "react-awesome-reveal";
import Reveal from "react-reveal/Reveal";
// import TransitionGroup from "react-transition-group/TransitionGroup";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { DeleteFile } from "../../../UploadFile";
import Overlay from "../../../components/Overlay";
import { GetProgramImageData, DeleteProgramImageData } from "../../../apis";
function Microfinance() {

  const [microfinanceImage, setMicrofinanceImage] = useState([]);
  const [slidesToShow,setSlidesToShow]=useState(0);

  var settings = {
    // accessibility:true,
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    speed: 1900,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    // centerMode:true,
    focusOnSelect: true,
  };

  const deleteMicrofinanceImage = async (id) => {
    await DeleteProgramImageData;
  };
  const tempMicrofinanceImage = [
    { imageurl: "https://picsum.photos/536/354" },
    { imageurl:"https://images.unsplash.com/photo-1566275529824-cca6d008f3da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGhvdG98ZW58MHx8MHx8&w=1000&q=80" },
    { imageurl: "https://cdn.mos.cms.futurecdn.net/CAZ6JXi6huSuN4QGE627NR-1200-80.jpg.webp" },
    { imageurl: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg" },
  ];

  const deletePic=async (id,fileRef)=>{
    const data={
      id:id
    }
    await DeleteProgramImageData(data);
    await DeleteFile(fileRef);
    // fetchMicrofinanceImage();
    window.location.reload(false);
  }
  const fetchMicrofinanceImage = async () => {
    const fetchMicrofinanceImageRes = await GetProgramImageData();

    console.log( fetchMicrofinanceImageRes.data);
    let data = fetchMicrofinanceImageRes.data.filter((item) => item.programType == "livelihood");

    let temp=data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setMicrofinanceImage(() => temp);
    console.log( data);
  };

  useEffect(() => {
    fetchMicrofinanceImage();
    let tempSlideToShow=3;
    if(window.innerWidth<1000)
    tempSlideToShow=2;
    else if(window.innerWidth<600)
    tempSlideToShow=1;
    setSlidesToShow(()=>tempSlideToShow)
  }, []);
  return (
    <div className="microfinance">
      <div className="microfinanceContainer">
        <Navbar />
        <div className="microfinanceContainer-overlay"></div>

        <PhotoSlider
          imgBase={"programs/livelihood/Pic-"}
          number={10}
          sizeStyle={{
            width: "100%",
            height: "100%",
          }}
          className="photoSlider"
        />

        <div className="microfinance-heading-container">
          <Fade left>
            <h1>COMMUNITY LED LIVELIHOODS AND MICROFINANCE</h1>
          </Fade>
          <Fade left delay={1000}>
            <span
              style={
                window.innerWidth < 400 ? { display: "none" } : { display: "" }
              }
            >
              Ushering in rural prosperity through community led institutions
              <br /> and entrepreneurship development
            </span>
          </Fade>
        </div>

        {/* <div className='color-overlay'></div> */}
      </div>

      <div className="microfinanceHeading">
        {/* <h2>Microfinance</h2> */}
        <span
          style={
            window.innerWidth > 400 ? { display: "none" } : { display: "" }
          }
        >
        Ushering in rural prosperity through community led institutions and entrepreneurship development 
        </span>
      </div>

      <div className="microfinanceAbout">
        {/* <Overlay overLayColor={"#333333dd"} /> */}
        <div className="microfinanceAbout-content">
          <p>
            Though landholdings continue to grow smaller with each generation,
            agriculture has remained the major primary occupation. Subsistence
            agriculture is supported by small scale livestock rearing and a
            large proportion of cash incomes across households come from daily
            wage labour and seasonal migration. Women’s Self-Help Groups and
            their apex institutions – SHG federations have proven to be an
            effective medium of financial inclusion and enabled access to credit
            for furthering farm and non-farm based enterprises. CmF focuses on
            strengthening capacities of SHGs and federations to achieve
            financial sustainability and self-management. Thereafter,
            interventions designed for improving productivity and market
            linkages in agriculture and livestock and skilling in non-farm
            trades for self-employment and job placements help small and
            marginal households to embark on a pathway from subsistence to
            prosperity.
          </p>
        </div>
      </div>

      <div className="microfinancestrategy-new">
        <h4>Strategy & Intervention Areas</h4>
        <div className="microfinancestrategy-content">
          <Fade left>
            <div className="microfinancestrategy1-new">
              <h6>Enabling sustainable access of rural women to financial services through community institutions – SHGs, Village Organizations and Federations  </h6>

              <ul>
                <li>
                Training and capacity building of community institutions – leaders, staff, and members 
                </li>
                <li>Strengthening financial systems – audit and grading </li>
                <li>
                Supporting system setting and compliances 
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="microfinancestrategy2-new">
              <h6>Increasing and sustaining yields from agriculture and livestock  </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>Training and on-field support to farmer producers on improved package of practices </li>
                <li>Water conservation and irrigation access for small and marginal farmers</li>
                <li>Piloting and scaling up climate smart technologies </li>
              </ul>
            </div>
          </Fade>

          <Fade left>
            <div className="microfinancestrategy3-new">
              <h6>Facilitating market linkages of small and marginal farmers for agriculture and livestock products  </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>
                Promotion and technical support to Farmer Producer Organizations 
                </li>
                <li>
                Linkages with institutional and retail buyers 
                </li>
                <li>
                Processing and value chain integration through entrepreneurs and FPOs
                </li>
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="microfinancestrategy4-new">
              <h6>Skilling linkages and micro enterprise development </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>Awareness and counselling of youth on skilling opportunities </li>
                <li>
                Linkages with skilling institutes and apprenticeship with local businesses – Udyog Mitras
                </li>
                <li>
                Business planning, financial and digital literacy training to potential entrepreneurs 
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
      <div className="microfinance-journeySoFar">
        <p>
          <b>Journey so far: </b> Journey so far: &bull;103,000 women of Self-Help Groups supported &bull; Technical support to state government programs – 9 districts &bull; 2,000+ women service providers in agriculture and livestock groomed &bull; 6,300 youth trained and supported for job and self-employment
        </p>
      </div>
      <div className="photoGallery">
        {microfinanceImage.length > 0 && (
          <Slider {...settings} className="  microfinanceSlider">
            {microfinanceImage.map((item, i) => {
              return (
                <div className="microfinanceSlider-image-container" >
                <div className="microfinanceSlider-image-container-internalDiv">
                   <img src={item.imageurl}/>
                   {localStorage.getItem("CmFtoken") != null &&
                   <button onClick={()=>deletePic(item.id,item.fileRef)} >DELETE</button>}
                   </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Microfinance;
