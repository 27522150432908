import React from "react";
import "../../styles/partners.css";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
export default function Partners() {
  const donorimgArray = [
    "Donor-1.jpg",
    "Donor-2.png",
    "Donor-3.jpg",
    "Donor-4.png",
    "Donor-5.png",
    "Donor-6.png",
    "Donor-7.png",
    "Donor-8.jpg",
    "Donor-9.png",
    "Donor-10.png",
  ];
  const govtimgArray = [
    "Govt-1.jpg",
    "Govt-2.jpeg",
    "Govt-3.jpg",
    "Govt-4.jpg",
  ];
  const ngoimgArray = [
    "NGO-1.png",
    "NGO-2.jpg",
    "NGO-3.png",
    "NGO-4.jpg",
    "NGO-5.bmp",
    "NGO-6.gif",
    "NGO-7.png",
    "NGO-8.gif",
    "NGO-9.jpg",
    "NGO-10.jpg",
    "NGO-11.jpg",
    "NGO-12.jpg",
  ];

  let partnerHeader = (
    <div className="partnerHeader">
      <Navbar />
      <h2>OUR PARTNERS</h2>
      <p>
        For a SUSTAINABLE and long-lasting TRANSFORMATION in its efforts, CmF
        has partnered with influential institutions i.e., system-level learning
        partnerships with GOVERNMENT and IMPLEMENTATION PARTNERS, supported by
        leading foundations, DONORS, and eminent Organizations to amplify its
        strategy.
      </p>
    </div>
  );

  let partnerContent = (
    <div className="partnerContent">
      <div className="partnerContent-donor partnerContent-subSec ">
        {donorimgArray.map((item, i) => {
          return (
            <div className="partner-single">
              <img src={`../images/partners/Donors/${item}`} />
            </div>
          );
        })}
      </div>
      <div className="partnerContent-govt partnerContent-subSec">
        {govtimgArray.map((item, i) => {
          return (
            <div className="partner-single">
              <img src={`../images/partners/GovtPrograms/${item}`} />
            </div>
          );
        })}
      </div>
      <div className="partnerContent-ngo partnerContent-subSec">
        {ngoimgArray.map((item, i) => {
          return (
            <div className="partner-single">
              <img src={`../images/partners/NGOpartners/${item}`} />
            </div>
          );
        })}
      </div>
    </div>
  );
  return (
    <div className="partners">
      {partnerHeader}
      {partnerContent}
      <Footer />
    </div>
  );
}
