import React, { useEffect, useState } from 'react'
import '../../styles/otherEvent.css';
import {Modal} from 'react-bootstrap';
import {PostOtherEventData,GetOtherEventData,DeleteOtherEventData,UploadOtherEventImage} from "../../apis";
import { ImTerminal } from 'react-icons/im';
import Footer from '../../components/Footer';
import Navbar from '../../components/NavBar';
import Overlay from '../../components/Overlay';
import PhotoSlider from '../../components/PhotoSlider';
import {UploadFile,DeleteFile} from '../../UploadFile';
import { useNavigate } from 'react-router-dom';
export default function OtherEvents() {
const navigate=useNavigate();
    const [otherEventDBdata,setOtherEventDBdata]=useState([]);
    const [addOtherEventmodalShow,setAddOtherEventmodalShow] = useState(false);

    const fetchOtherEventData= async()=>{
         const GetOtherEventDataRes=  await GetOtherEventData();
         let temp= GetOtherEventDataRes.data;
         temp=temp.sort((item1,item2)=>item1.time-item2.time);
         setOtherEventDBdata(()=>temp);
         console.log(GetOtherEventDataRes.data);
    }
    const deleteOtherEventDatafunc=async(id,fileRef)=>{
        const data={
            id:id
        }
        const deleteOtherEventDataRes=  await DeleteOtherEventData(data);
        fetchOtherEventData();
    }
   const handleAddOtherEventSubmit=async(e)=>{
    e.preventDefault();
    const title=e.target[0].value;
    const keyPoint=e.target[1].value;
    const link=e.target[2].value;
    const date=e.target[3].value;
    
    setAddOtherEventmodalShow(()=>false);
    const file = e.target[4].files[0];
   
    const UploadFileRes=await UploadFile(file,'newsNevents');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
     const time = new Date().getTime();
     const data={
        title:title,
        date:date,
       keyPoint:keyPoint,
       link:link,
        imageurl:imageurl,
        time:time,
        fileRef:fileRef
     }
     const  uploadOtherEventDataRes= await PostOtherEventData(data);
    fetchOtherEventData();
   }
   useEffect(()=>{
    fetchOtherEventData();
   },[])
    let addOtherEventModal=( <Modal
        className="modal-addOtherEvent"
        show={addOtherEventmodalShow}
        onHide={() => setAddOtherEventmodalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter-addOtherEvent"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
          </Modal.Header>
        <Modal.Body className="modal-body-addOtherEvent">
    
        <form onSubmit={(e)=>handleAddOtherEventSubmit(e)}>
        <input  className="input" type="text" placeholder="Title" />
        <input  className="input" type="text" placeholder="Key Ponts" />
        <input  className="input" type="text" placeholder="LINK(if any)" />
        <input  className="input" type="text" placeholder="DATE" />
        <input  className="input" type="file" placeholder="IMAGE" />
        <label >Add Image</label>
        <button type="submit" >SUBMIT</button>
        </form>
        </Modal.Body>
        
    
        </Modal>)

        let otherEventContent=(<div className='otherEventContent'>
           {/* {
            otherEventDBdata.length>0&&otherEventDBdata.map((item,i)=>{
                return <button onClick={()=>deleteOtherEventDatafunc(item.id)} >delete</button>
            }
               
            )
           } */}
        
              {otherEventDBdata.length>0&&otherEventDBdata.map((item,i)=>{
                return <div className='otherEventContent-eventContainer-singleEvent'>
               
               <img src={item.imageurl} />
               <h4>{item.title}</h4>
               <p>{item.keyPoint}</p>
                 { item.date&& <p>{item.date}</p>}  
              <div className='otherEventContent-eventContainer-singleEvent-footer' >
              {item.link!==""&&<a target="_blank" href={item.link}>Link</a>}
              {localStorage.getItem("CmFtoken") != null &&
               <button onClick={()=>deleteOtherEventDatafunc(item.id,item.fileRef)} >DELETE</button>}
              
               <button
                onClick={() => {
                    navigate("/more", {
                      state: {
                        title:item.title,
                        tagline:"",
                        imageurl:item.imageurl,
                        date:item.date,
                        theme:"",
                        description:item.keyPoint,
                        pdflink:item.link,
                        videoLink:"",
                      },
                    });
                  }}
               
               >READ MORE</button>
              
               </div>
                </div>

              })
              }
         
        </div>)

        let otherEventsHeader=( <div className="newsNeventsHeader">
        <Navbar />
        <Overlay overLayColor={"#00000055"} z_index={"10"} />
        <div className="Cover-AwardPage">
       
          <PhotoSlider imgBase={"../images/media/Pic-"} number={7} />
        </div>
        <div className="newsNeventsHeader-content">
            <h1>NEWS & EVENTS</h1>
        </div>
      </div>)
  return (
    <div className='otherEvent' >
    {otherEventsHeader}
    <div className='addOtherEventButton'>
    {localStorage.getItem("CmFtoken") != null &&
     <button onClick={()=>setAddOtherEventmodalShow(()=>true)}  >ADD Events</button>}
     </div>
     {addOtherEventModal}
     {otherEventContent}
<Footer/>
    </div>
  )
}
