import React, { useEffect, useState } from "react";
import "../../styles/homePagePrograms.css";
import Card from "../Card";
import Overlay from "../../components/Overlay";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

// EDUCATION
//

//
//

//
//

//
//

//
//

//
//

const programHeads = [
  {
    name: "LIVELIHOODS & mF",
    img: "../images/home/programs/Program_Livelihoods.JPG",
    tagline: "Ushering in Rural Prosperity Through Community Led Institutions",
    routingPath: "/programs/microfinance",
  },
  {
    name: "EDUCATION",
    img: "../images/home/programs/Program_Education.jpg",
    tagline:
      "Strengthening learning environment for accessible, enjoyable, and meaningful education",
    routingPath: "/programs/education",
  },
  {
    name: "NUTRITION",
    img: "../images/home/programs/Program_Nutrition.JPG",
    tagline:
      "Enabling access and enhancing service quality to fight malnutrition",
    routingPath: "/programs/nutrition",
  },
  {
    name: "WASH",
    img: "../images/home/programs/Program_WASH.JPG",
    tagline:
      "Community & Institution-led Sustainable and Equitable Access to WASH Facilities",
    routingPath: "/programs/WASH",
  },
];
export default function Programs() {
  const [hoverDisplay, setHoverDisplay] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [overlayColor, setOverlayColor] = useState([
    "#00000000",
    "#00000000",
    "#00000000",
    "#00000000",
 
  ]);
  const onHoverColor = [
    "#da251c90",
    "#da251c90",
    "#c7914090",
    "#cf599890",
  
  ];
  let randomMargin = [60, 20, 80, 60, 10, 80];

  const handleHover = (i) => {
    let index = i;
    setHoverDisplay((prev) => {
      let tempHover = [...prev];
      tempHover[index] = !tempHover[index];
      return tempHover;
    });
    setOverlayColor((prev) => {
      let tempOverlay = [...prev];
      tempOverlay[index] =
        tempOverlay[index] === "#00000000" ? onHoverColor[index] : "#00000000";
      return tempOverlay;
    });
  };

  // const handleHover=
  return (
    <div className="Programs-HomePage">
      {/* <img
        src="../images/programs/nutrition/nutrition1.JPG"
        className="Programs-HomePage-backgroundimg"
      /> */}
      {/* <Overlay overLayColor={"#000000dd"} /> */}
      <div className="Programs-HomePage-heading">
        <h1>FOCUS AREAS</h1>
      </div>
      <hr />
      {programHeads.map((item, i) => {
        console.log(i);

        return (
          <Fade delay={i * 100} bottom>
            <div
              className="programType"
              // style={{ marginTop:`${randomMargin[i]}px` }}
              onMouseEnter={() => handleHover(i)}
              onMouseLeave={() => handleHover(i)}
            >
              <Link to={`${item.routingPath}`}>
                <img src={item.img} />
                <Overlay overLayColor={overlayColor[i]} />

                {/* url(${item.img}) */}
                <div
                  className="programType-tagline"
                  style={
                    hoverDisplay[i] === true
                      ? { opacity: "1" }
                      : { opacity: "0" }
                  }
                >
                  <p>{item.tagline}</p>
                </div>

                <div
                  className="programType-top-heading"
                  style={
                    hoverDisplay[i] === true
                      ? { opacity: "0" }
                      : { opacity: "1" }
                  }
                >
                  <h4>{item.name}</h4>
                </div>

                <div
                  className="programType-bottom-heading"
                  style={
                    hoverDisplay[i] === true
                      ? { opacity: "1" }
                      : { opacity: "0" }
                  }
                >
                  <h4>{item.name}</h4>
                  {/*  */}
                </div>
              </Link>
            </div>
          </Fade>
        );
      })}
    </div>
  );
}
