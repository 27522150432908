import React, { useEffect, useState } from "react";
import "../../styles/coverage.css";
import {UploadFile,DeleteFile} from '../../UploadFile';
import { Modal } from "react-bootstrap";
import PhotoSlider from "../../components/PhotoSlider";
import {
  PostCoverageData,
  GetCoverageData,
  DeleteCoverageData,
  UploadCoverageImage,
} from "../../apis";
import { ImTerminal } from "react-icons/im";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import Overlay from "../../components/Overlay";
import Loader from "../../components/Loader";
export default function Coverage() {
  const [isLoading, setIsLoading] = useState(false);
  const [coverageDBdata, setCoverageDBdata] = useState([]);
  const [addCoveragemodalShow, setAddCoveragemodalShow] = useState(false);

  const fetchCoverageData = async () => {
    // setIsLoading(() => true);
    const GetCoverageDataRes = await GetCoverageData();

    let temp=GetCoverageDataRes.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);

    setCoverageDBdata(() => temp);
    console.log(GetCoverageDataRes.data);
    // setIsLoading(() => false);
  };
  const deleteCoverageDatafunc = async (id,fileRef) => {
    setIsLoading(() => true);
    const data = {
      id: id,
    };
    const deleteCoverageDataRes = await DeleteCoverageData(data);
    fetchCoverageData();
    setIsLoading(() => false);
  };
  const handleAddCoverageSubmit = async (e) => {
    e.preventDefault();
    const title = e.target[0].value;
    const linkToPdf = e.target[1].value;
    const linkToVideo = e.target[2].value;
     const theme=e.target[3].value;
  
    setAddCoveragemodalShow(() => false);
    setIsLoading(() => true);
   
    const file = e.target[4].files[0];
   
    const UploadFileRes=await UploadFile(file,'coverage');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
    const time =new Date().getTime();
    const data = {
      title: title,
      theme: theme,
      linkToPdf: linkToPdf,
      linkToVideo:linkToVideo,
      imageurl: imageurl,
      time:time,
      fileRef:fileRef
    };
    const uploadCoverageDataRes = await PostCoverageData(data);
    fetchCoverageData();
    setIsLoading(() => false);
  };
  useEffect(() => {
    fetchCoverageData();
  }, []);
  let addCoverageModal = (
    <Modal
      className="modal-addCoverage"
      show={addCoveragemodalShow}
      onHide={() => setAddCoveragemodalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addCoverage"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addCoverage">
        <form onSubmit={(e) => handleAddCoverageSubmit(e)}>
          <input className="input" type="text" placeholder="Title" />    
          <input className="input" type="text" placeholder="Link To Pdf" /> 
          <input className="input" type="text" placeholder="Link To video" /> 
          <select name="themeSelect">
            <option value="">Select Theme</option>
            <option value="Community-level Livelihoods & mF"> Community-level Livelihoods & mF</option>
            <option value="Skilling & Entrepreneurship">Skilling & Entrepreneurship</option>
            <option value="Education">Education</option>
            <option value="WASH">WASH</option>
            <option value="MHM">MHM</option>
            <option value="Nutrition">Nutrition</option>
          </select>
          <input className="input" type="file" placeholder="IMAGE" />
          <label>Add Image</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let coverageContent = (
    <div className="coverageContent">
      {/* {
            coverageDBdata.length>0&&coverageDBdata.map((item,i)=>{
                return <button onClick={()=>deleteCoverageDatafunc(item.id)} >delete</button>
            }
               
            )
           } */}

      {coverageDBdata.length > 0 &&
        coverageDBdata.map((item, i) => {
          return (
            <div className="coverageContent-eventContainer-singleEvent">
              <img src={item.imageurl} />
              <h4>{item.title}</h4>
              <p>{item.theme}</p>
              {/* {item.date && <p>{item.date}</p>} */}
              <div className="coverageContent-eventContainer-singleEvent-footer">
                {item.linkToPdf!==""&&<a target="_blank" href={item.linkToPdf}>PDF</a>}
                {item.linkToVideo!==""&&<a target="_blank" href={item.linkToVideo}>VIDEO</a>}
                {localStorage.getItem("CmFtoken") != null &&<button onClick={() => deleteCoverageDatafunc(item.id,item.fileRef)}>
                  DELETE
                </button>}

                {/* <button>READ MORE</button> */}
              </div>
            </div>
          );
        })}
    </div>
  );

  let coverageHeader = (
    <div className="coverageHeader">
    <Navbar />
    <Overlay overLayColor={"#00000055"} z_index={"10"} />
    <div className="Cover-CoveragePage">
   
      <PhotoSlider imgBase={"../images/media/Pic-"} number={7} />
    </div>
    <div className="coverageHeader-content">
        <h1>MEDIA COVERAGE</h1>
    </div>
  </div>
  );
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="coverage">
          {coverageHeader}
          <div className="addCoverageButton">
          {localStorage.getItem("CmFtoken") != null &&
            <button onClick={() => setAddCoveragemodalShow(() => true)}>
              ADD COVERAGE
            </button>}
          </div>
          {addCoverageModal}
          {coverageContent}
          <Footer />
        </div>
      )}
    </>
  );
}
