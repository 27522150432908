import React from 'react';
import '../styles/dots.css';
export default function Dots({extraStyle}) {
  return (
    <div className='dots'>
        <div className='dot1' style={extraStyle}></div>
        <div className='dot1' style={extraStyle}></div>
        <div className='dot1' style={extraStyle}></div>
    </div>
  )
}
