// import logo from './logo.svg';
import './App.css';

  import 'react-toastify/dist/ReactToastify.css';
import React,{useEffect} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AboutUs from "./pages/common/about/AboutUs";
import ReadMore from './pages/common/ReadMore';
import ContactUs from "./pages/common/ContactUs";
import HomePage from './pages/common/HomePage';
import NewComponent from './pages/common/new_components'
import OurTeam from './pages/common/about/OurTeam';
import AdminLogin from './pages/admin/AdminLogin';
import Achievements from './pages/common/impact/Achievements'
import Education from './pages/common/programs/Education';
import EducationHighlights from './pages/common/programs/EducationHighlights';
import Skilling from './pages/common/programs/Skilling'
import Nutrition from './pages/common/programs/Nutrition';
import Wash from './pages/common/programs/Wash';
import Microfinance from './pages/common/programs/Microfinance';
import Programs from './pages/common/programs/Programs';
import ImpactStories from './pages/common/impact/ImpactStories';
import ImpactStoriesDetail from './pages/common/impact/ImpactStoriesDetail';
import Report from './pages/common/Report';
import Audit from './pages/common/Audit';
import ScrollToTop from './components/ScrollToTop';
import Career from "./pages/common/Career";
import ProfVyas from './pages/common/ProfVyas';
import Events from './pages/common/Events';
import Award from './pages/common/Award';
import Research from './pages/common/Research';
import Opinions from './pages/common/Opinions';
import Coverage from './pages/common/Coverage';
import Videos from './pages/common/Videos';
import OtherEvents from './pages/common/OtherEvents';
import Colloquium from './pages/common/Colloquium';
import Partners from './pages/common/Partners';
import Loader from './components/Loader';
function App() {
  useEffect(() => {
   document.title="Centre For Microfinance"
  }, [])
  
  return (
    <div className="App">
    <ScrollToTop/>
    <Routes>
     <Route path="/loader" element={<Loader/>} />
      <Route path="/" element={<HomePage />} />
      <Route path="/aboutUs" element={<AboutUs />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/new_component" element={<NewComponent />} />
      {/* <Route path="/ourTeam" element={<OurTeam />} /> */}
      <Route path="/adminLogin" element={<AdminLogin />} />
      {/* <Route path="/achievements" element={<Achievements />} /> */}
      <Route path="/programs" element={<Programs/>} />
      <Route path="/impactStories" element={<ImpactStories/>} />
      <Route path="/impactStories/more" element={<ImpactStoriesDetail/>} />
      <Route path="/reports" element={<Audit/>} />
      <Route path="/career" element={<Career/>}/>
      <Route path="/Prof-Vyas" element={<ProfVyas/>}/>
      
      <Route path="/awardNrecognition" element={<Award/>}/>
      <Route path="/reflections" element={<Opinions/>}/>
      <Route path="/research" element={<Research/>}/>
      <Route path="/videos" element={<Videos/>}/>
      <Route path="/coverage" element={<Coverage/>}/>
      <Route path="/newsNevents" element={<OtherEvents/>}/>
      <Route path="/colloquium" element={<Colloquium/>}/>
      <Route path="/partners" element={<Partners/>}/>
      <Route path="/more" element={<ReadMore/>}/>

      /*---------------- PROGRAMS--------------------- */

      <Route path="/programs/WASH" element={<Wash/>} />
      <Route path="/programs/education" element={<Education/>} />
      {/* <Route path="/programs/education/more/:id" element={<EducationHighlights/>} /> */}
      <Route path="/programs/skills" element={<Skilling/>} />
      <Route path="/programs/nutrition" element={<Nutrition/>} />
      <Route path="/programs/microfinance" element={<Microfinance/>} />
    </Routes>
    
    </div>
  );
}

export default App;


