import React, { useEffect, useState } from "react";
import Dots from "../../../components/Dots";
import Navbar from "../../../components/NavBar";
import "../../../styles/nutrition.css";
import Footer from "../../../components/Footer";
import PhotoSlider from "../../../components/PhotoSlider";
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import { Fade } from "react-awesome-reveal";
import Reveal from "react-reveal/Reveal";
import { DeleteFile } from "../../../UploadFile";
// import TransitionGroup from "react-transition-group/TransitionGroup";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Overlay from "../../../components/Overlay";
import { GetProgramImageData, DeleteProgramImageData } from "../../../apis";
function Nutrition() {

  const [nutritionImage, setNutritionImage] = useState([]);
  const [slidesToShow,setSlidesToShow]=useState(0);

  var settings = {
    // accessibility:true,
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    speed: 1900,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    // centerMode:true,
    focusOnSelect: true,
  };

  const deleteNutritionImage = async (id) => {
    await DeleteProgramImageData;
  };
  const tempNutritionImage = [
    { imageurl: "https://picsum.photos/536/354" },
    { imageurl:"https://images.unsplash.com/photo-1566275529824-cca6d008f3da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGhvdG98ZW58MHx8MHx8&w=1000&q=80" },
    { imageurl: "https://cdn.mos.cms.futurecdn.net/CAZ6JXi6huSuN4QGE627NR-1200-80.jpg.webp" },
    { imageurl: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg" },
  ];

  const deletePic=async (id,fileRef)=>{
    const data={
      id:id
    }
    await DeleteProgramImageData(data);
    await DeleteFile(fileRef);
    // fetchNutritionImage();
    window.location.reload(false);
  }
  const fetchNutritionImage = async () => {
    const fetchNutritionImageRes = await GetProgramImageData();
    console.log( fetchNutritionImageRes.data);
    let data = fetchNutritionImageRes.data.filter((item) => item.programType == "nutrition");

    let temp=data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setNutritionImage(() => temp);
    console.log( data);
  };

  useEffect(() => {
    fetchNutritionImage();
    let tempSlideToShow=3;
    if(window.innerWidth<1000)
    tempSlideToShow=2;
    else if(window.innerWidth<600)
    tempSlideToShow=1;
    setSlidesToShow(()=>tempSlideToShow)
  }, []);
  return (
    <div className="nutrition">
      <div className="nutritionContainer">
        <Navbar />
        <div className="nutritionContainer-overlay"></div>

        <PhotoSlider
          imgBase={"programs/nutrition/Pic-"}
          number={10}
          sizeStyle={{
            width: "100%",
            height: "100%",
          }}
          className="photoSlider"
        />

        <div className="nutrition-heading-container">
          <Fade left>
            <h1>MATERNAL AND CHILD HEALTH AND NUTRITION</h1>
          </Fade>
          <Fade left delay={1000}>
            <span
              style={
                window.innerWidth < 400 ? { display: "none" } : { display: "" }
              }
            >
              Reducing malnutrition through behaviour change <b />
              and strengthening service quality
            </span>
          </Fade>
        </div>

        {/* <div className='color-overlay'></div> */}
      </div>

      <div className="nutritionHeading">
        {/* <h2>Nutrition</h2> */}
        <span
          style={
            window.innerWidth > 400 ? { display: "none" } : { display: "" }
          }
        >
          Enabling equitable and sustainable access to safe drinking water and
          sanitation
        </span>
      </div>

      <div className="nutritionAbout">
        {/* <Overlay overLayColor={"#333333dd"} /> */}
        <div className="nutritionAbout-content">
          <p>
            Child malnutrition remains an area of concern for the state of
            Rajasthan, particularly in its tribal dominated pockets. Anaemic
            young mothers, low birth spacing, poor access and low usage of
            public health delivery systems and poor diets of mothers and
            children are the major reasons for the prevalence of malnutrition
            among vast majority in the poverty pockets. CmF is making efforts to
            change health seeking behaviour of communities and activating the
            public health systems through capacity building and on-field support
            to frontline workers.
          </p>
        </div>
      </div>

      <div className="nutritionstrategy-new">
        <h4>Strategy & Intervention Areas</h4>
        <div className="nutritionstrategy-content">
          <Fade left>
            <div className="nutritionstrategy1-new">
              <h6>Developing model Anganwadis</h6>

              <ul>
                <li>
                Refurbishment and equipping for regular growth monitoring and key H & N services
                </li>
                <li>Hygienic environment and practices – safe drinking water, handwashing </li>
               
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="nutritionstrategy2-new">
              <h6>BEHAVIOUR CHANGE THROUGH SOCIAL ART </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>Regular trainings - Growth Monitoring, Infant & Young Child Feeding & Home Based New-born Care</li>
                <li>On-site support to frontline workers</li>
              </ul>
            </div>
          </Fade>

          <Fade left>
            <div className="nutritionstrategy3-new">
              <h6>Strengthening government system for prevention and early identification of malnutrition  </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>
                Facilitating data sharing and convergence among AAAs – Anganwadi worker, Asha and ANM
                </li>
                <li>
                Supporting outreach activities of government’s Poshan Abhiyan, immunization, and anaemia reduction
                </li>
             
              </ul>
            </div>
          </Fade>

          <Fade right>
            <div className="nutritionstrategy4-new">
              <h6>Behaviour Change among communities on addressing malnutrition and anaemia </h6>
              {/* <Overlay overLayColor={"rgba(142, 189, 119,0.7)"} /> */}
              <ul>
                <li>Training of women and adolescent girls</li>
                <li>
                Activating community platforms for knowledge and awareness on nutrition- VHWSNCs, SHGs PLA meetings
                </li>
                <li>
                Home based counselling and linkages with government system 
                </li>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
      <div className="nutrition-journeySoFar">
        <p>
          <b>Journey so far: </b> &bull; 179 Anganwadis and 168 VHWSNCs
          activated &bull; Increase in Ante Natal Check (ANC) check-ups (93.7%
          mothers), Institutional Delivery (98.5% mothers), and exclusive
          breastfeeding (91.3% children) &bull; Reduction in underweight
          (weight-for-age) children (29.6% children in intervention area against
          50.4% in NFHS-4 Sirohi) &bull; 1010 Frontline Workers trained on
          growth monitoring, HBNC & IYCF, and Hygienic Practices
        </p>
      </div>
      <div className="photoGallery">
        {nutritionImage.length > 0 && (
          <Slider {...settings} className="  nutritionSlider">
            {nutritionImage.map((item, i) => {
              return (
                <div className="nutritionSlider-image-container" >
                <div className="nutritionSlider-image-container-internalDiv">
                   <img src={item.imageurl}/>
                   {localStorage.getItem("CmFtoken") != null &&
                   <button onClick={()=>deletePic(item.id,item.fileRef)} >DELETE</button>}
                   </div>
                </div>
              );
            })}
          </Slider>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Nutrition;
