import React from "react";
import "../../../styles/impactStoriesDetail.css";
import { useLocation } from "react-router-dom";
import Navbar from "../../../components/NavBar";
import Overlay from "../../../components/Overlay";
export default function ImpactStoriesDetail() {
  
  let randomOverlayColorIndex = Math.floor(Math.random() * 4);
  let randomOverlayColorArray = ["#FFCCCC", "#FFFFCC", "#CCFFCC", "#CCCCFF"];
  const { state } = useLocation();
  const { heading, tagline, img, detail } = state;
  console.log(img);

  
  /*--------------------HTML PART------------------*/

  let impactStoriesDetailHeader = (
    <div className="impactStoriesDetailHeader">
      <Navbar />
      <Overlay
        overLayColor={`${randomOverlayColorArray[randomOverlayColorIndex]}aa`}
      />
      <img src={img} className="impactStoriesDetailHeader-img" />

      <div className="impactStoriesDetailHeader-heading">
        <h2>{heading}</h2>
      </div>
    </div>
  );

  let impactStoriesDetailContent = (
    <div className="impactStoriesDetailContent">
      <h3>{heading}</h3>
      <h5>
        "<i>{tagline}</i>"
      </h5>
      <img src={img} />
      <p>{detail}</p>
    </div>
  );
  return (
    <div className="ImpactStoriesDetail">
      {impactStoriesDetailHeader}
      {impactStoriesDetailContent}
      {console.log(tagline)}
    </div>
  );
}
