import React,{useState} from 'react';
import { storage } from './firebaseConfig';
import Compress from "browser-image-compression";
// import {ref,uploadBytes} from 'firebase/storage';
import { ref, getDownloadURL, uploadBytes,deleteObject,uploadBytesResumable } from "firebase/storage";

const UploadFile=async (file,folder) =>{
    // const [fileurl,setFileUrl]=useState("");
    // console.log(file,folder);
   
if(file==null)
return "https://www.jaipuriaschoolballia.in/wp-content/uploads/2016/11/blank-img.jpg";

const options = {
  maxSizeMB: 1,
  useWebWorker: true
};

Compress(file, options)
  .then((compressedBlob) => {
    // console.log(compressedBlob);
    // const FileUrl =await URL.createObjectURL(compressedBlob);
    compressedBlob.lastModifiedDate = new Date();

    // Conver the blob to file
     let convertedBlobFile = new File([compressedBlob], file.name, {
      type: file.type,
      lastModified: Date.now()
    });
    file=convertedBlobFile;
  })
  .catch((e) => {
  });



const time=new Date().getTime();
const fileName=file.name+time;
const storageRef= ref(storage, `${folder}/${fileName}`);
const uploadTask = await uploadBytesResumable(storageRef, file);
// console.log(uploadTask );
const fileUrl= await getDownloadURL(storageRef);
// console.log(fileUrl);
return {
    fileRef:`${folder}/${fileName}`,
    fileUrl:fileUrl
};

}
const DeleteFile= async(fileRef)=>{
    
    const storageRef= ref(storage, fileRef);

    deleteObject(storageRef).then(() => {
      //  console.log("deleted")
      }).catch((error) => { 
      //  console.log("error");
      }); 

}
export  {UploadFile,DeleteFile};

