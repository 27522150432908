import React, { useEffect, useState } from "react";
import "../../styles/colloquium.css";
import { Modal } from "react-bootstrap";
import {UploadFile,DeleteFile} from '../../UploadFile';
import {
  PostColloquiumData,
  GetColloquiumData,
  DeleteColloquiumData,
  UploadColloquiumImage,
} from "../../apis";
import { ImTerminal } from "react-icons/im";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import Overlay from "../../components/Overlay";
import { useNavigate } from "react-router-dom";

export default function Colloquium() {
  const navigate=useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [colloquiumDBdata, setColloquiumDBdata] = useState([]);
  const [addColloquiummodalShow, setAddColloquiummodalShow] = useState(false);

  const fetchColloquiumData = async () => {
    // setIsLoading(()=>true)
    const GetColloquiumDataRes = await GetColloquiumData();

    let temp=GetColloquiumDataRes.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setColloquiumDBdata(() => temp);
    console.log(GetColloquiumDataRes.data);
    // setIsLoading(()=>false)
   
  };
  const deleteColloquiumDatafunc = async (id) => {
    setIsLoading(()=>true)
    const data = {
      id: id,
    };
    const deleteColloquiumDataRes = await DeleteColloquiumData(data);
    fetchColloquiumData();
    setIsLoading(()=>false)
  };
  const handleAddColloquiumSubmit = async (e) => {
    e.preventDefault();
   
    const title = e.target[0].value;
    const keyPoint = e.target[1].value;
    const linkToPdf = e.target[2].value;
    const date = e.target[3].value;

    setAddColloquiummodalShow(() => false);
    setIsLoading(()=>true)
    const file = e.target[4].files[0];
   
    const UploadFileRes=await UploadFile(file,'colloquium');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
    const data = {
      title: title,
      date: date,
      keyPoint: keyPoint,
      linkToPdf: linkToPdf,
      imageurl: imageurl,
      fileRef:fileRef,
    };
    const uploadColloquiumDataRes = await PostColloquiumData(data);
    fetchColloquiumData();
    setIsLoading(()=>false)
  };
  useEffect(() => {
    fetchColloquiumData();
  }, []);
  let addColloquiumModal = (
    <Modal
      className="modal-addColloquium"
      show={addColloquiummodalShow}
      onHide={() => setAddColloquiummodalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addColloquium"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addColloquium">
        <form onSubmit={(e) => handleAddColloquiumSubmit(e)}>
          <input className="input" type="text" placeholder="Title" />
          <input className="input" type="text" placeholder="Key Ponts" />
          <input className="input" type="text" placeholder="LINK(if any)" />
          <input className="input" type="text" placeholder="DATE" />
          <input className="input" type="file" placeholder="IMAGE" />
          <label>Add Image</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let colloquiumContent = (
    <div className="colloquiumContent">
      {/* {
            colloquiumDBdata.length>0&&colloquiumDBdata.map((item,i)=>{
                return <button onClick={()=>deleteColloquiumDatafunc(item.id)} >delete</button>
            }
               
            )
           } */}

      {colloquiumDBdata.length > 0 &&
        colloquiumDBdata.map((item, i) => {
          return (
            <div className="colloquiumContent-eventContainer-singleEvent">
              <img src={item.imageurl} />
              <h4>{item.title}</h4>
              <p>{item.keyPoint}</p>
              {item.date && <p>{item.date}</p>}
              <div className="colloquiumContent-eventContainer-singleEvent-footer">
                { item.linkToPdf!==""&&<a target="_blank" href={item.linkToPdf}>Link</a>}
                {localStorage.getItem("CmFtoken") != null &&
                <button onClick={() => deleteColloquiumDatafunc(item.id)}>
                  DELETE
                </button>}

                <button
                    onClick={() => {
                    navigate("/more", {
                      state: {
                        title:item.title,
                        tagline:"",
                        imageurl:item.imageurl,
                        date:item.date,
                        theme:"",
                        description:item.keyPoint,
                        pdflink:item.link,
                        videoLink:"",
                      },
                    });
                  }}
                
                >READ MORE</button>
              </div>
            </div>
          );
        })}
    </div>
  );

  let colloquiumHeader = (
    <div className="colloquiumHeader">
      <Navbar />
      {/* <Overlay overLayColor={"#00000099"} z_index={"5"} /> */}
      <div className="colloquiumHeader-CoverpicContainer">
        <img src={"../images/programs/nutrition/nutrition1.jpg"} />
      </div>
      <div className="colloquiumHeader-headingContainer">
        <h2>COLLOQUIUM</h2>
      </div>
    </div>
  );
  return (
    <>
      {isLoading && <Loader/>}
      {!isLoading && (
        <div className="colloquium">
          {colloquiumHeader}
          <div className="addColloquiumButton">
          {localStorage.getItem("CmFtoken") != null &&
            <button onClick={() => setAddColloquiummodalShow(() => true)}>
              ADD Events
            </button>}
          </div>
          {addColloquiumModal}
          {colloquiumContent}
          <Footer />
        </div>
      )}
    </>
  );
}
