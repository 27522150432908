// const axios = require('axios');
import axios from 'axios';

let token="";
if(localStorage.getItem('CmFtoken')!=null)
{
  token=localStorage.getItem('CmFtoken');
} 
else
token=null;
// console.log(token);
const getApiObject = function () {

    const APIObj = axios.create({
      // baseURL: `http://localhost:5000/v1`,
      baseURL: `https://us-central1-cmf-backend.cloudfunctions.net/app/v1`,
      // baseURL: `https://us-central1-cmf-backend-update.cloudfunctions.net/app/v1`,
      // headers: {
      //   authorization: `Bearer ${token}`,
      // },
    });
    return APIObj;

};
// authorization: `Bearer ${JSON.parse(token)}`,
const API = getApiObject();

const setHeader = () => {
  let token="";
if(localStorage.getItem('CmFtoken')!=null)
{
  token=localStorage.getItem('CmFtoken');
}
else
token=null;
  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
};

const setFormHeader = () => {
  let token="";
if(localStorage.getItem('CmFtoken')!=null)
{
  token=localStorage.getItem('CmFtoken');
}
else
token=null;
  return {
    headers: {
      authorization: `Bearer ${token}`,
      contentType:'multipart/form-data;boundary=boundary=boundary42',
    },
  };
};




// -------------------- Auth apis ---------------------------

export const UploadImpactStoryData = (data) => API.post(`/auth/register`, data, setHeader());

export const Ping =()=>API.get("/");


// ---------------------STORY-----------------------

export const PostStoryData= (data) => API.post(`/story/uploadData`, data, setHeader());
export const GetStoryData= () => API.get(`/story/getData`, setHeader());
export const DeleteStoryData= (data) => API.post(`/story/delete`, data, setHeader());
// export const UploadStoryImage= (data) => API.post(`/story/uploadImage`, data, setHeader());
export const UploadStoryImage= (data) => API.post(`/story/uploadImage`, data, setFormHeader());

// export const UploadStoryImage= (data) => axios.post(`http://localhost:5000/checkingUploads`, data, setHeader());

// ---------------------ABOUT US-----------------------


export const PostBoardMemberData= (data) => API.post(`/aboutUs/boardmember/uploadData`, data, setHeader());
export const GetBoardMemberData= () => API.get(`/aboutUs/boardmember/getData`, setHeader());
export const DeleteBoardMemberData= (data) => API.post(`/aboutUs/boardmember/delete`, data, setHeader());
// export const UploadImage= (data) => API.post(`/story/uploadImage`, data, setHeader());

export const PostChairmanData= (data) => API.post(`/aboutUs/chairman/uploadData`, data, setHeader());
export const GetChairmanData= () => API.get(`/aboutUs/chairman/getData`, setHeader());
export const DeleteChairmanData= (data) => API.post(`/aboutUs/chairman/delete`, data, setHeader());
export const UploadChairmanImage= (data) => API.post(`/aboutUs/chairman/uploadImage`, data, setHeader());



// ---------------------Annual Memorial Oration-----------------------

export const PostOrationData= (data) => API.post(`/oration/uploadData`, data, setHeader());
export const GetOrationData= () => API.get(`/oration/getData`, setHeader());
export const DeleteOrationData= (data) => API.post(`/oration/delete`, data, setHeader());
 export const UploadOrationImage= (data) => API.post(`/oration/uploadImage`, data, setHeader());


 // ---------------------Headline-----------------------

export const PostHeadlineData= (data) => API.post(`/headline/uploadData`, data, setHeader());
export const GetHeadlineData= () => API.get(`/headline/getData`, setHeader());
export const DeleteHeadlineData= (data) => API.post(`/headline/delete`, data, setHeader());

// ---------------------AWARD-----------------------

export const PostAwardData= (data) => API.post(`/award/uploadData`, data, setHeader());
export const GetAwardData= () => API.get(`/award/getData`, setHeader());
export const DeleteAwardData= (data) => API.post(`/award/delete`, data, setHeader());
 export const UploadAwardImage= (data) => API.post(`/award/uploadImage`, data, setHeader());

 // ---------------------OPINION-----------------------

export const PostOpinionData= (data) => API.post(`/opinion/uploadData`, data, setHeader());
export const GetOpinionData= () => API.get(`/opinion/getData`, setHeader());
export const DeleteOpinionData= (data) => API.post(`/opinion/delete`, data, setHeader());
 export const UploadOpinionImage= (data) => API.post(`/opinion/uploadImage`, data, setHeader());

 // ---------------------RESEARCH-----------------------

export const PostResearchData= (data) => API.post(`/research/uploadData`, data, setHeader());
export const GetResearchData= () => API.get(`/research/getData`, setHeader());
export const DeleteResearchData= (data) => API.post(`/research/delete`, data, setHeader());
 export const UploadResearchImage= (data) => API.post(`/research/uploadImage`, data, setHeader());

 // ---------------------COVERAGE-----------------------

export const PostCoverageData= (data) => API.post(`/coverage/uploadData`, data, setHeader());
export const GetCoverageData= () => API.get(`/coverage/getData`, setHeader());
export const DeleteCoverageData= (data) => API.post(`/coverage/delete`, data, setHeader());
 export const UploadCoverageImage= (data) => API.post(`/coverage/uploadImage`, data, setHeader());

 // ---------------------VIDEOS-----------------------

export const PostVideoData= (data) => API.post(`/videos/uploadData`, data, setHeader());
export const GetVideoData= () => API.get(`/videos/getData`, setHeader());
export const DeleteVideoData= (data) => API.post(`/videos/delete`, data, setHeader());
 export const UploadVideoImage= (data) => API.post(`/videos/uploadImage`, data, setHeader());

// ---------------------OTHER EVENTS-----------------------

export const PostOtherEventData= (data) => API.post(`/otherEvent/uploadData`, data, setHeader());
export const GetOtherEventData= () => API.get(`/otherEvent/getData`, setHeader());
export const DeleteOtherEventData= (data) => API.post(`/otherEvent/delete`, data, setHeader());
 export const UploadOtherEventImage= (data) => API.post(`/otherEvent/uploadImage`, data, setHeader());

  // ---------------------COLLOQUIUM-----------------------

export const PostColloquiumData= (data) => API.post(`/colloquium/uploadData`, data, setHeader());
export const GetColloquiumData= () => API.get(`/colloquium/getData`, setHeader());
export const DeleteColloquiumData= (data) => API.post(`/colloquium/delete`, data, setHeader());
 export const UploadColloquiumImage= (data) => API.post(`/colloquium/uploadImage`, data, setHeader());

  // ---------------------HOME-----------------------


export const GetHomeEventData= () => API.get(`/home/event/getData`, setHeader());
export const GetHomeStoryData= () => API.get(`/home/story/getData`, setHeader());

 // ---------------------ADMIN LOGIN-----------------------


 export const AutheticateAdmin= (data) => API.post(`/admin/adminAuth`, data, setHeader());


   // ---------------------AUDITnREPORT-----------------------

export const PostInitiativeData= (data) => API.post(`/initiativeNreport/initiative/uploadData`, data, setHeader());
export const UploadInitiativeFile= (data) => API.post(`/initiativeNreport/initiative/uploadFile`, data, setHeader());
export const GetInitiativeData= () => API.get(`/initiativeNreport/initiative/getData`, setHeader());
export const DeleteInitiativeData= (data) => API.post(`/initiativeNreport/initiative/delete`, data, setHeader());

export const PostReportData= (data) => API.post(`/initiativeNreport/report/uploadData`, data, setHeader());
export const UploadReportFile= (data) => API.post(`/initiativeNreport/report/uploadFile`, data, setHeader());
export const GetReportData= () => API.get(`/initiativeNreport/report/getData`, setHeader());
export const DeleteReportData= (data) => API.post(`/initiativeNreport/report/delete`, data, setHeader());

 /*-------CONTACT US------------*/
export const UploadContactResume=(data)=> API.post(`/contact/uploadFile`, data, setHeader());
 export const SendContactMail= (data) => API.post(`/contact/sendMail`, data, setHeader());

 export const UploadVacancyData=(data)=> API.post(`/contact/vacancy/uploadData`, data, setHeader());
 export const UploadVacancyFile=(data)=> API.post(`/contact/vacancy/uploadFile`, data, setHeader());
 export const GetVacancyData= () => API.get(`/contact/vacancy/getData`, setHeader());
export const DeleteVacancyData= (data) => API.post(`/contact/vacancy/delete`, data, setHeader());


  // ---------------------TEAM MEMBER-----------------------

  export const PostTeamMemberData= (data) => API.post(`/teamMember/uploadData`, data, setHeader());
  export const GetTeamMemberData= () => API.get(`/teamMember/getData`, setHeader());
  export const DeleteTeamMemberData= (data) => API.post(`/teamMember/delete`, data, setHeader());
   export const UploadTeamMemberImage= (data) => API.post(`/teamMember/uploadImage`, data, setHeader());

   /*-------------------PROGRAMS-------------------*/
  //  export const PostProgramImageData= (data) => API.post(`/program/uploadData`, data, setHeader());
  //  export const GetProgramImageData= () => API.get(`/program/getData`, setHeader());
  //  export const DeleteProgramImageData= (data) => API.post(`/program/delete`, data, setHeader());
  //   export const UploadProgramImageImage= (data) => API.post(`/program/uploadImage`, data, setHeader());

  export const PostProgramImageData= (data) => API.post(`/program/uploadData`, data, setHeader());
  export const GetProgramImageData= () => API.get(`/program/getData`, setHeader());
  export const DeleteProgramImageData= (data) => API.post(`/program/delete`, data, setHeader());
   export const UploadProgramImageImage= (data) => API.post(`/program/uploadImage`, data, setFormHeader());

    







