import React, { useEffect, useState } from "react";
import "../../styles/research.css";
import { Modal } from "react-bootstrap";
import {UploadFile,DeleteFile} from '../../UploadFile';
import {
  PostResearchData,
  GetResearchData,
  DeleteResearchData,
  UploadResearchImage,
} from "../../apis";
import { ImTerminal } from "react-icons/im";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import Overlay from "../../components/Overlay";
import PhotoSlider from "../../components/PhotoSlider";
import { useNavigate } from "react-router-dom";
export default function Research() {
  const navigate=useNavigate();
  const [researchDBdata, setResearchDBdata] = useState([]);
  const [addResearchmodalShow, setAddResearchmodalShow] = useState(false);

  const fetchResearchData = async () => {
    const GetResearchDataRes = await GetResearchData();
    let temp=GetResearchDataRes.data;
    temp=temp.sort((item1,item2)=>item1.time-item2.time);
    setResearchDBdata(() => temp);
    console.log(GetResearchDataRes.data);
  };
  const deleteResearchDatafunc = async (id,fileRef) => {
    const data = {
      id: id,
    };
    await DeleteFile(fileRef);
    const deleteResearchDataRes = await DeleteResearchData(data);
    fetchResearchData();
  };
  const handleAddResearchSubmit = async (e) => {
 

    e.preventDefault();
    const title = e.target[0].value;
    const theme=e.target[1].value;
    const keyPoint = e.target[2].value;

    const link = e.target[3].value;
    const date = e.target[4].value;
    const file = e.target[5].files[0];

    setAddResearchmodalShow(() => false);
   
   
    const UploadFileRes=await UploadFile(file,'research');
    const imageurl= UploadFileRes.fileUrl;
     const fileRef=UploadFileRes.fileRef;
     const time = new Date().getTime();
    const data = {
      title: title,
      theme:theme,
      date: date,
      keyPoint: keyPoint,
      link: link,
      imageurl: imageurl,
      fileRef:fileRef,
      time:time
    };
    const uploadResearchDataRes = await PostResearchData(data);
    fetchResearchData();
  };
  useEffect(() => {
    fetchResearchData();
  }, []);
  let addResearchModal = (
    <Modal
      className="modal-addResearch"
      show={addResearchmodalShow}
      onHide={() => setAddResearchmodalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter-addResearch"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-addResearch">
        <form onSubmit={(e) => handleAddResearchSubmit(e)}>
          <input className="input" type="text" placeholder="Title" />
          <select name="researchTheme">
            <option value="Career">Theme</option>
            <option value="Community-level Livelihoods & mF">
              Community-level Livelihoods & mF
            </option>
            <option value="Skilling & Entrepreneurship">
              Skilling & Entrepreneurship
            </option>
            <option value="Education">Education</option>
            <option value="WASH">WASH</option>
            <option value="MHM">MHM</option>
            <option value="Nutrition">Nutrition</option>
          </select>
          <input className="input" type="text" placeholder="Key Ponts" />
          
          <input className="input" type="text" placeholder="LINK" />
          {/* <input className="input" type="text" placeholder="LINK To Pdf" /> */}

          <input className="input" type="text" placeholder="DATE" />
          <input className="input" type="file" placeholder="IMAGE" />
          <label>Add Image</label>
          <button type="submit">SUBMIT</button>
        </form>
      </Modal.Body>
    </Modal>
  );

  let researchContent = (
    <div className="researchContent">
      {/* {
            researchDBdata.length>0&&researchDBdata.map((item,i)=>{
                return <button onClick={()=>deleteResearchDatafunc(item.id)} >delete</button>
            }
               
            )
           } */}

      {researchDBdata.length > 0 &&
        researchDBdata.map((item, i) => {
          return ( <div className="researchContent-eventContainer-singleEvent">
              <img src={item.imageurl} />
              <h4>{item.title}</h4>
              <h6>{item.theme}</h6>
              <p>{item.keyPoint}</p>
              {item.date && <p>{item.date}</p>}
              <div className="researchContent-eventContainer-singleEvent-footer">
                {item.link!==""&&<a target="_blank" href={item.link}>Link</a>}
                {localStorage.getItem("CmFtoken") != null &&<button onClick={() => deleteResearchDatafunc(item.id,item.fileRef)}>
                  DELETE
                </button>}

                <button
                 onClick={() => {
                    navigate("/more", {
                      state: {
                        title:item.title,
                        tagline:"",
                        imageurl:item.imageurl,
                        date:item.date,
                        theme:item.theme,
                        description:item.keyPoint,
                        pdflink:item.link,
                        videoLink:"",
                      },
                    });
                  }}
                
                >READ MORE</button>
              </div>
            </div>
          );
        })}
    </div>
  );

  let researchHeader = (
    <div className="researchHeader">
    <Navbar />
    <Overlay overLayColor={"#00000055"} z_index={"10"} />
    <div className="Cover-ResearchPage">
   
      <PhotoSlider imgBase={"../images/insights/Pic-"} number={10} />
    </div>
    <div className="researchHeader-content">
        <h1>RESEARCH AND PUBLICATIONS</h1>
    </div>
  </div>
  );
 
  return (
    <div className="research">
      {researchHeader}
      <div className="addResearchButton">
      {localStorage.getItem("CmFtoken") != null &&
        <button onClick={() => setAddResearchmodalShow(() => true)}>
          ADD RESEARCH
        </button>
      }
      </div>
      {addResearchModal}
      {researchContent}
      {/* <iframe src="https://research.google.com/pubs/archive/44678.pdf"
   width="800" height="600"/> */}
      <Footer />
    </div>
  );
}
